import { Box, Breadcrumbs, Button, Card, CardContent, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import productsStore from "../../stores/ProductsStore";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import { useReactToPrint } from "react-to-print";
import PrintSettingProducts from "../../components/Report/Pdf/PrintSettingProducts";
import { DownloadTableExcel } from "react-export-table-to-excel";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExportFileSettingProducts from "../../components/Report/Excel/ExportFileSettingProducts";
import authStore from "../../stores/AuthStore";

const Products: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [productName, setProductName] = useState('');
  /* NOTE - Keep it in consideration for future use
  const [productCategory, setProductCategory] = useState('all');
  const [productGroup, setProductGroup] = useState('all');
  const [productGroupData, setProductGroupData] = useState<any[]>([]);
  const [productCategoryData, setProductCategoryData] = useState<any[]>([]);*/
  const [rows, setRows] = useState<any[]>([]);
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/products');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const printRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const exportRef = useRef(null);

  /* NOTE - Keep it in consideration for future use
  const handleChangeProductGroup = (event: SelectChangeEvent) => {
    setProductGroup(event.target.value as string);
  };

  const handleChangeProductCategory = (event: SelectChangeEvent) => {
    setProductCategory(event.target.value as string);
  };*/

  const handleSearch = (pageNumber?: string, limitNumber?: string) => {
    setIsLoading(true);
    setRows([]);
    const _page = parseInt(pageNumber || '0');
    const _limit = parseInt(limitNumber || '0');
    productsStore.getByCriteria({
      /* NOTE - Keep it in consideration for future use
      productCategoryId: productCategory === 'all' ? undefined : productCategory,
      productGroupId: productGroup === 'all' ? undefined : productGroup,*/
      name: productName,
      page: _page,
      limit: _limit
    }).then((result) => {
      setRows(result.data.sort((a: any, b: any) => a.productCategory?.name.localeCompare(b.productCategory?.name || '')).sort((a: any, b: any) => a.productGroup?.name.localeCompare(b.productGroup?.name || '')));
      setTotal(result.total);

      setIsLoading(false);
    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    productsStore.getByCriteria({
      /* NOTE - Keep it in consideration for future use
      productCategoryId: productCategory === 'all' ? undefined : productCategory,
      productGroupId: productGroup === 'all' ? undefined : productGroup,*/
      name: productName,
      page: page,
      limit: limit
    }).then((result) => {
      setRows(result.data.sort((a: any, b: any) => a.productCategory?.name.localeCompare(b.productCategory?.name || '')).sort((a: any, b: any) => a.productGroup?.name.localeCompare(b.productGroup?.name || '')));
      setTotal(result.total);

      setPage(0);
      setIsLoading(false);
    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component={'div'}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>การจัดการ</Typography>
        <Typography variant="h6" color="text.primary">สินค้า</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems={'end'}>
                {/* NOTE - Keep it in consideration for future use
                <Grid item xs={3} >
                  <FormControl fullWidth>
                    <InputLabel id="productCategory-select-label">ประเภทสินค้า</InputLabel>
                    <Select
                      labelId="productCategory-select-label"
                      id="productCategory-select"
                      value={productCategory}
                      label="ประเภทสินค้า"
                      onChange={handleChangeProductCategory}
                    >
                      <MenuItem value={'all'}>ทั้งหมด</MenuItem>
                      {productCategoryData.map((item) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3} >
                  <FormControl fullWidth>
                    <InputLabel id="productGroup-select-label">หมวดหมู่สินค้า</InputLabel>
                    <Select
                      labelId="productGroup-select-label"
                      id="productGroup-select"
                      value={productGroup}
                      label="หมวดหมู่สินค้า"
                      onChange={handleChangeProductGroup}
                    >
                      <MenuItem value={'all'}>ทั้งหมด</MenuItem>
                      {productGroupData.map((item) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item xs={10} >
                  <TextField id="outlined-basic" label="ชื่อสินค้า" variant="outlined" fullWidth
                    onChange={(event) => {
                      setProductName(event.target.value);
                    }} value={productName}
                  />
                </Grid>
                <Grid item xs={2} textAlign={'end'} pb={0.5} >
                  <Button variant="contained" onClick={() => handleSearch()} startIcon={<SearchIcon />}>ค้นหา</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container >
                    <Grid item xs={9}>
                      <Typography>ทั้งหมด {total.toLocaleString()} รายการ</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <DownloadTableExcel
                        filename={`products-${dayjs().format('DD-MM-YYYY')}`}
                        sheet={`products-${dayjs().format('DD-MM-YYYY')}`}
                        currentTableRef={exportRef.current}
                      >
                        <IconButton aria-label="download"  >
                          <DownloadIcon />
                        </IconButton>
                      </DownloadTableExcel>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="print" onClick={() => reactToPrintFn()}>
                        <PrintIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      {((permission && permission.create) || authStore.user?.isSuperAdmin) && <Button variant="contained" startIcon={<NoteAddIcon />} onClick={() => navigate('/product-create')}>สร้าง</Button>}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          {/*NOTE - Keep it in consideration for future use
                          <TableCell width={100}>ประเภทสินค้า</TableCell>
                          <TableCell width={100}>หมวดหมู่สินค้า</TableCell> */}
                          <TableCell width={300}>ชื่อสินค้า</TableCell>
                          <TableCell width={10}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.length > 0 && rows.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            {/* NOTE - Keep it in consideration for future use
                            <TableCell component="th" scope="row">
                              {row.productCategory?.name || ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.productGroup?.name || ''}
                        </TableCell>*/}
                            <TableCell>{row.name}</TableCell>
                            <TableCell align="right">
                              <IconButton aria-label="edit" onClick={() => navigate(`/product-edit/${row.id}`)}>
                                <KeyboardArrowRightIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                      count={total}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        handleSearch(newPage.toString());
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setRows([]);
                        setLimit(e.target.value);
                        setPage(0);
                        handleSearch('0', e.target.value.toString());
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <Box sx={{ display: "none" }}>
        <PrintSettingProducts ref={printRef} title={'รายการสินค้า'} data={rows} />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileSettingProducts ref={exportRef} title={'รายการสินค้า'} data={rows} />
      </Box>
    </Box>
  );
};

export default Products;
