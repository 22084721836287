import { useNavigate } from "react-router-dom";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import authStore from "../../stores/AuthStore";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/th';
import { Box, Breadcrumbs, Button, Card, CardContent, Chip, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from "react-to-print";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import receiptStore from "../../stores/ReceiptStore";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PrintReceiptLists from "../../components/Report/Pdf/PrintReceiptList";
import ExportFileReceipt from "../../components/Report/Excel/ExportFileReceipt";
import { DownloadTableExcel } from "react-export-table-to-excel";

const Receipts: React.FC = () => {
  const navigate = useNavigate();
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/contracts');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs().startOf('month'));
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs());
  const [rows, setRows] = useState<any>([]);
  const [dateTypes, setDateTypes] = useState('createDate');
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [total, setTotal] = useState(0);
  const printRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const exportRef = useRef(null);
  const [grandTotal, setGrandTotal] = useState(0);
  const [sortType, setSortType] = useState({ name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" });
  const sortOption = [
    { name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" },
    { name: "วันที่บันทึก: เก่าสุด", variable: "createAtASC", sortBy: "createAt", sortType: "ASC" },
    { name: "วันที่เอกสาร: ล่าสุด", variable: "docDateDESC", sortBy: "docDate", sortType: "DESC" },
    { name: "วันที่เอกสาร: เก่าสุด", variable: "docDateASC", sortBy: "docDate", sortType: "ASC" },
    { name: "วันที่แก้ไข: ล่าสุด", variable: "updateAtDESC", sortBy: "updateAt", sortType: "DESC" },
    { name: "วันที่แก้ไข: เก่าสุด", variable: "updateAtASC", sortBy: "updateAt", sortType: "ASC" },
  ];
  const [sortValue, setSortValue] = useState("createAtDESC");

  const handleChangeDateType = (event: ChangeEvent<HTMLInputElement>) => {
    setDateTypes((event.target as HTMLInputElement).value);
    localStorage.setItem('dateTypes', (event.target as HTMLInputElement).value);
  };

  // Remove local storage
  window.addEventListener("beforeunload", function (event) {
    localStorage.removeItem('dateFrom');
    localStorage.removeItem('dateTo');
    localStorage.removeItem('name');
    localStorage.removeItem('tel');
    localStorage.removeItem('dateTypes');
    localStorage.removeItem('sortType');
    localStorage.removeItem('limit');
    localStorage.removeItem('page');
  });

  const handleSearch = (pageNumber?: string, limitNumber?: string, dateF?: Dayjs, dateT?: Dayjs, nameCustomer?: string, telCustomer?: string, dateType?: string, _sortType?: string, isRefresh = false) => {
    setIsLoading(true);
    setRows([]);
    const _page = parseInt(pageNumber || '0');
    const _limit = parseInt(limitNumber || '0');
    localStorage.setItem('dateFrom', JSON.stringify(dateF ? dateF.format('YYYY-MM-DD') : dateFrom?.format('YYYY-MM-DD')));
    localStorage.setItem('dateTo', JSON.stringify(dateT ? dateT.format('YYYY-MM-DD') : dateTo?.format('YYYY-MM-DD')));
    localStorage.setItem('dateTypes', dateTypes);
    const data = {
      dateFrom: dateF ? dateF.format('YYYY-MM-DD') : dateFrom?.format('YYYY-MM-DD'),
      dateTo: dateT ? dateT.format('YYYY-MM-DD') : dateTo?.format('YYYY-MM-DD'),
      name: nameCustomer ? nameCustomer : name,
      tel: telCustomer ? telCustomer : tel,
      limit: _limit,
      page: _page,
      dateTypes: dateType ? dateType : dateTypes,
      sortType: _sortType ? _sortType : sortType,
    };
    receiptStore.getByCriteria(data).then((res: any) => {
      setRows(res.data);
      setTotal(res.total);
      setGrandTotal(res.grandTotal);
      if ((res?.total && res.total <= 10) || isRefresh === true) {
        setPage(0);
        setLimit(10);
      };
      const _sortType = localStorage.getItem('sortType');
      const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
      setSortType(_sortTypeConvert || sortType);
      setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
      if (!_sortType) {
        localStorage.setItem('sortType', JSON.stringify(sortType));
      }
    });
    setIsLoading(false);
  };

  useEffect(() => {
    const _dateFrom = localStorage.getItem('dateFrom');
    const _dateTo = localStorage.getItem('dateTo');
    const _name = localStorage.getItem('name');
    const _tel = localStorage.getItem('tel');
    const _dateTypes = localStorage.getItem('dateTypes');
    const dateF = dayjs(_dateFrom ? _dateFrom : dateFrom);
    const dateT = dayjs(_dateTo ? _dateTo : dateTo);
    const _limit = localStorage.getItem('limit');
    const _page = localStorage.getItem('page');
    setDateFrom(dateF);
    setDateTo(dateT);
    setLimit(_limit ? parseInt(_limit) : limit);
    setPage(_page ? parseInt(_page) : page);
    const _sortType: any = localStorage.getItem('sortType');
    const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
    setSortType(_sortTypeConvert || sortType);
    setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
    if (!_sortType) {
      localStorage.setItem('sortType', JSON.stringify(sortType));
    }
    if (!_limit) {
      localStorage.setItem('limit', '10');
    }
    if (!_page) {
      localStorage.setItem('page', '0');
    }
    handleSearch(undefined, undefined, dateF || undefined, dateT || undefined, _name || undefined, _tel || undefined, _dateTypes || undefined, _sortTypeConvert);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSort = (e: any) => {
    const findSortType: any = sortOption.find((item: any) => item.variable === e.target.value);
    localStorage.setItem('sortType', JSON.stringify(findSortType));
    setSortType(findSortType);
    setSortValue(e.target.value);
  };
  return (
    <Box component={'div'}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>เอกสาร</Typography>
        <Typography variant="h6" color="text.primary">ใบเสร็จรับเงิน</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems={'end'}>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={dateTypes}
                      onChange={handleChangeDateType}
                    >
                      <FormControlLabel value="createDate" control={<Radio />} label="วันที่บันทึก" />
                      <FormControlLabel value="docDate" control={<Radio />} label="วันที่เอกสาร" />
                    </RadioGroup>
                  </FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                        label="ตั้งแต่วันที่"
                        value={dateFrom}
                        onChange={(newValue) => {
                          setDateFrom(newValue);
                          localStorage.setItem('dateFrom', newValue?.format('YYYY-MM-DD') || '');
                        }}
                      />
                      <DatePicker
                        label="ถึงวันที่"
                        value={dateTo}
                        onChange={(newValue) => {
                          setDateTo(newValue);
                          localStorage.setItem('dateTo', newValue?.format('YYYY-MM-DD') || '');
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                  <TextField label="ชื่อลูกค้า" variant="outlined" fullWidth onChange={(event) => {
                    setName(event.target.value);
                    localStorage.setItem('name', event.target.value);
                  }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label="เบอร์โทรศัพท์" variant="outlined" fullWidth onChange={(event) => {
                    setTel(event.target.value);
                    localStorage.setItem('tel', event.target.value);
                  }} />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">เรียงตาม
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sortValue}
                      label="sortType"
                      onChange={handleChangeSort}
                    >
                      {sortOption.map((option: any, index: number) => (
                        <MenuItem key={`sort-option-${index}`} value={option.variable}>{option.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} textAlign={"end"} pb={0.5}>
                  <Button variant="contained" onClick={() => handleSearch(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true)} startIcon={<SearchIcon />}>ค้นหา</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justifyContent={"end"}>
                    <Grid item xs={9}>
                      <Typography>ทั้งหมด {total?.toLocaleString()} รายการ</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <DownloadTableExcel
                        filename={`reciept-${dayjs().format('DD-MM-YYYY')}`}
                        sheet={`reciept-${dayjs().format('DD-MM-YYYY')}`}
                        currentTableRef={exportRef.current}
                      >
                        <IconButton aria-label="download"  >
                          <DownloadIcon />
                        </IconButton>
                      </DownloadTableExcel>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="print" onClick={() => reactToPrintFn()}>
                        <PrintIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      {((permission && permission.create) || authStore.user?.isSuperAdmin) &&
                        <Button variant="contained" startIcon={<NoteAddIcon />} onClick={() => navigate('/receipt-create')}>สร้าง</Button>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>วันที่เอกสาร</TableCell>
                          <TableCell>รายชื่อลูกค้า</TableCell>
                          <TableCell>เบอร์โทรศัพท์</TableCell>
                          <TableCell>จำนวนเงิน</TableCell>
                          <TableCell>ประเภทการจ่าย</TableCell>
                          <TableCell width={100}>บันทึก</TableCell>
                          <TableCell width={100}>แก้ไข</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows && rows?.length > 0 && rows?.map((row: any, index: number) => (
                          <TableRow key={row.id + index}>
                            <TableCell>{dayjs(row.docDate).format('DD-MM-YYYY')}</TableCell>
                            <TableCell>{row?.customer?.firstname ?? ''} {row?.customer?.lastname ?? ''}</TableCell>
                            <TableCell>{row?.customer?.tel ?? ''}</TableCell>
                            <TableCell>{parseFloat(row?.total || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                            <TableCell>
                              {row.payment === 'cash' ? <Chip label="เงินสด" sx={{ bgcolor: '#bbdefb' }} /> : row.payment === 'transfer' ? <Chip label="โอน" sx={{ bgcolor: '#f48fb1' }} /> : <Chip label="ว่าง" />}</TableCell>
                            <TableCell>{<b>{row.createBy?.name}</b>} {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                            <TableCell>{<b>{row.updateBy?.name}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''} </TableCell>
                            <TableCell align="right">
                              <IconButton aria-label="detail" onClick={() => navigate(`/receipt-edit/${row.id}`)}>
                                <KeyboardArrowRightIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}

                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[10, 25, 50, 100, { label: "All", value: -1 }]}
                      count={total}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        handleSearch(newPage.toString());
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setRows([]);
                        setLimit(e.target.value);
                        setPage(0);
                        handleSearch("0", e.target.value.toString());
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <Box sx={{ display: "none" }}>
        <PrintReceiptLists data={rows} ref={printRef} title={'รายการใบเสร็จรับเงิน'} grandTotal={grandTotal} />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileReceipt data={rows} ref={exportRef} title={'รายการใบเสร็จรับเงิน'} grandTotal={grandTotal} />
      </Box>

    </Box >
  );
};

export default Receipts;