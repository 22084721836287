import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React from "react";

interface ExportFileExpensesProjectLandProps {
  title?: string;
  data: any[];
  total?: string;
}

const ExportFileExpensesProjectLand = React.forwardRef(
  (props: ExportFileExpensesProjectLandProps, ref) => {
    return (
      <Box
        ref={ref}
        sx={{ textAlign: "center", padding: "20px", "&@page": { size: "A4" } }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 650, overflow: "hidden" }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                      <Typography fontWeight={"bold"}>{props.title}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={300}>เลขที่โฉนด</TableCell>
                    <TableCell width={450}>บ้าน</TableCell>
                    <TableCell width={100}>เนื้อที่</TableCell>
                    <TableCell width={200} align="center">
                      มูลค่ารวม
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props?.data?.length > 0 &&
                    props?.data?.map((row: any) => (
                      <TableRow key={row.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            textDecoration: row.isDelete
                              ? "line-through"
                              : "none",
                          }}
                        >
                          {row.deedNo}
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={14} fontWeight={"bold"}>
                            {" "}
                            {row?.childLand
                              ?.sort((a: any, b: any) =>
                                dayjs(a.createAt).diff(b.createAt)
                              )
                              .map((childLand: any, index: number) => {
                                return (
                                  <span key={childLand.id}>{`${childLand.house?.name || ""
                                    }${index < row?.childLand.length - 1
                                      ? ", "
                                      : ""
                                    } `}</span>
                                );
                              })}
                          </Typography>
                          {row?.house?.name || ""}
                        </TableCell>
                        <TableCell>{`${row?.rai
                            ? parseFloat(row?.rai || 0).toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }
                            )
                            : "-"
                          } ไร่ ${row?.ngan
                            ? parseFloat(row?.ngan).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })
                            : "-"
                          } งาน ${row?.squareWah
                            ? parseFloat(row?.squareWah).toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }
                            )
                            : "-"
                          } ตร.วา`}</TableCell>
                        <TableCell align="center">
                          {parseFloat(row?.landPrice || 0).toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  {props.data.length === 0 && (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell colSpan={5} align="center">
                        ไม่พบข้อมูล
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={3} align="right">
                      <b>จำนวนเงินรวมทั้งสิ้น</b>
                    </TableCell>
                    <TableCell align="center">
                      {parseFloat(
                        (props?.total || 0)?.toString()
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default ExportFileExpensesProjectLand;
