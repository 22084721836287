import { Autocomplete, Box, Breadcrumbs, Button, Card, CardContent, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import SimpleBackdrop from "../../components/SimpleBackdrop";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/th';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchIcon from '@mui/icons-material/Search';
import customerStore from "../../stores/CustomerStore";
import projectsStore from "../../stores/ProjectsStore";
import housesStore from "../../stores/HousesStore";
import statusStore from "../../stores/StatusStore";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import ChipCustom from "../../components/ChipCustom";
import PrintCustomer from "../../components/Report/Pdf/PrintCustomer";
import ExportFileCustomer from "../../components/Report/Excel/ExportFileCustomer";

const Customers: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState<any[]>([]);
  const [dateTypes, setDateType] = useState('createDate');
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs().startOf('month'));
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs());
  const [docStatus, setDocStatus] = useState("all");
  const [statusData, setStatusData] = useState<any[]>([]);
  const [name, setName] = useState("");
  const [bookingNo, setBookingNo] = useState("");
  const [tel, setTel] = useState("");
  const printRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const exportRef = useRef(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [housesList, setHousesList] = useState<any[]>([]);
  const [project, setProject] = useState<any[]>([]);
  const [house, setHouse] = useState<any[]>([]);
  const [houseValue, setHouseValue] = useState('');
  const [projectValue, setProjectValue] = useState('');
  const [sortType, setSortType] = useState({ name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" });
  const sortOption = [
    { name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" },
    { name: "วันที่บันทึก: เก่าสุด", variable: "createAtASC", sortBy: "createAt", sortType: "ASC" },
    { name: "วันเกิดลูกค้า: ล่าสุด", variable: "docDateDESC", sortBy: "docDate", sortType: "DESC" },
    { name: "วันเกิดลูกค้า: เก่าสุด", variable: "docDateASC", sortBy: "docDate", sortType: "ASC" },
    { name: "วันที่แก้ไข: ล่าสุด", variable: "updateAtDESC", sortBy: "updateAt", sortType: "DESC" },
    { name: "วันที่แก้ไข: เก่าสุด", variable: "updateAtASC", sortBy: "updateAt", sortType: "ASC" },
  ];
  const [sortValue, setSortValue] = useState("createAtDESC");

  const handleChangeDateType = (event: ChangeEvent<HTMLInputElement>) => {
    setDateType((event.target as HTMLInputElement).value);
  };

  const handleChangeDocStatus = (event: SelectChangeEvent) => {
    setDocStatus(event.target.value as string);
  };

  window.addEventListener("beforeunload", function (event) {
    localStorage.removeItem('dateFrom');
    localStorage.removeItem('dateTo');
    localStorage.removeItem('sortType');
    localStorage.removeItem('limit');
    localStorage.removeItem('page');
  });

  const handleSearch = (pageNumber?: string, limitNumber?: string, dateF?: Dayjs, dateT?: Dayjs, _sortType?: string, isRefresh = false) => {
    setIsLoading(true);
    setCustomers([]);
    const _page = parseInt(pageNumber || '0');
    const _limit = parseInt(limitNumber || '0');
    const data = {
      dateFrom: dateF ? dateF.format('YYYY-MM-DD') : dateFrom?.format('YYYY-MM-DD'),
      dateTo: dateT ? dateT.format('YYYY-MM-DD') : dateTo?.format('YYYY-MM-DD'),
      project: projectValue,
      house: houseValue,
      status: docStatus,
      name: name,
      tel: tel,
      bookingNo: bookingNo,
      limit: _limit,
      page: _page,
      dateTypes: dateTypes,
      sortType: _sortType ? _sortType : sortType,
    };
    customerStore.getByCriteria(data).then((res: any) => {
      setCustomers(res?.data);
      setTotal(res?.total);
      if ((res?.total && res?.total <= 10) || isRefresh === true) {
        setPage(0);
        setLimit(10);
      };
      const _sortType = localStorage.getItem('sortType');
      const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
      setSortType(_sortTypeConvert || sortType);
      setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
      if (!_sortType) {
        localStorage.setItem('sortType', JSON.stringify(sortType));
      }
      setIsLoading(false);
    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
    });
  };

  const handleChangeSort = (e: any) => {
    const findSortType: any = sortOption.find((item: any) => item.variable === e.target.value);
    localStorage.setItem('sortType', JSON.stringify(findSortType));
    setSortType(findSortType);
    setSortValue(e.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    const _dateFrom = localStorage.getItem('dateFrom');
    const _dateTo = localStorage.getItem('dateTo');
    const dateF = dayjs(_dateFrom ? _dateFrom : dateFrom);
    const dateT = dayjs(_dateTo ? _dateTo : dateTo);
    const _limit = localStorage.getItem('limit');
    const _page = localStorage.getItem('page');
    setDateFrom(dateF);
    setDateTo(dateT);
    setLimit(_limit ? parseInt(_limit) : limit);
    setPage(_page ? parseInt(_page) : page);
    const _sortType: any = localStorage.getItem('sortType');
    const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
    setSortType(_sortTypeConvert || sortType);
    setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
    if (!_sortType) {
      localStorage.setItem('sortType', JSON.stringify(sortType));
    }
    if (!_limit) {
      localStorage.setItem('limit', '10');
    }
    if (!_page) {
      localStorage.setItem('page', '0');
    }
    handleSearch(_page || undefined, _limit || undefined, dateF, dateT, _sortTypeConvert);
    Promise.all([
      projectsStore.getAll(),
      housesStore.getAll(),
      statusStore.getAll()
    ]).then((res) => {
      setProjectsList(res[0]);
      setHousesList(res[1]);
      setStatusData(res[2].filter((item: any) => item.statusType === "booking"));
      setIsLoading(false);
    }).catch((err) => {
      console.error(err);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component={"div"}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>ขาย/จอง</Typography>
        <Typography variant="h6" color="text.primary">รายชื่อลูกค้า</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems={"end"}>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={dateTypes}
                      onChange={handleChangeDateType}  >
                      <FormControlLabel value="createDate" control={<Radio />} label="วันที่บันทึก" />
                      <FormControlLabel value="docDate" control={<Radio />} label="วันที่จอง" />
                      <FormControlLabel value="birthdate" control={<Radio />} label="วันที่เกิดลูกค้า" />
                    </RadioGroup>
                  </FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                        label="ตั้งแต่วันที่"
                        value={dateFrom}
                        onChange={(newValue) => setDateFrom(newValue)} />
                      <DatePicker
                        label="ถึงวันที่"
                        value={dateTo}
                        onChange={(newValue) => setDateTo(newValue)} />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={5}>
                  <Autocomplete
                    disablePortal
                    value={project}
                    options={projectsList}
                    sx={{ width: '100%' }}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option || '';
                      }
                      if (option.inputValue) {
                        return option.inputValue || '';
                      }
                      return option.name || '';
                    }}
                    renderInput={(params) => <TextField {...params} label="โครงการ"
                    />}
                    onChange={(e, value: any) => {
                      if (value?.id) {
                        housesStore.getByProjectId(value?.id).then((res) => {
                          setHousesList(res);
                        });
                      } else {
                        housesStore.getAll().then((res) => {
                          setHousesList(res);
                        });
                      }
                      setProject(value);
                      setProjectValue(value?.id);
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Autocomplete
                    value={house}
                    disablePortal
                    options={housesList}
                    sx={{ width: '100%' }}
                    renderOption={(props, option) =>
                      <li {...props}>{option.name}</li>
                    }
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option || '';
                      }
                      if (option.inputValue) {
                        return option.inputValue || '';
                      }
                      return option.name || '';
                    }}
                    renderInput={(params) => <TextField {...params} label="บ้าน"
                    />}
                    onChange={(e, value: any) => {
                      setHouse(value);
                      setHouseValue(value?.id);
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth >
                    <InputLabel>สถานะเอกสาร</InputLabel>
                    <Select
                      value={docStatus}
                      label="สถานะเอกสาร"
                      onChange={handleChangeDocStatus}
                    >
                      <MenuItem value={"all"} >
                        ทั้งหมด
                      </MenuItem>
                      {statusData.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <TextField label="เลขที่จอง" variant="outlined" fullWidth onChange={(event) => setBookingNo(event.target.value)} />
                </Grid>
                <Grid item xs={7}>
                  <TextField label="ชื่อลูกค้า" variant="outlined" fullWidth onChange={(event) => setName(event.target.value)} />
                </Grid>
                <Grid item xs={5}>
                  <TextField label="เบอร์โทรศัพท์" variant="outlined" fullWidth onChange={(event) => setTel(event.target.value)} />
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">เรียงตาม
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sortValue}
                      label="sortType"
                      onChange={handleChangeSort}
                    >
                      {sortOption.map((option: any, index: number) => (
                        <MenuItem key={`sort-option-${index}`} value={option.variable}>{option.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} textAlign={"end"} pb={0.5}>
                  <Button variant="contained" onClick={() => handleSearch()} startIcon={<SearchIcon />}>ค้นหา</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justifyContent={"end"}>
                    <Grid item xs={10}>
                      <Typography>ทั้งหมด {total?.toLocaleString()} รายการ</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <DownloadTableExcel
                        filename={`customer-${dayjs().format('DD-MM-YYYY')}`}
                        sheet={`customer-${dayjs().format('DD-MM-YYYY')}`}
                        currentTableRef={exportRef.current}
                      >
                        <IconButton aria-label="download"  >
                          <DownloadIcon />
                        </IconButton>
                      </DownloadTableExcel>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="print" onClick={() => reactToPrintFn()}>
                        <PrintIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>รายชื่อลูกค้า</TableCell>
                          <TableCell>เบอร์โทรศัพท์</TableCell>
                          <TableCell>วันที่เกิดลูกค้า</TableCell>
                          <TableCell>โครงการ</TableCell>
                          <TableCell>บ้าน</TableCell>
                          <TableCell>เลขที่ใบจอง</TableCell>
                          <TableCell>สถานะ</TableCell>
                          <TableCell width={100}>บันทึก</TableCell>
                          <TableCell width={100}>แก้ไข</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {customers && customers?.length > 0 && customers?.map((row, index) => (
                          <TableRow
                            key={row.id + index}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }} >
                            <TableCell component="th" scope="row" style={{ textDecoration: row.isDelete ? "line-through" : "none" }} >
                              {row?.firstname || "-"} {row?.lastname || ""}
                            </TableCell>
                            <TableCell>{row?.tel || "-"}</TableCell>
                            <TableCell align="center">{dayjs(row?.birthdate).format("DD/MM/YYYY") !== "Invalid Date" ? dayjs(row?.birthdate).format("DD/MM/YYYY") : "-"}</TableCell>
                            <TableCell>{row?.booking?.project?.name || "-"}</TableCell>
                            <TableCell>{row?.booking?.house?.name || "-"}</TableCell>
                            <TableCell>{row?.booking?.bookingNo || "-"}</TableCell>
                            <TableCell>
                              {row?.booking?.status?.name && <ChipCustom lable={row?.booking?.status?.name} />}
                            </TableCell>
                            <TableCell >{<b>{row.createBy?.name}</b>} {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                            <TableCell>{<b>{row.updateBy?.name}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''} </TableCell>
                            <TableCell align="right">
                              <IconButton aria-label="detail" onClick={() => navigate(`/customer-edit/${row.id}`)}>
                                <KeyboardArrowRightIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        {customers?.length === 0 && (
                          <TableRow
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell colSpan={7} align="center">ไม่พบข้อมูล</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[10, 25, 50, 100, { label: "All", value: -1 }]}
                      count={total}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        handleSearch(newPage.toString());
                        localStorage.setItem('page', newPage.toString());
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setCustomers([]);
                        setLimit(e.target.value);
                        setPage(0);
                        handleSearch("0", e.target.value.toString());
                        localStorage.setItem('limit', e.target.value.toString());
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <Box sx={{ display: "none" }}>
        <PrintCustomer ref={printRef} title={"รายการลูกค้า"} data={customers} />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileCustomer ref={exportRef} title={"รายการลูกค้า"} data={customers} />
      </Box>
    </Box >
  );
};

export default Customers;
