import { Box, Breadcrumbs, Button, Card, CardContent, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import workersStore from "../../stores/WorkersStore";
import { useReactToPrint } from "react-to-print";
import PrintSettingWorkers from "../../components/Report/Pdf/PrintSettingWorkers";
import { DownloadTableExcel } from "react-export-table-to-excel";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExportFileSettingWorkers from "../../components/Report/Excel/ExportFileSettingWorkers";
import authStore from "../../stores/AuthStore";

const SettingWorkers: React.FC = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [tel, setTel] = useState('');
    const [name, setName] = useState('');
    const [rows, setRows] = useState<any[]>([]);
    const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/setting-workers');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const printRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({ contentRef: printRef });
    const exportRef = useRef(null);

    const handleSearch = (pageNumber?: string, limitNumber?: string) => {
        setIsLoading(true);
        setRows([]);
        const _page = parseInt(pageNumber || '0');
        const _limit = parseInt(limitNumber || '0');
        workersStore.getByCriteria({
            name: name,
            tel: tel,
            page: _page,
            limit: _limit
        }).then((response) => {
            setRows(response.data.sort((a: any, b: any) => a.firstName.localeCompare(b.firstName)));
            setTotal(response.total);
            setIsLoading(false);
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        });
    };

    const addRef = (el: any) => {
        if (el && !inputRefs.current.includes(el)) {
            inputRefs.current.push(el);
        }
    };

    const handleKeyDown = (event: any, index: number) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (index + 1 < inputRefs.current.length) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    useEffect(() => {
        handleSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box component={'div'}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Typography>การจัดการ</Typography>
                <Typography variant="h6" color="text.primary">คนงาน</Typography>
            </Breadcrumbs>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2} alignItems={'end'}>
                                <Grid item xs={5} >
                                    <TextField id="outlined-basic" label="ชื่อ-นามสกุล" variant="outlined" fullWidth
                                        onChange={(event) => setName(event.target.value)} value={name}
                                        inputRef={addRef}
                                        onKeyDown={(e) => handleKeyDown(e, 0)}
                                    />
                                </Grid>
                                <Grid item xs={5} >

                                    <TextField id="outlined-basic" label="เบอร์โทร" variant="outlined" fullWidth
                                        onChange={(event) => setTel(event.target.value)} value={tel}
                                        inputRef={addRef}
                                        onKeyDown={(e) => handleKeyDown(e, 1)}
                                    />
                                </Grid>
                                <Grid item xs={2} textAlign={'end'} pb={0.5} >
                                    {((permission && permission.create) || authStore.user?.isSuperAdmin) && <Button variant="contained" onClick={() => handleSearch()} startIcon={<SearchIcon />}>ค้นหา</Button>}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent={'end'}>
                                        <Grid item xs={9}>
                                            <Typography>ทั้งหมด {total.toLocaleString()} รายการ</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <DownloadTableExcel
                                                filename={`workers-${dayjs().format('DD-MM-YYYY')}`}
                                                sheet={`workers-${dayjs().format('DD-MM-YYYY')}`}
                                                currentTableRef={exportRef.current}
                                            >
                                                <IconButton aria-label="download"  >
                                                    <DownloadIcon />
                                                </IconButton>
                                            </DownloadTableExcel>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton aria-label="print" onClick={() => reactToPrintFn()}>
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1}>
                                            {((permission && permission.create) || authStore.user?.isSuperAdmin) && <Button variant="contained" startIcon={<NoteAddIcon />} onClick={() => navigate('/setting-worker-create')}>สร้าง</Button>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell >ชื่อ</TableCell>
                                                    <TableCell>นามสกุล</TableCell>
                                                    <TableCell>เบอร์โทร</TableCell>
                                                    <TableCell>หมายเหตุ</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows && rows.map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.firstName}</TableCell>
                                                        <TableCell>{row.lastName}</TableCell>
                                                        <TableCell>{row.tel}</TableCell>
                                                        <TableCell>{row.note}</TableCell>
                                                        <TableCell align="right">
                                                            <IconButton aria-label="edit" onClick={() => navigate(`/setting-worker-edit/${row.id}`)}>
                                                                <KeyboardArrowRightIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box mt={3}>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                                            count={total}
                                            onPageChange={(e, newPage) => {
                                                setPage(newPage);
                                                handleSearch(newPage.toString());
                                            }}
                                            page={page}
                                            rowsPerPage={limit}
                                            onRowsPerPageChange={(e: any) => {
                                                setRows([]);
                                                setLimit(e.target.value);
                                                setPage(0);
                                                handleSearch('0', e.target.value.toString());
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <SimpleBackdrop open={isLoading} />
            <Box sx={{ display: 'none' }}>
                <PrintSettingWorkers title={'รายการคนงาน'} data={rows} ref={printRef} />
            </Box>
            <Box sx={{ display: 'none' }}>
                <ExportFileSettingWorkers title={'รายการคนงาน'} data={rows} ref={exportRef} />
            </Box>
        </Box>
    );
};

export default SettingWorkers;
