import { Box, Breadcrumbs, Button, Card, CardContent, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import vendorsStore from "../../stores/VendorsStore";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import { useReactToPrint } from "react-to-print";
import PrintSettingVendors from "../../components/Report/Pdf/PrintSettingVendors";
import { DownloadTableExcel } from "react-export-table-to-excel";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExportFileSettingVendors from "../../components/Report/Excel/ExportFileSettingVendors";
import authStore from "../../stores/AuthStore";

const Vendors: React.FC = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [vendorName, setVendorName] = useState('');
    const [rows, setRows] = useState<any[]>([]);
    const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/vendors');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const printRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({ contentRef: printRef });
    const exportRef = useRef(null);

    useEffect(() => {
        handleSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = (pageNumber?: string, limitNumber?: string) => {
        setIsLoading(true);
        setRows([]);
        const _page = parseInt(pageNumber || '0');
        const _limit = parseInt(limitNumber || '0');
        vendorsStore.getByCriteria({
            name: vendorName,
            page: _page,
            limit: _limit
        }).then((result) => {
            setRows(result.data);
            setTotal(result.total);

            setIsLoading(false);
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        });
    };

    return (
        <Box component={'div'}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Typography>การจัดการ</Typography>
                <Typography variant="h6" color="text.primary">ผู้ขาย</Typography>
            </Breadcrumbs>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2} alignItems={'end'}>
                                <Grid item xs={10} >
                                    <TextField id="outlined-basic" label="ชื่อผู้ขาย" variant="outlined" fullWidth
                                        onChange={(event) => setVendorName(event.target.value)} value={vendorName}
                                    />
                                </Grid>
                                <Grid item xs={2} textAlign={'end'} pb={0.5} >
                                    <Button variant="contained" onClick={() => handleSearch()} startIcon={<SearchIcon />}>ค้นหา</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent={'end'}>
                                        <Grid item xs={9}>
                                            <Typography>ทั้งหมด {total.toLocaleString()} รายการ</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <DownloadTableExcel
                                                filename={`vendors-${dayjs().format('DD-MM-YYYY')}`}
                                                sheet={`vendors-${dayjs().format('DD-MM-YYYY')}`}
                                                currentTableRef={exportRef.current}
                                            >
                                                <IconButton aria-label="download"  >
                                                    <DownloadIcon />
                                                </IconButton>
                                            </DownloadTableExcel>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton aria-label="print" onClick={() => reactToPrintFn()}>
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1}>
                                            {((permission && permission.create) || authStore.user?.isSuperAdmin) && <Button variant="contained" startIcon={<NoteAddIcon />} onClick={() => navigate('/vendor-create')}>สร้าง</Button>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width={300}>ชื่อผู้ขาย</TableCell>
                                                    <TableCell width={500}>ที่อยู่</TableCell>
                                                    <TableCell width={100}>เบอร์โทร</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows && rows.map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.name}</TableCell>
                                                        <TableCell>{row.address}</TableCell>
                                                        <TableCell>{row.tel}</TableCell>
                                                        <TableCell align="right">
                                                            <IconButton aria-label="edit" onClick={() => navigate(`/vendor-edit/${row.id}`)}>
                                                                <KeyboardArrowRightIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box mt={3}>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                                            count={total}
                                            onPageChange={(e, newPage) => {
                                                setPage(newPage);
                                                handleSearch(newPage.toString());
                                            }}
                                            page={page}
                                            rowsPerPage={limit ? limit : 10}
                                            onRowsPerPageChange={(e: any) => {
                                                setRows([]);
                                                setLimit(e.target.value);
                                                setPage(0);
                                                handleSearch('0', e.target.value.toString());
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <SimpleBackdrop open={isLoading} />
            <Box sx={{ display: 'none' }}>
                <PrintSettingVendors ref={printRef} title={'รายการผู้ขาย'} data={rows} />
            </Box>
            <Box sx={{ display: 'none' }}>
                <ExportFileSettingVendors ref={exportRef} title={'รายการผู้ขาย'} data={rows} />
            </Box>
        </Box>
    );
};

export default Vendors;
