import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Box, Breadcrumbs, Button, Card, CardContent, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import SearchIcon from '@mui/icons-material/Search';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useReactToPrint } from 'react-to-print';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/th';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import SimpleBackdrop from '../../components/SimpleBackdrop';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import authStore from '../../stores/AuthStore';
import { useNavigate } from 'react-router-dom';
import PrintContracts from '../../components/Report/Pdf/Document/PrintContracts';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import contractStore from '../../stores/ContractStore';
import projectsStore from '../../stores/ProjectsStore';
import housesStore from '../../stores/HousesStore';
import masterDataStore from '../../stores/MasterDataStore';
import ExportFileContracts from '../../components/Report/Excel/ExportFileContracts';

const Contracts: React.FC = () => {
  const navigate = useNavigate();
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/contracts');
  const [projectData, setProjectData] = useState([]);
  const [houseData, setHouseData] = useState([]);
  const [total, setTotal] = useState(0);
  const printRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const exportRef = useRef(null);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs().startOf('month'));
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs());
  const [rows, setRows] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateTypes, setDateTypes] = useState('createDate');
  const [project, setProject] = useState('all');
  const [house, setHouse] = useState('all');
  const [workerName, setWorkerName] = useState('');
  const [contractType, setContractType] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [houseDataFilter, setHouseDataFilter] = useState([]);
  const [sortType, setSortType] = useState({ name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" });
  const sortOption = [
    { name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" },
    { name: "วันที่บันทึก: เก่าสุด", variable: "createAtASC", sortBy: "createAt", sortType: "ASC" },
    { name: "วันที่เอกสาร: ล่าสุด", variable: "docDateDESC", sortBy: "docDate", sortType: "DESC" },
    { name: "วันที่เอกสาร: เก่าสุด", variable: "docDateASC", sortBy: "docDate", sortType: "ASC" },
    { name: "วันที่แก้ไข: ล่าสุด", variable: "updateAtDESC", sortBy: "updateAt", sortType: "DESC" },
    { name: "วันที่แก้ไข: เก่าสุด", variable: "updateAtASC", sortBy: "updateAt", sortType: "ASC" },
  ];
  const [sortValue, setSortValue] = useState("createAtDESC");

  const handleChangeDateType = (event: ChangeEvent<HTMLInputElement>) => {
    setDateTypes((event.target as HTMLInputElement).value);
  };

  const handleChangeProject = (event: SelectChangeEvent) => {
    setProject((event.target as HTMLInputElement).value);
    const _houseData = houseData.filter((house: any) => house?.project?.id === (event.target as HTMLInputElement).value);
    setHouseDataFilter(_houseData);
  };

  const handleChangeHouse = (event: SelectChangeEvent) => {
    setHouse((event.target as HTMLInputElement).value);
  };

  const handleChangeContractType = (event: ChangeEvent<HTMLInputElement>) => {
    setContractType(event.target.value as string);
  };

  window.addEventListener("beforeunload", function (event) {
    localStorage.removeItem('dateFrom');
    localStorage.removeItem('dateTo');
    localStorage.removeItem('sortType');
    localStorage.removeItem('limit');
    localStorage.removeItem('page');
  });

  useEffect(() => {
    const _dateFrom = localStorage.getItem('dateFrom');
    const _dateTo = localStorage.getItem('dateTo');
    const dateF = dayjs(_dateFrom ? _dateFrom : dateFrom);
    const dateT = dayjs(_dateTo ? _dateTo : dateTo);
    const _limit = localStorage.getItem('limit');
    const _page = localStorage.getItem('page');
    setDateFrom(dateF);
    setDateTo(dateT);
    setLimit(_limit ? parseInt(_limit) : limit);
    setPage(_page ? parseInt(_page) : page);
    const _sortType: any = localStorage.getItem('sortType');
    const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
    setSortType(_sortTypeConvert || sortType);
    setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
    if (!_sortType) {
      localStorage.setItem('sortType', JSON.stringify(sortType));
    }
    if (!_limit) {
      localStorage.setItem('limit', '10');
    }
    if (!_page) {
      localStorage.setItem('page', '0');
    }
    handleSearch(_page || undefined, _limit || undefined, dateF, dateT, _sortTypeConvert);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (pageNumber?: string, limitNumber?: string, dateF?: Dayjs, dateT?: Dayjs, _sortType?: string, isRefresh = false) => {
    setIsLoading(true);
    setRows([]);
    const _page = parseInt(pageNumber || '0');
    const _limit = parseInt(limitNumber || '0');
    const data = {
      dateTypes: dateTypes,
      dateFrom: dateF ? dateF.format('YYYY-MM-DD') : dateFrom?.format('YYYY-MM-DD'),
      dateTo: dateT ? dateT.format('YYYY-MM-DD') : dateTo?.format('YYYY-MM-DD'),
      project: project,
      house: house,
      contractType: contractType,
      workerName: workerName,
      page: _page,
      limit: _limit,
      sortType: _sortType ? _sortType : sortType,
    };
    contractStore.getByCriteria(data).then((res) => {
      setRows(res.data);
      setTotal(res.total);
      if ((res?.total && res.total <= 10) || isRefresh === true) {
        setPage(0);
        setLimit(10);
      };
      const _sortType = localStorage.getItem('sortType');
      const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
      setSortType(_sortTypeConvert || sortType);
      setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
      if (!_sortType) {
        localStorage.setItem('sortType', JSON.stringify(sortType));
      }
      setIsLoading(false);
    }).catch((err) => {
      console.error(err);
      setIsLoading(false);
    });
  };

  const handleChangeSort = (e: any) => {
    const findSortType: any = sortOption.find((item: any) => item.variable === e.target.value);
    localStorage.setItem('sortType', JSON.stringify(findSortType));
    setSortType(findSortType);
    setSortValue(e.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      projectsStore.getAll(),
      housesStore.getAll(),
      masterDataStore.getContractTypeAll()
    ]).then(([projectData, houseData, contractTypeData]) => {
      setProjectData(projectData);
      setHouseData(houseData);
      setHouseDataFilter(houseData);
      const _dateFrom = localStorage.getItem('dateFrom');
      const _dateTo = localStorage.getItem('dateTo');
      const dateF = dayjs(_dateFrom ? _dateFrom : dateFrom);
      const dateT = dayjs(_dateTo ? _dateTo : dateTo);
      const _limit = localStorage.getItem('limit');
      const _page = localStorage.getItem('page');
      setDateFrom(dateF);
      setDateTo(dateT);
      setLimit(_limit ? parseInt(_limit) : limit);
      setPage(_page ? parseInt(_page) : page);
      const _sortType: any = localStorage.getItem('sortType');
      const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
      setSortType(_sortTypeConvert || sortType);
      setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
      if (!_sortType) {
        localStorage.setItem('sortType', JSON.stringify(sortType));
      }
      if (!_limit) {
        localStorage.setItem('limit', '10');
      }
      if (!_page) {
        localStorage.setItem('page', '0');
      }
      handleSearch(_page || undefined, _limit || undefined, dateF, dateT, _sortTypeConvert);
      setIsLoading(false);
    }).catch((err) => {
      console.error(err);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component={'div'}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>เอกสาร</Typography>
        <Typography variant="h6" color="text.primary">สัญญา</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems={'end'}>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={dateTypes}
                      onChange={handleChangeDateType}
                    >
                      <FormControlLabel value="createDate" control={<Radio />} label="วันที่บันทึก" />
                      <FormControlLabel value="docDate" control={<Radio />} label="วันที่เอกสาร" />
                    </RadioGroup>
                  </FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                        label="ตั้งแต่วันที่"
                        value={dateFrom}
                        onChange={(newValue) => setDateFrom(newValue)}
                      />
                      <DatePicker
                        label="ถึงวันที่"
                        value={dateTo}
                        onChange={(newValue) => setDateTo(newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <InputLabel>โครงการ</InputLabel>
                    <Select
                      label="โครงการ"
                      value={project}
                      onChange={handleChangeProject}
                    >
                      <MenuItem value={'all'}>ทั้งหมด</MenuItem>
                      {projectData && projectData?.length > 0 && projectData?.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <InputLabel>บ้าน</InputLabel>
                    <Select
                      label="บ้าน"
                      onChange={handleChangeHouse}
                      value={house}
                    >
                      <MenuItem value={'all'}>ทั้งหมด</MenuItem>
                      {houseDataFilter && houseDataFilter?.length > 0 && houseDataFilter?.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3.5}>
                  {/* NOTE:May use in the future */}
                  {/* <FormControl fullWidth >
                    <InputLabel>ประเภทสัญญา</InputLabel>
                    <Select
                      value={contractType}
                      label="ประเภทสัญญา"
                      onChange={handleChangeContractType}
                    >
                      <MenuItem value={"all"} >
                        ทั้งหมด
                      </MenuItem>
                      {contractTypeData.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <TextField label="ชื่อสัญญา" value={contractType} onChange={handleChangeContractType} variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={3.5}>
                  <TextField label="ชื่อผู้รับเหมา" variant="outlined" fullWidth
                    onChange={(event) => {
                      setWorkerName(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">เรียงตาม
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sortValue}
                      label="sortType"
                      onChange={handleChangeSort}
                    >
                      {sortOption.map((option: any, index: number) => (
                        <MenuItem key={`sort-option-${index}`} value={option.variable}>{option.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} textAlign={'end'} pb={0.5}>
                  <Button variant="contained"
                    onClick={() => handleSearch(undefined, undefined, undefined, undefined, undefined, true)}
                    startIcon={<SearchIcon />}>ค้นหา</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={9}>
                      <Typography>ทั้งหมด {total?.toLocaleString()} รายการ</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <DownloadTableExcel
                        filename={`document-contracts-${dayjs(dateFrom).format('DD-MM-YYYY')} - ${dayjs(dateTo).format('DD-MM-YYYY')}`}
                        sheet={`document-contracts-${dayjs(dateFrom).format('DD-MM-YYYY')} - ${dayjs(dateTo).format('DD-MM-YYYY')}`}
                        currentTableRef={exportRef.current}
                      >
                        <IconButton aria-label="download"  >
                          <DownloadIcon />
                        </IconButton>
                      </DownloadTableExcel>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="print" onClick={() => reactToPrintFn()}>
                        <PrintIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      {((permission && permission.create) || authStore.user?.isSuperAdmin) && <Button variant="contained" startIcon={<NoteAddIcon />} onClick={() => navigate('/contract-create')}>สร้าง</Button>}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>วันที่เอกสาร</TableCell>
                          <TableCell>ชื่อโครงการ</TableCell>
                          <TableCell>บ้าน</TableCell>
                          <TableCell>ชื่อผู้รับเหมา</TableCell>
                          <TableCell>ชื่อสัญญา</TableCell>
                          <TableCell>มูลค่า</TableCell>
                          <TableCell width={100}>บันทึก</TableCell>
                          <TableCell width={100}>แก้ไข</TableCell>
                          <TableCell ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows && rows?.length > 0 && rows.map((row: any, index: number) => (
                          <TableRow
                            key={row.id + index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>{dayjs(row.contractDate).format('DD-MM-YYYY')}</TableCell>
                            <TableCell>{row?.project?.name}</TableCell>
                            <TableCell>{row?.house?.name}</TableCell>
                            <TableCell>{row?.worker?.firstName ?? ''} {row?.worker?.lastName ?? ''}</TableCell>
                            <TableCell>{row?.title}</TableCell>
                            <TableCell>{parseFloat(row?.total || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                            <TableCell >{<b>{row.createBy?.name}</b>} {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                            <TableCell>{<b>{row.updateBy?.name}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''} </TableCell>
                            <TableCell align="right">
                              <IconButton aria-label="edit" onClick={() => navigate(`/contract-edit/${row.id}`)}>
                                <KeyboardArrowRightIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        {!rows && (
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell colSpan={10} align="center">ไม่พบข้อมูล</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                      count={total}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        handleSearch(newPage.toString());
                        localStorage.setItem('page', newPage.toString());
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setRows([]);
                        setLimit(e.target.value);
                        setPage(0);
                        handleSearch('0', e.target.value.toString());
                        localStorage.setItem('limit', e.target.value.toString());
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <Box sx={{ display: "none" }}>
        <PrintContracts ref={printRef} title={'รายการสัญญาเหมา'} data={rows} />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileContracts ref={exportRef} title={'รายการสัญญาเหมา'} data={rows} />
      </Box>
    </Box >
  );
};


export default Contracts;
