import { Autocomplete, Box, Breadcrumbs, Button, ButtonProps, Card, CardContent, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Link, Radio, RadioGroup, Stack, TextField, Typography, createFilterOptions } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import authStore from "../../stores/AuthStore";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { v4 as uuid4 } from 'uuid';
import dayjs, { Dayjs } from "dayjs";
import purchasesStore from "../../stores/PurchasesStore";
import vendorsStore from "../../stores/VendorsStore";
import productsStore from "../../stores/ProductsStore";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import DialogCreateVendor from "../../components/DialogCreateVendor";
import DialogCreateProduct from "../../components/DialogCreateProduct";
import DialogConfirm from "../../components/DialogConfirm";
import { enqueueSnackbar } from "notistack";
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from "react-to-print";
import PrintOnePO from "../../components/Report/Pdf/PrintOnePO";
import companyStore from "../../stores/CompanyStore";
import imagesStore from "../../stores/ImagesStore";
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import UploadFileIcon from '@mui/icons-material/UploadFile';

interface VendorOptionType {
  inputValue?: string;
  name: string;
}

interface ProductOptionType {
  inputValue?: string;
  name: string;
  size?: string;
  color?: string;
}
const filterVendor = createFilterOptions<VendorOptionType>();
const filterProduct = createFilterOptions<ProductOptionType>();

const PurchaseCreate: React.FC = () => {

  const { id } = useParams();
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/purchases');
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: '',
    message: '',
    color: 'primary' as ButtonProps['color'],
    type: 'create' as 'create' | 'edit' | 'delete' | 'confirm-duplicate'
  });
  const [isLoading, setIsLoading] = useState(false);
  const [docDate, setDocDate] = useState<Dayjs | null>(dayjs());
  const [refNo, setRefNo] = useState('');
  const [deliveryDate, setDeliveryDate] = useState<Dayjs | null>(dayjs());
  const [vendorsList, setVendorsList] = useState<any[]>([]);
  const [vendorValue, setVendorValue] = useState<VendorOptionType | null>(null);
  const [productList, setProductList] = useState<any[]>([]);
  const [openAddVendorDialog, setOpenAddVendorDialog] = useState(false);
  const [dialogCreateVendorValue, setDialogCreateVendorValue] = useState('');
  const [shipTo, setShipTo] = useState('');
  const [vendors, setVendors] = useState<any>();
  const [productValue, setProductValue] = useState<ProductOptionType[]>([]);
  const [openAddProductDialog, setOpenAddProductDialog] = useState(false);
  const [dialogCreateProductValue, setDialogCreateProductValue] = useState('');
  const [conditions, setConditions] = useState('');
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totalAfterDiscount, setTotalAfterDiscount] = useState(0);
  const [vatType, setVatType] = useState('include-vat');
  const [vat, setVat] = useState(0);
  const [note, setNote] = useState('');
  const [grandTotal, setGrandTotal] = useState(0);
  const [docNo, setDocNo] = useState('');
  const [itemsOld, setItemsOld] = useState<any[]>([]);
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [itemLength, setItemLength] = useState(0);
  const [items, setItems] = useState<any[]>([{ id: uuid4() }]);
  const navigate = useNavigate();
  const [companyInfo, setCompanyInfo] = useState<any>([]);
  const [images, setImages] = useState<any[]>([]);
  const [deleteImages, setDeleteImages] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imageList, setImageList] = useState<any[]>([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const printRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });

  const handleChangeVatType = (event: ChangeEvent<HTMLInputElement>) => {
    setVatType((event.target as HTMLInputElement).value);
    let grandTotal = 0;
    if ((event.target as HTMLInputElement).value === 'exclude-vat') {
      grandTotal = parseFloat(totalAfterDiscount.toString()) + parseFloat(vat.toString());
    } else {
      grandTotal = totalAfterDiscount;
    }
    setGrandTotal(grandTotal);
  };


  const handleAddItem = () => {
    setItems([...items, {
      id: uuid4()
      , isNew: true
    }]);
  };
  const handleRemoveItem = (id: string) => {
    setItems(items.filter((item: any) => item.id !== id));
    calculatorSummary();
  };
  const calculate = (index: number, key: string, value: any) => {
    const newItems = [...items];
    newItems[index][key] = parseFloat(value);
    setItems(newItems);
    if (key === 'quantity' || key === 'price') {
      const newTotal = parseFloat(value) * parseFloat(key === 'quantity' ? newItems[index].price : newItems[index].quantity);
      newItems[index].total = newTotal;
    }
    if (key === 'total') {
      newItems[index].total = parseFloat(value);
    }
    calculatorSummary();
  };

  const calculatorSummary = () => {
    const total = items.reduce((a: any, b: any) => a + parseFloat((b?.total) || 0), 0);
    setTotal(total);

    const afterDiscount = parseFloat(total) - discount;
    setTotalAfterDiscount(afterDiscount);

    const totalAfterDiscount = parseFloat(total) - discount;
    setTotalAfterDiscount(totalAfterDiscount);

    const vat = afterDiscount * 0.07;
    setVat(vat);
    let grandTotal = 0;
    if (vatType === 'exclude-vat') {
      grandTotal = afterDiscount + vat;
    } else {
      grandTotal = afterDiscount;
    }
    setGrandTotal(grandTotal);
  };

  const handleDelete = async () => {
    if (id) {
      try {
        await purchasesStore.update(id, {
          isDeleted: true,
          updateBy: authStore.user?.id,
          updateAt: new Date()
        });
        enqueueSnackbar('ลบข้อมูลสำเร็จ', { variant: 'success' });
        setIsLoading(false);
        navigate('/purchases');
      }
      catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    }


  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (!vendors) {
        enqueueSnackbar('กรุณาเลือกบริษัทคู่ค้า', { variant: 'warning' });
        setIsLoading(false);
        return;
      }

      if (!shipTo) {
        enqueueSnackbar('กรุณาเลือกที่อยู่จัดส่ง', { variant: 'warning' });
        setIsLoading(false);
        return;
      }
      if (!items[0].name) {
        enqueueSnackbar('กรุณาเลือกรายการสินค้า/บริการ', { variant: 'warning' });
        setIsLoading(false);
        return;
      }
      if (!total) {
        enqueueSnackbar('กรุณากรอกจำนวนเงิน', { variant: 'warning' });
        setIsLoading(false);
        return;
      }

      const details = await items.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          total: item.total,
          product: item.product,
          unit: item.unit,
          createBy: authStore.user?.id,
          createAt: new Date(),
        };
      });
      await purchasesStore.create({
        docDate: docDate,
        refNo: refNo,
        deliveryDate: deliveryDate,
        shipTo: shipTo,
        vendor: vendors?.id,
        condition: conditions,
        total: total,
        note: note,
        discount: discount,
        vat: vat,
        vatType: vatType,
        grandTotal: grandTotal,
        createBy: authStore.user?.id,
        createAt: new Date(),
        details: details,
      }).then((res) => {
        handleFileUpload(res.id);
        handleUpdateVendor();
        navigate(`/purchase-edit/${res.id}`);
      }).catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleEdit = async () => {
    setIsLoading(true);
    try {
      if (!vendors) {
        enqueueSnackbar('กรุณาเลือกบริษัทคู่ค้า', { variant: 'warning' });
        setIsLoading(false);
        return;
      }

      if (!shipTo) {
        enqueueSnackbar('กรุณาเลือกที่อยู่จัดส่ง', { variant: 'warning' });
        setIsLoading(false);
        return;
      }
      if (!items[0]?.product) {
        enqueueSnackbar('กรุณาเลือกรายการสินค้า/บริการ', { variant: 'warning' });
        setIsLoading(false);
        return;
      }
      if (!total) {
        enqueueSnackbar('กรุณากรอกจำนวนเงิน', { variant: 'warning' });
        setIsLoading(false);
        return;
      }

      if (id) {
        handleEditFileUpload(id);
        handleUpdateVendor();
        const itemsUpdate = items.filter((item: any) => {
          return itemsOld.find((itemOld: any) => itemOld.id === item.id);
        });
        const itemsCreate = items.filter((item: any) => {
          return !itemsOld.find((itemOld: any) => itemOld.id === item.id);
        });

        const itemsDetele = itemsOld.filter((itemOld: any) => {
          return !items.find((item: any) => itemOld.id === item.id);
        });

        const details = await itemsUpdate.map((item: any) => {
          const product = item.product ? (item.product.id ? item.product.id : item.product) : null;
          delete item.product;
          delete item?.name;
          return {
            id: item.id,
            name: item.name,
            quantity: item.quantity,
            price: item.price,
            total: item.total,
            product: product,
            unit: item.unit,
            purchaseOrder: id,
            updateBy: authStore.user?.id,
            updateAt: new Date(),
          };
        });
        purchasesStore.update(id, {
          docDate: docDate,
          refNo: refNo,
          deliveryDate: deliveryDate,
          shipTo: shipTo,
          vendor: vendors?.id,
          condition: conditions,
          total: total,
          note: note,
          discount: discount,
          vat: vat,
          vatType: vatType,
          grandTotal: grandTotal,
          updateBy: authStore.user?.id,
          updateAt: new Date(),
          details: details,
        }).catch((err) => {
          console.error(err);
        });
        if (itemsCreate.length > 0) {
          const createDetails = await itemsCreate.map((item: any) => {
            return {
              purchaseOrder: id,
              name: item.name,
              quantity: item.quantity,
              price: item.price,
              total: item.total,
              product: item.product,
              unit: item.unit,
              createBy: authStore.user?.id,
              createAt: new Date(),
            };
          });
          await purchasesStore.createDetail(createDetails).catch((err) => {
            console.error(err);
            setIsLoading(false);
          });
        }
        if (itemsDetele.length > 0) {
          await itemsDetele.forEach((item: any) => {
            purchasesStore.deleteDetail(item.id).catch((err) => {
              enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
              console.error(err);
              setIsLoading(false);
            });
          });

        }
        setIsLoading(false);
        navigate('/purchases');
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const checkIfAlready = async () => {
    setOpenConfirm({ ...openConfirm, open: false });
    setIsLoading(true);

    const isExist = await purchasesStore.checkExpenseAlreadyExist({
      vendorId: vendors?.id,
      docNo: docNo
    });

    if (isExist) {
      setOpenConfirm({
        open: true,
        title: 'เลขที่เอกสารซ้ำ',
        message: 'คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่',
        color: 'warning',
        type: 'confirm-duplicate'
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      handleSave();
    }
  };

  const addRef = (el: any) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
    setItemLength(inputRefs.current.length - 1);
  };

  const handleKeyDown = (event: any, index: number, i = 0) => {
    const currentInput = event.target;
    if (event.key === "Enter") {
      const currentIndex = Array.from(inputRefs.current).indexOf(currentInput);
      const nextIndex = currentIndex + 1;
      if (i === -1) {
        inputRefs.current[8].focus();
      }
      else if (index === 5 && i === 0) {
        if (inputRefs.current[9]) {
          inputRefs.current[9].focus();
        } else {
          inputRefs.current[6].focus();
        }
      } else if (currentIndex === itemLength) {
        inputRefs.current[6].focus();
      } else {
        if (nextIndex < inputRefs.current.length) {
          inputRefs.current[nextIndex].focus();
          event.preventDefault();
        }
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newSelectedFiles = Array.from(files);
      setSelectedFiles([...selectedFiles, ...newSelectedFiles]);
    }
  };

  const handleFileRemove = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleFileRemoveImageList = (file: any) => {
    const updatedImages = images.filter((image) => image.id !== file.id);
    setImages(updatedImages);
    setDeleteImages([...deleteImages, file]);
  };

  const handleFileUpload = (refId: string) => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append("refType", "purchaseOrder");
        formdata.append("refId", refId);
        formdata.append('file', file);
        formdata.append('createBy', authStore.user?.id);
        formdata.append("createAt", new Date(Date.now() + index * 1000).toISOString());

        await imagesStore.upload(formdata)
          .catch(err => console.error("err", err));
      });
    }
  };

  const handleEditFileUpload = async (id: string) => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach(async (file: any, index: number) => {
        const formdata = new FormData();
        formdata.append("refType", "purchaseOrder");
        formdata.append("refId", id);
        formdata.append('file', file);
        formdata.append('updateBy', authStore.user?.id);
        formdata.append("updateAt", new Date(Date.now() + index * 1000).toISOString());
        await imagesStore.upload(formdata)
          .catch(err => console.error("err", err));
      });
    }
    if (deleteImages.length > 0) {
      await deleteImages.forEach(async (item: any) => {
        await imagesStore.update(item.id, {
          updateBy: authStore.user?.id,
          updateAt: new Date(),
          isDeleted: true
        }).catch(err => console.error("err deleteImages", err));
      });
    }
  };

  const handleChangeVendor = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const value = e.target.value;
    switch (name) {
      case "branch": setVendors({ ...vendors, branch: value }); break;
      case "address": setVendors({ ...vendors, address: value }); break;
      case "taxId": setVendors({ ...vendors, taxId: value }); break;
      case "tel": setVendors({ ...vendors, tel: value }); break;
      case "email": setVendors({ ...vendors, email: value }); break;
    }
  };

  const handleUpdateVendor = () => {
    try {
      vendorsStore.update(vendors.id, {
        ...vendors
      });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    if (!id) {
      vendorsStore.getAll().then((res) => {
        setVendorsList(res);
        productsStore.getAll().then((res) => {
          setProductList(res.sort((a: any, b: any) => a.name.localeCompare(b.name)));
          setIsLoading(false);
        }).catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
      }).catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
    } else {
      purchasesStore.get(id).then((res) => {
        setDocDate(dayjs(res.docDate));
        setRefNo(res.refNo);
        setDeliveryDate(dayjs(res.deliveryDate));
        setDocNo(res?.docNo || '');
        setShipTo(res?.shipTo || {});
        setVendors(res?.vendor || {});
        setVendorValue(res.vendor?.name || '');
        setItems(res?.purchaseOrderDetail || []);
        setItemsOld(res?.purchaseOrderDetail || []);
        setConditions(res?.condition || '');
        setNote(res?.note || '');
        setTotal(res?.total || 0);
        setDiscount(res?.discount || 0);
        setImages(res.images);
      });
      productsStore.getAll().then((res) => {
        setProductList(res.sort((a: any, b: any) => a.name.localeCompare(b.name)));
        setIsLoading(false);
      }).catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
      setIsLoading(false);
    }
    try {
      companyStore.getAll().then((res) => {
        setCompanyInfo(res[0]);
      });
    } catch (err) {
      console.error(err);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calculatorSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount, items]);

  return (
    <Box component={'div'}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton aria-label="delete" onClick={() => navigate('/purchases')}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={'flex'} alignItems={'center'}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ใบสั่งซื้อ</Typography>
            <Link component="button" onClick={() => navigate('/purchases')}>รายการใบสั่งซื้อ</Link>
            <Typography variant="h6" color="text.primary">{id ? 'แก้ไข' : 'สร้าง'}</Typography>
          </Breadcrumbs>
        </Grid>
        {/*NOTE: print contract */}
        <Grid item xs={1.5} display={'flex'} justifyContent={'end'}>
          {id &&
            <Button variant="outlined" color="success" startIcon={<PrintIcon />} onClick={() => reactToPrintFn()}>
              พิมพ์ใบสั่งซื้อ
            </Button>
          }
        </Grid>
        <Grid item xs={1.5} display={'flex'} justifyContent={'end'}>
          {((permission && permission.delete) || authStore.user?.isSuperAdmin) && id &&
            <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => setOpenConfirm({
              open: true,
              title: 'ลบเอกสาร',
              message: 'คุณต้องการลบเอกสารนี้ใช่หรือไม่',
              color: 'error',
              type: 'delete'
            })}>ลบเอกสาร</Button>
          }
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>

                {/* row 1 */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <TextField id="docNo" label="เลขที่ใบสั่งซื้อ" variant="outlined" fullWidth
                        onChange={(event) => { setDocNo(event.target.value); }} value={docNo}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                        <DatePicker
                          label="วันที่เอกสาร"
                          value={docDate}
                          onChange={(newValue) => setDocDate(newValue)}
                          sx={{ width: '100%' }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                      <TextField id="refNo" label="เลขที่อ้างอิง" variant="outlined" fullWidth
                        value={refNo}
                        onChange={(event) => setRefNo(event.target.value)}
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          handleKeyDown(e, 0);
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                        <DatePicker
                          label="วันที่กำหนดส่ง"
                          value={deliveryDate}
                          onChange={(newValue) => setDeliveryDate(newValue)}
                          sx={{ width: '100%' }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField id="deliveredAddress" label="ที่อยู่จัดส่ง" variant="outlined" fullWidth
                        value={shipTo}
                        onChange={(event) => setShipTo(event.target.value)}
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          handleKeyDown(e, 1);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* row 2 */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Autocomplete
                        value={vendorValue}
                        onChange={(event, newValue) => {
                          if (typeof newValue === 'string') {
                            setTimeout(() => {
                              setOpenAddVendorDialog(true);
                              setDialogCreateVendorValue(newValue);
                            });
                          } else if (newValue && newValue.inputValue) {
                            setOpenAddVendorDialog(true);
                            setDialogCreateVendorValue(newValue.inputValue);
                          } else {
                            setVendorValue(newValue);
                            setVendors({ ...vendors, id: newValue?.id, name: newValue?.name, address: newValue?.address, tel: newValue?.tel, branch: newValue?.branch, email: newValue?.email, taxId: newValue?.taxId });
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filterVendor(options, params);

                          if (params.inputValue !== '') {
                            filtered.push({
                              inputValue: params.inputValue,
                              name: `สร้าง "${params.inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        id="vendor-autocomplete"
                        options={vendorsList}
                        getOptionLabel={(option) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.name;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        sx={{ width: '100%' }}
                        freeSolo
                        renderInput={(params) => <TextField {...params} label="ชื่อบริษัทคู่ค้า" variant="outlined"
                        />}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField id="vendor-branch" label="สาขา" variant="outlined" fullWidth onChange={(e: any) => handleChangeVendor(e, 'branch')}
                        value={vendors?.branch || ''}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField id="vendor-address" label="ที่อยู่" variant="outlined" fullWidth value={vendors?.address || ''} onChange={(e: any) => handleChangeVendor(e, 'address')} inputProps={{ readOnly: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField id="vendor-taxId" label="เลขประจำตัวผู้เสียภาษี" variant="outlined" fullWidth onChange={(e: any) => handleChangeVendor(e, 'taxId')}
                        value={vendors?.taxId || ''}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField id="vendor-tel" label="เบอร์โทร" variant="outlined" fullWidth value={vendors?.tel || ''} onChange={(e: any) => handleChangeVendor(e, 'tel')}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField id="companyEmail" label="อีเมล์" variant="outlined" fullWidth onChange={(e: any) => handleChangeVendor(e, 'email')}
                        value={vendors?.email || ''}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography>รายการสินค้า/บริการ</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                    </Grid>
                    <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                      <Typography>ลำดับ</Typography>
                    </Grid>
                    <Grid item xs={4.5}>
                      <Typography>รายการ</Typography>
                    </Grid>
                    <Grid item xs={1.75}>
                      <Typography>จำนวน</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography>หน่วย</Typography>
                    </Grid>
                    <Grid item xs={1.75}>
                      <Typography>ราคาต่อหน่วย</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>จำนวนเงิน</Typography>
                    </Grid>
                  </Grid>
                  {items.map((item: any, index: number) => {
                    return (
                      <Grid item xs={12} mt={2} key={`i-${index.toString()}`}>
                        <Grid container spacing={2} alignItems={'end'}>
                          <Grid item xs={0.5} mb={1.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                            <IconButton aria-label={`delete-${id}`} size="small" color="error" onClick={() => handleRemoveItem(items[index].id)}>
                              <RemoveCircleIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={0.5} mb={2} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                            <Typography>{index + 1}</Typography>
                          </Grid>
                          <Grid item xs={4.5}>
                            <Autocomplete
                              value={(productValue[index]?.name || item.product?.name || '') + ' ' + ((productValue[index]?.color || item?.product?.color || productValue[index]?.size || item?.product?.size) ? '(' : ' ') + (productValue[index]?.color || item?.product?.color || '') + ' ' + (productValue[index]?.size || item?.product?.size || '') + ((productValue[index]?.color || item?.product?.color || productValue[index]?.size || item?.product?.size) ? ')' : ' ')}
                              onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                  setTimeout(() => {
                                    setOpenAddProductDialog(true);
                                    setDialogCreateProductValue(newValue);
                                  });
                                } else if (newValue && newValue.inputValue) {
                                  setOpenAddProductDialog(true);
                                  setDialogCreateProductValue(newValue.inputValue);
                                } else {
                                  setProductValue({ ...productValue, [index]: newValue });
                                  const newItems = [...items];
                                  newItems[index].product = { id: newValue?.id, name: newValue?.name, unit: newValue?.unit };
                                  newItems[index].name = newValue?.name;
                                  newItems[index].unit = newValue?.unit;
                                  setItems(newItems);
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filterProduct(options, params);

                                if (params.inputValue !== '') {
                                  filtered.push({
                                    inputValue: params.inputValue,
                                    name: `สร้าง "${params.inputValue}"`
                                  });
                                }
                                return filtered;
                              }}
                              id={`item-${index}-product-autocomplete`}
                              options={productList}
                              getOptionLabel={(option) => {
                                if (typeof option === 'string') {
                                  return option;
                                }
                                if (option.inputValue) {
                                  return option.inputValue;
                                }
                                return option.name;
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              renderOption={(props, option) => (
                                <li {...props}>
                                  {option.name} {option.color || option.size ? `(${option.color || ''} ${option.size || ''})` : ''}
                                </li>
                              )}
                              sx={{ width: '100%' }}
                              freeSolo
                              renderInput={(params) => <TextField {...params} variant="outlined"
                              />}
                            />
                          </Grid>
                          <Grid item xs={1.75}>
                            <TextField id={`item-${index}-qty`} variant="outlined" fullWidth type="number"
                              inputProps={{
                                min: 0,
                              }}

                              value={item?.quantity ? parseFloat(item?.quantity) : ''}
                              onChange={(e) => {
                                calculate(index, 'quantity', e.target.value);
                              }}
                              inputRef={addRef}
                              onKeyDown={(e) => handleKeyDown(e, 2 + index, index)}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <TextField id={`item-${index}-unit`} variant="outlined" fullWidth value={item.unit || ''}
                              inputProps={{
                                min: 0,
                              }}
                              onChange={(e) => {
                                const newItems = [...items];
                                newItems[index].unit = e.target.value;
                                setItems(newItems);
                              }}
                              inputRef={addRef}
                              onKeyDown={(e) => handleKeyDown(e, 3 + index, index)}
                            />
                          </Grid>
                          <Grid item xs={1.75}>
                            <TextField id={`item-${index}-price`} variant="outlined" fullWidth type="number"
                              inputProps={{
                                min: 0,
                              }}
                              value={item.price ? parseFloat(item.price) : ''}
                              onChange={(e) => {
                                calculate(index, 'price', e.target.value);
                              }}
                              inputRef={addRef}
                              onKeyDown={(e) => handleKeyDown(e, 4 + index, index)}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField id={`item-${index}-total`} variant="outlined" fullWidth type="number"
                              inputProps={{
                                min: 0,
                              }}
                              value={item.total ? parseFloat(item.total) : ''}
                              onChange={(e) => {
                                calculate(index, 'total', e.target.value);
                              }}
                              inputRef={addRef}
                              onKeyDown={(e) => handleKeyDown(e, 5 + index, index)}
                            />
                          </Grid>
                          <Grid item xs={12} mb={2}><Divider /></Grid>
                        </Grid>

                      </Grid>
                    );
                  })
                  }
                  <Grid item xs={12}>
                    <Button variant="outlined" sx={{ width: '100%' }} onClick={handleAddItem} startIcon={<AddCircleIcon />} >เพิ่มรายการ</Button>
                  </Grid>
                  <Grid item xs={12} mt={2} >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography>เงื่อนไขอื่นๆ</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField id="conditions" variant="outlined" fullWidth
                          multiline maxRows={8}
                          onChange={(event) => {
                            setConditions(event.target.value);
                          }}
                          value={conditions}
                          inputRef={addRef}
                          onKeyDown={(e) => handleKeyDown(e, 6)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} mt={2}><Divider /></Grid>
                  <Grid item xs={12} mt={2}>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={8} >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography>เงื่อนไขอื่นๆ</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField id="conditions" variant="outlined" fullWidth
                              multiline maxRows={8}
                              onChange={(event) => {
                                setConditions(event.target.value);
                              }}
                              value={conditions}
                              inputRef={addRef}
                              onKeyDown={(e) => handleKeyDown(e, 6)}
                            />
                          </Grid>
                        </Grid>
                      </Grid> */}
                      <Grid item xs={8} >
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography>เอกสาร</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Button component="label" variant="outlined" startIcon={<UploadFileIcon />}>
                              เลือกไฟล์
                              <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png"
                                onChange={handleFileChange} />
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="caption">รองรับไฟล์นามสกุล .jpg, .jpeg, .png</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {selectedFiles.length > 0 && (
                              <Box component={'div'} mt={1}>
                                {selectedFiles.map((file: any, index: number) => (
                                  <Grid item xs={12} key={`sf-${index.toString()}`} mt={1}>
                                    <Grid container >
                                      <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                        <IconButton size="small" color="error"
                                          onClick={() => handleFileRemove(index)}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      </Grid>
                                      <Grid item xs={11.5}>
                                        <img
                                          src={URL.createObjectURL(file)}
                                          alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                          width="500"
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            {images && (
                              <Box component={'div'} mt={1}>
                                {images.map((file: any, index: number) => (
                                  <Grid item xs={12} key={`im-${index.toString()}`} mt={1}>
                                    <Grid container >
                                      <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                                        <IconButton size="small" color="error"
                                          onClick={() => handleFileRemoveImageList(file)}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      </Grid>
                                      <Grid item xs={11.5}>
                                        <img
                                          src={`${apiUrl}/uploads/${file.name}`}
                                          alt={`ตัวอย่างไฟล์ ${index + 1}`}
                                          width="500"
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ))}
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography>รวมเป็นเงิน</Typography>
                          </Grid>
                          <Grid item xs={6} textAlign={'end'}>
                            <Typography>{
                              parseFloat(total.toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                            }</Typography>
                          </Grid>
                          <Grid item xs={6} display={'flex'} alignItems={'center'}>
                            <Typography mr={1}>ส่วนลด</Typography>
                          </Grid>
                          <Grid item xs={6} textAlign={'end'}>
                            <TextField id="discount" variant="outlined" size="small" sx={{ textAlign: 'end' }} type="number"
                              onChange={(e) => {
                                setDiscount(e.target.value ? parseFloat(e.target.value) : 0);
                              }}
                              value={discount}
                              inputRef={addRef}
                              onKeyDown={(e) => handleKeyDown(e, itemLength)}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>ราคาหลังหักส่วนลด</Typography>
                          </Grid>
                          <Grid item xs={6} textAlign={'end'}>
                            <Typography>{
                              parseFloat(totalAfterDiscount.toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                            }</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl>
                              <FormLabel id="row-radio-buttons-group-label" sx={{ color: 'black' }}>ภาษีมูลค่าเพิ่ม 7%</FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={vatType}
                                onChange={handleChangeVatType}
                              >
                                <FormControlLabel value="non-vat" control={<Radio />} label="ไม่รวมภาษี" />
                                <FormControlLabel value="include-vat" control={<Radio />} label="ภาษีรวมใน" />
                                <FormControlLabel value="exclude-vat" control={<Radio />} label="ภาษีแยกนอก" />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} textAlign={'end'}>
                            <Typography>{
                              parseFloat(vat.toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                            }</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="outlined-multiline-flexible"
                              label="หมายเหตุ"
                              value={note}
                              onChange={(e) => {
                                setNote(e.target.value);
                              }
                              }
                              multiline
                              maxRows={4} fullWidth inputProps={{
                                min: 0,
                              }}
                              inputRef={addRef}
                              onKeyDown={(e) => handleKeyDown(e, itemLength, -1)}
                            ></TextField>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography>จำนวนเงินรวมทั้งสิ้น</Typography>
                          </Grid>
                          <Grid item xs={6} textAlign={'end'}>
                            <Typography>{
                              parseFloat(grandTotal.toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                            }</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Stack spacing={2} direction="row">
                              <Button variant="outlined" fullWidth onClick={() => navigate('/purchases')}>ยกเลิก</Button>
                              {!id && <Button variant="contained" fullWidth onClick={() => {
                                setOpenConfirm({
                                  open: true,
                                  title: 'บันทึกเอกสาร',
                                  message: 'คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่',
                                  color: 'primary',
                                  type: 'create'
                                });
                              }}>บันทึก</Button>}
                              {((permission && permission.edit) || authStore.user?.isSuperAdmin) && id && <Button variant="contained" fullWidth onClick={() => {
                                setOpenConfirm({
                                  open: true,
                                  title: 'แก้ไขเอกสาร',
                                  message: 'คุณต้องการแก้ไขเอกสารนี้ใช่หรือไม่',
                                  color: 'primary',
                                  type: 'edit'
                                });
                              }}>แก้ไข</Button>}
                            </Stack>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogCreateVendor name={dialogCreateVendorValue} open={openAddVendorDialog} onClose={() => setOpenAddVendorDialog(false)} onSave={(success) => {
        if (success) {
          vendorsStore.getAll().then((res) => {
            setVendorsList(res);
          });
        }
        setOpenAddVendorDialog(false);
      }} />
      <DialogCreateProduct name={dialogCreateProductValue} open={openAddProductDialog}
        onClose={() => {
          setOpenAddProductDialog(false);
        }}
        onSave={(success) => {
          if (success) {
            productsStore.getAll().then((res) => {
              setProductList(res.sort((a: any, b: any) => a.name.localeCompare(b.name)));
            });
          }
          setOpenAddProductDialog(false);
        }} />
      <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === 'delete') {
            handleDelete();
          } else if (openConfirm.type === 'create') {
            checkIfAlready();
          } else if (openConfirm.type === 'edit') {
            handleEdit();
          } else if (openConfirm.type === 'confirm-duplicate') {
            handleSave();
          }
        }} />
      <Box style={{ display: "none" }}>
        <PrintOnePO
          ref={printRef}
          title="ใบสั่งซื้อ"
          data={{
            docNo: docNo,
            docDate: docDate,
            refNo: refNo,
            deliveryDate: deliveryDate,
            shipTo: shipTo,
            vendor: vendors,
            condition: conditions,
            total: total,
            note: note,
            discount: discount,
            vat: vat,
            vatType: vatType,
            grandTotal: grandTotal,
            createBy: authStore.user?.id,
            createAt: new Date(),
          }}
          companyInfo={companyInfo}
          details={items ?? []}
        />
      </Box>
    </Box>
  );
};

export default PurchaseCreate;
