import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import buddhistEra from "dayjs/plugin/buddhistEra";
import updateLocale from "dayjs/plugin/updateLocale";
import dayjs from "dayjs";
import { ThaiBaht } from "thai-baht-text-ts";
interface PrintContractProps {
  title?: string;
  date?: string;
  data: any;
  total?: string;
  paidTotal?: string;
  companyTypes?: string;
  companyInfo?: any;
}

const PrintContract = React.forwardRef((props: PrintContractProps, ref) => {
  dayjs.extend(buddhistEra);
  dayjs.extend(updateLocale);
  dayjs.updateLocale("en", {
    months: [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ],
    weekdays: [
      "อาทิตย์",
      "จันทร์",
      "อังคาร",
      "พุธ",
      "พฤหัสบดี",
      "ศุกร์",
      "เสาร์",
    ],
  });

  return (
    <Box
      ref={ref}
      sx={{
        margin: 0,
        width: "100%",
        borderCollapse: "collapse",
        textAlign: "center",
        fontFamily: "'Angsana', sans-serif",
        paddingBottom: "20px",
        paddingLeft: "20px",
        "@page": {
          size: "A4 portrait", // Default page size is portrait
        },
        overflow: "hidden",
        fontSize: 8,
        display: "block",
        pageBreakBefore: "auto", // Allow page break before this element
        pageBreakAfter: "auto", // Allow page break before this element
        "@media print": {
          "@page": {
            size: "A4 portrait", // Subsequent pages are landscape
            marginLeft: "60px",
            marginRight: "80px",
            marginTop: "70px",
          },
          footer: {
            display: "none",
          },
          header: {
            display: "none",
          },
        },
      }}
      className="print-contract"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <Typography
            align="center"
            fontSize={"28px"}
            fontWeight={"bold"}
            fontFamily={"Angsana"}
          >
            สัญญาจอง/สัญญาจะซื้อ จะขายบ้านพร้อมที่ดิน
          </Typography>
          <Grid container sx={{ textAlign: "right" }} mt={1}>
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
              <Typography
                align="left"
                mt={1}
                className="typography-booking-selling-contract"
              >
                เขียนที่ <b>{props?.companyInfo?.name || ""}</b>
              </Typography>
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
              <Typography
                align="left"
                className="typography-booking-selling-contract"
              >
                {`วันที่
                ${dayjs(
                  props?.data?.docDate !== "Invalid Date"
                    ? props?.data?.docDate
                    : new Date()
                ).format("DD")} 
                เดือน 
                ${dayjs(
                  props?.data?.docDate !== "Invalid Date"
                    ? props?.data?.docDate
                    : new Date()
                ).format("MMMM")} 
                พ.ศ. 
                ${dayjs(
                  props?.data?.docDate !== "Invalid Date"
                    ? props?.data?.docDate
                    : new Date()
                ).format("BBBB")}`}
              </Typography>
            </Grid>
          </Grid>
          <Box
            lineHeight={2}
            mt={2}
            className="typography-booking-selling-contract"
          >
            {props?.companyTypes === "company" && (
              <Box className="text-box">
                สัญญานี้ทำขึ้นระหว่าง <b>{props?.companyInfo?.name || ""}</b>
                &nbsp; ตั้งอยู่เลขที่ <b>{props?.companyInfo?.address}</b>{" "}
                หมู่ที่&nbsp;
                <b>{props?.companyInfo?.moo}</b> ตำบล&nbsp;
                <b>{props?.companyInfo?.subDistrict?.nameTh}</b> อำเภอ&nbsp;
                <b>{props?.companyInfo?.district?.nameTh}</b> จังหวัด&nbsp;
                <b>{props?.companyInfo?.province?.nameTh}</b> โทร&nbsp;
                <b>{props?.companyInfo?.tel}</b> ซึ่งต่อไปนี้เรียกว่า “ผู้จะขาย”
              </Box>
            )}
            {props?.companyTypes === "person" && (
              <Box className="text-box">
                สัญญานี้ทำขึ้นระหว่าง <b>{props?.data?.companyName || ""}</b>
                &nbsp;ตั้งอยู่เลขที่ <b>{props?.data?.companyAddress}</b>{" "}
                หมู่ที่&nbsp;
                <b>{props?.data?.companyMoo}</b> ตำบล&nbsp;
                <b>{props?.data?.companySubDistrict?.nameTh}</b> อำเภอ&nbsp;
                <b>{props?.data?.companyDistrict?.nameTh}</b> จังหวัด&nbsp;
                <b>{props?.data?.companyProvince?.nameTh}</b> โทร&nbsp;
                <b>{props?.data?.companyTel}</b> ซึ่งต่อไปนี้เรียกว่า “ผู้จะขาย”
              </Box>
            )}
            <Box className="text-box" component={"div"}>
              ฝ่ายหนึ่งกับ&nbsp;
              <b>
                {props?.data?.customer?.firstname}&nbsp;
                {props?.data?.customer?.lastname}
              </b>
              &nbsp;เลขบัตร <b>{props?.data?.customer?.idCard}</b>
              &nbsp;ที่อยู่ติดต่อได้เลขที่{" "}
              <b>{props?.data?.customer?.address}</b>
              &nbsp;หมู่ที่ <b>{props?.data?.customer?.moo}</b> ตรอก/ซอย&nbsp;
              <b>{props?.data?.customer?.alley}</b> ถนน&nbsp;
              <b>{props?.data?.customer?.street}</b> ตำบล&nbsp;
              <b>{props?.data?.customer?.subDistrict?.nameTh}</b> อำเภอ&nbsp;
              <b>{props?.data?.customer?.district?.nameTh}</b> จังหวัด&nbsp;
              <b>{props?.data?.customer?.province?.nameTh}</b> เบอร์โทร&nbsp;
              <b>{props?.data?.customer?.tel}</b>&nbsp;
              {props?.data?.customer2 && (
                <Box component={"span"}>
                  และ
                  <b>
                    {` ${props?.data?.customer2?.firstname} ${props?.data?.customer2?.lastname} `}
                  </b>
                  เลขบัตร <b>{props?.data?.customer?.idCard}</b>
                  ที่อยู่ติดต่อได้เลขที่&nbsp;
                  <b>{props?.data?.customer2?.address}</b> หมู่ที่&nbsp;
                  <b>{props?.data?.customer2?.moo}</b> ตรอก/ซอย&nbsp;
                  <b>{props?.data?.customer2?.alley}</b> ถนน&nbsp;
                  <b>{props?.data?.customer2?.street}</b> ตำบล&nbsp;
                  <b>{props?.data?.customer2?.subDistrict?.nameTh}</b>{" "}
                  อำเภอ&nbsp;
                  <b>{props?.data?.customer2?.district?.nameTh}</b>{" "}
                  จังหวัด&nbsp;
                  <b>{props?.data?.customer2?.province?.nameTh}</b>{" "}
                  เบอร์โทร&nbsp;
                  <b>{props?.data?.customer2?.tel}</b>
                </Box>
              )}
              &nbsp;ซึ่งต่อไปนี้เรียกว่า “ผู้จะซื้อ” อีกฝ่ายหนึ่ง
              คู่สัญญาทั้งสองฝ่ายทำสัญญากัน มีข้อความดังต่อไปนี้
            </Box>
            <Box className="text-box" component={"div"} fontWeight={"bold"}>
              ข้อ 1. ที่ดินพร้อมสิ่งปลูกสร้าง
            </Box>
            <Box className="text-box-box">
              ผู้จะซื้อตกลงที่จะซื้อและผู้จะขายตกลงว่าจะขายที่ดินพร้อมสิ่งปลูกสร้าง
              <b>{props?.data?.project?.name}</b> บ้านเลขที่&nbsp;
              <b>{props?.data?.house?.land?.address}</b> โฉนดที่ดินเลขที่&nbsp;
              <b>{props?.data?.house?.land?.deedNo}</b> ระวาง&nbsp;
              <b>{props?.data?.house?.land?.mapsheet}</b> เลขที่ดิน&nbsp;
              <b>{props?.data?.house?.land?.parcelNo}</b> หน้าสำรวจ&nbsp;
              <b>{props?.data?.house?.land?.dealingFileNo}</b> ขนาดพื้นที่&nbsp;
              <b>
                {` ${props?.data?.house?.land?.squareWah
                  ? parseFloat(
                    props?.data?.house?.land?.squareWah ?? 0
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                  : "-"
                  } `}
              </b>
              /
              <b>
                {` ${props?.data?.house?.land?.rai
                  ? parseFloat(
                    props?.data?.house?.land?.rai ?? 0
                  ).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })
                  : "-"
                  } `}
              </b>
              ตารางวา/ไร่ ปรากฏตามแผนผังสังเขปแนบท้ายสัญญา
              และถือเป็นส่วนหนึ่งของสัญญานี้ เป็นอาคารพักอาศัยแบบ คสล.
              สิ่งปลูกสร้างตลอดจนรายละเอียดมีการแก้ไขเปลี่ยนแปลงเพื่อให้สอดคล้องกับระเบียบข้อบังคับของทางราชการที่เกี่ยวข้อง
              หรือคำสั่งของเจ้าพนักงานธุรการผู้มีอำนาจ
              หรือมีเหตุจำเป็นทางวิศวกรรม หรือสถาปัตยกรรม
              หรือเหตุจำเป็นอย่างอื่น
            </Box>
            <Box className="text-box-child">1.1 ราคาซื้อขาย</Box>
            <Box className="text-box-child">
              ผู้จะขายตกลงจะขาย
              ผู้จะซื้อตกลงจะซื้อบ้านพร้อมที่ดินตามที่ระบุไว้ในข้อ 1.
              ในราคารวมทั้งสิ้น
              <b>
                {` ${parseFloat(props?.data?.sellingPrice || 0).toLocaleString(
                  undefined,
                  { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                )} `}
              </b>
              บาท {"("}
              <b>
                {!props?.data?.sellingPrice ||
                  parseInt(props?.data?.sellingPrice) === 0
                  ? "ศูนย์บาทศูนย์สตางค์"
                  : ThaiBaht(props?.data?.sellingPrice || 0)}
              </b>
              {")"} ราคาจะซื้อจะขายนี้เป็นการซื้อขายตามเนื้อที่
              ที่ปรากฏในหน้าโฉนด โดยยึดถือเป็นการขายยกแปลง
              และบ้าที่ตั้งอยู่บนที่ดินแปลงนี้
            </Box>
            <Box className="text-box" component={"div"} fontWeight={"bold"}>
              ข้อ 2. เงื่อนไขการชำระเงิน
            </Box>
            <Box className="text-box-child">
              2.1 ผู้จะซื้อตกลงที่จะซื้อทำการชำระเงินให้แก้ผู้จะขาย ณ
              สถานที่ที่ทำการของผู้จะขายหรือสถานที่อื่นใดตามที่ผู้จะขายจะกำหนด
            </Box>
            <Box className="text-box-child">
              2.2
              ในวันที่ทำสัญญาฉบับนี้ผู้ซื้อได้ชำระค่าจองเป็นจำนวนเงิน
              <b>
                {` ${parseFloat(props?.data?.bookingFee || 0).toLocaleString(
                  undefined,
                  { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                )} `}
              </b>
              บาท {"("}
              <b>
                {!props?.data?.bookingFee ||
                  parseInt(props?.data?.bookingFee) === 0
                  ? "ศูนย์บาทศูนย์สตางค์"
                  : ThaiBaht(props?.data?.bookingFee || 0)}
              </b>
              {") "}
              และค่าทำสัญญาเป็นจำนวนเงิน
              <b>
                {` ${parseFloat(props?.data?.contractFee || 0).toLocaleString(
                  undefined,
                  { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                )} `}
              </b>
              บาท {"("}
              <b>
                {!props?.data?.contractFee ||
                  parseInt(props?.data?.contractFee) === 0
                  ? "ศูนย์บาทศูนย์สตางค์"
                  : ThaiBaht(props?.data?.contractFee || 0)}
              </b>
              {") "}
              รวมทั้งสิ้นเป็นจำนวนเงิน
              <b>
                {` ${parseFloat(props?.paidTotal || '0').toLocaleString(
                  undefined,
                  { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                )} `}
              </b>
              บาท {"("}
              <b>
                {!props?.paidTotal ||
                  parseInt(props?.paidTotal) === 0
                  ? "ศูนย์บาทศูนย์สตางค์"
                  : ThaiBaht(parseFloat(props?.paidTotal || '0') || 0)}
              </b>
              {") "}
              ซึ่งจำนวนเงินดังกล่าวทั้งหมดถือเป็นเงินประกันการปฏิบัติตามสัญญา
              ในกรณีที่ผู้ซื้อปฏิบัติตามสัญญาครบถ้วน
              เงินจำนวนนี้ถือเป็นส่วนหนึ่งของราคาบ้านที่ผู้ซื้อต้องชำระทั้งสิ้น
            </Box>
            <Box className="text-box-child">
              2.3 ผู้จะซื้อต้องชำระเงินให้แก้ผู้จะขายอีก ในวันโอนกรรมสิทธิ์
              เป็นจำนวนเงิน
              <b>
                {` ${parseFloat(props?.data?.arrearage || 0).toLocaleString(
                  undefined,
                  { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                )} `}
              </b>
              บาท {"("}
              <b>
                {!props?.data?.arrearage ||
                  parseInt(props?.data?.arrearage) === 0
                  ? "ศูนย์บาทศูนย์สตางค์"
                  : ThaiBaht(props?.data?.arrearage || 0)}
              </b>
              {")"}
            </Box>
            <Box className="text-box-child">
              2.4 ในวันที่ทำการโอนกรรมสิทธิ์ ณ สำนักงานที่ดิน
              หลังจากการก่อสร้างเสร็จเรียบร้อย
              ซึ่งผู้จะขายได้กำหนดนัดแจ้งล่วงหน้าให้ผู้จะซื้อทราบเป็นลายลักษณ์อักษรอีกครั้งหนึ่ง
              โดยจะทำการแจ้งล่วงหน้าไม่น้อยกว่า 7 วัน
              และผู้จะซื้อจะต้องชำระเงินส่วนที่เหลือ ในวันโอนกรรมสิทธิ์
              โดยชำระเป็นเงินสด/เช็คขีดคร่อม/แคชเชียร์เช็ค ในนามผู้ขาย
            </Box>
            <Box className="text-box-child">
              2.5 ผู้จะซื้อต้องชำระเงินให้แก่ผู้จะขายตามจำนวน และกำหนดเวลาในข้อ
              2.3 หากผู้ซื้อไม่ปฏิบัติตามสัญญาดังกล่าว หรือผิดสัญญาข้อหนึ่งข้อใด
              ผู้จะขายมีสิทธิบอกเลิกสัญญาฉบับนี้
              และริบเงินที่ผู้จะซื้อได้ชำระทั้งหมดทันที
              โดยผู้จะซื้อไม่มีสิทธิเรียกร้องเงินค่าตอบแทนใดๆ
              ทั้งยอมให้ผู้ขายนำบ้านที่จะซื้อจะขายตามสัญญานี้ไปขายให้แก่บุคคลอื่นได้
              โดยไม่ได้แย้งหรือเรียกร้องเอาประโยชน์อื่นใด
              รวมทั้งค่าเสียหายจากปู้จะขายทั้งสิ้น
            </Box>
            <Box className="text-box-child">
              2.6 ผู้จะซื้อสัญญาว่าจะไม่เรียกร้องค่าเสียหายอย่างใดๆ
              ในกรณีที่ผู้จะขายไม่สามารถดำเนินโครงการต่อไปเพราะเหตุสุดวิสัย
              ภาวะสงคราม หรือต้องห้ามตามกฎหมาย เป็นต้น
              ผู้จะขายจะทำการคืนเงินทั้งหมดที่ได้รับจากผู้จะซื้อให้แก่ผู้จะซื้อจนครบ
              โดยคู่สัญญาทั้งสองฝ่ายจะไม่เรียกร้องดอกเบี้ยหรือค่าเสียหายอื่นใดต่อกันอีก
            </Box>
            <Box className="text-box" component={"div"}>
              <b>ข้อ 3.</b> ค่าธรรมเนียม อากรแสตมป์ และค่าใช้จ่ายในการทำนิติกรรม
            </Box>
            <Box className="text-box-child">
              3.1 ผู้จะซื้อมีหน้าที่ออกค่าธรรมเนียม อากรแสตมป์
              และค่าใช้จ่ายในการจดทะเบียนโอนกรรมสิทธิ์ให้แก่ส่วนราชการที่เกี่ยวข้อง
              โดยชำระเป็นเงินสดในวันที่ทำการจดทะเบียนโอนกรรมสิทธิ์ต่อเจ้าพนักงานที่ดิน
              ณ สำนักงานที่ดิน
            </Box>
            <Box className="text-box-child">
              3.2
              ผู้จะขายเป็นผู้ชำระค่าภาษีโรงเรือนและที่ดินที่ถึงกำหนดก่อหรือในวันจดทะเบียนโอนกรรมสิทธิ์
              ส่วนภาษีโรงเรือนและที่ดินที่จะถึงกำหนดชำระหลังจากวันดังกล่าวผู้จะซื้อจะต้องเป็นผู้ชำระเองทั้งสิ้น
            </Box>
            <Box className="text-box" component={"div"}>
              <b>ข้อ 4.</b> การโอนกรรมสิทธิ์
              ผู้จะซื้อจะทำการโอนกรรมสิทธิ์และหน้าที่ตามสัญญาฉบับนี้ได้ก็ต่อเมื่อได้รับความยินยอมต่อลายลักษณ์อักษรจากผู้ขาย
              โดยแจ้งความประสงค์พร้อมทั้งรายละเอียดของผู้รับโอนเป็นลายลักษณ์อักษรไปยังผู้จะขายเพื่อพิจารณาและทั้งนี้หากผู้จะขายตกลงยินยอมกับการโอนสิทธิและหน้าที่ดังกล่าว
              ผู้จะซื้อให้สัญญาว่าตนตกลงยอมจ่ายค่าธรรมเนียมในการทำสัญญาฉบับใหม่เป็นจำนวนเงิน
              20,000 บาท (สองหมื่นบาทถ้วน) ให้แก่ผู้จะขายในวันทำสัญญาฉบับใหม่
            </Box>
            <Box className="text-box" component={"div"}>
              อนึ่ง ในกรณีที่มีการโอนสิทธิ์และหน้าที่ตามสัญญานี้
              ผู้รับโอนจะต้องปฏิบัติตามข้อตกลงและเงื่อนไขในสัญญานี้ทุกประการ
            </Box>
            <Box className="text-box" component={"div"}>
              <b>ข้อ 5.</b> นับแต่วันที่ทำสัญญานี้ และผู้จะซื้อได้ชำระราคาตาม
              ข้อ 2. เรียบร้อยแล้ว
              ผู้จะซื้อมีสิทธิ์เข้าครอบครองและทำประโยชน์หรือปรับปรุงทรัพย์ที่จะขายได้ทันทีและให้ถือว่าผู้จะซื้อได้รับมอบหมายครอบครองทรัพย์ที่จะขายไปจากผู้ขายสมบูรณ์ทันที
            </Box>
            <Box className="text-box" component={"div"}>
              <b>ข้อ 6.</b>
              ในกรณีที่มีความตกลงอื่นใดที่นอกหรือจากข้อตกลงในสัญญาฉบับนี้แล้ว
              และข้อตกลงใหม่ที่ดำเนินความตกลงกันนั้นไม่เป็นการขัดหรือแย้งกับข้อตกลงเดิม
              คู่สัญญาทั้งสองฝ่ายให้สัญญาว่าจะทำบันทึกข้อตกลงไว้เป็นลายลักษณ์อักษรลงลายมือชื่อทั้งสองฝ่ายแนบท้ายสัญญาฉบับนี้
              และบันทึกข้อตกลงดังกล่าวที่แนบไว้ท้ายสัญญาให้ถือเป็นส่วนหนึ่งของสัญญาฉบับนี้ด้วย
            </Box>
            <Box className="text-box-box" component={"div"}>
              สัญญานี้ถูกทำขึ้นเป็นสองฉบับมีข้อความถูกต้องตรงกัน
              คู่สัญญาทั้งสองฝ่ายได้อ่านข้อความและเข้าใจสัญญาดีแล้ว
              จึงลงลายมือชื่อไว้เป็นหลักฐานต่อหน้าพยานและเก็บสัญญาไว้ฝ่ายละฉบับ
            </Box>
          </Box>
          <Grid container marginTop={"20px"} spacing={3}>
            <Grid
              item
              xs={6}
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Grid item xs={12}>
                <Typography className="typography-booking-selling-contract">
                  ลงชื่อ..................................................ผู้จะขาย
                </Typography>
              </Grid>
              <Grid item xs={12} alignItems={"center"}>
                <Typography className="typography-booking-selling-contract">
                  (..................................................)
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Grid item xs={12}>
                <Typography className="typography-booking-selling-contract">
                  ลงชื่อ..................................................ผู้จะซื้อ
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="typography-booking-selling-contract">
                  (..................................................)
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Grid item xs={12}>
                <Typography className="typography-booking-selling-contract">
                  ลงชื่อ..................................................ผู้จะซื้อ
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="typography-booking-selling-contract">
                  (..................................................)
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Grid item xs={12}>
                <Typography className="typography-booking-selling-contract">
                  ลงชื่อ.....................................................พยาน
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="typography-booking-selling-contract">
                  (..................................................)
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Grid item xs={12}>
                <Typography className="typography-booking-selling-contract">
                  ลงชื่อ.....................................................พยาน
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="typography-booking-selling-contract">
                  (..................................................)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

export default PrintContract;
