import { Box, Breadcrumbs, Button, Card, CardContent, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, FormControl, RadioGroup, FormControlLabel, Radio, TablePagination, InputLabel, Select, MenuItem, TextField, Autocomplete } from "@mui/material";
import React, { useState, useEffect, ChangeEvent, useRef, } from 'react';
import SimpleBackdrop from "../../components/SimpleBackdrop";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/th';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SearchIcon from '@mui/icons-material/Search';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import wagesStore from "../../stores/WagesStore";
import projectsStore from "../../stores/ProjectsStore";
import housesStore from "../../stores/HousesStore";
import { useReactToPrint } from "react-to-print";
import PrintWages from "../../components/Report/Pdf/PrintWages";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ExportFileWages from "../../components/Report/Excel/ExportFileWages";
import authStore from "../../stores/AuthStore";
import ImageIcon from '@mui/icons-material/Image';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import SaveIcon from '@mui/icons-material/Save';
import imagesStore from "../../stores/ImagesStore";
import { enqueueSnackbar } from "notistack";
import DialogDisplayImage from "../../components/DialogDisplayImage";

const Wages: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [periodDateFrom, setPeriodDateFrom] = useState<Dayjs | null>(dayjs().startOf('month'));
  const [periodDateTo, setPeriodDateTo] = useState<Dayjs | null>(dayjs().startOf('month').add(14, 'day'));
  const [rows, setRows] = useState<any[]>([]);
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [housesList, setHousesList] = useState<any[]>([]);
  const [house, setHouse] = useState<any[]>([]);
  const [project, setProject] = useState<any[]>([]);
  const [projectValue, setProjectValue] = useState<string>('');
  const [houseValue, setHouseValue] = useState<string>('');
  const [dateType, setDateType] = useState('createDate');
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/wages');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [worker, setWorker] = useState<string>('');

  const [openDisplayImageDialog, setOpenDisplayImageDialog] = useState({
    open: false,
    images: [],
  });
  const [selectedFile, setSelectedFile] = useState<any>();
  const [rowSelectImage, setRowSelectImage] = useState<string>('');
  const [week, setWeek] = useState(1);
  const [pickMonth, setPickMonth] = useState<any>((dayjs().month()));
  const [pickYear, setPickYear] = useState<any>((dayjs().year()));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pickMonthYear, setPickMonthYear] = useState<Dayjs | null>(dayjs());
  const [sortType, setSortType] = useState({ name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" });
  const sortOption = [
    { name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" },
    { name: "วันที่บันทึก: เก่าสุด", variable: "createAtASC", sortBy: "createAt", sortType: "ASC" },
    { name: "วันที่เอกสาร: ล่าสุด", variable: "periodDateFromDESC", sortBy: "periodDateFrom", sortType: "DESC" },
    { name: "วันที่เอกสาร: เก่าสุด", variable: "periodDateFromASC", sortBy: "periodDateFrom", sortType: "ASC" },
    { name: "วันที่แก้ไข: ล่าสุด", variable: "updateAtDESC", sortBy: "updateAt", sortType: "DESC" },
    { name: "วันที่แก้ไข: เก่าสุด", variable: "updateAtASC", sortBy: "updateAt", sortType: "ASC" },
  ];
  const [sortValue, setSortValue] = useState("createAtDESC");
  const printRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const exportRef = useRef(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const files = event.target.files;
    if (files && files?.length > 0 && id) {
      setRowSelectImage(id);
      setSelectedFile(files[0]);
    }
  };

  const handleFileUpload = async (id: string) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("refType", "bill");
    formData.append("refId", id);
    formData.append('file', selectedFile);
    formData.append('updateBy', authStore.user?.id);
    formData.append('createBy', authStore.user?.id);
    formData.append("createAt", new Date().toString());
    formData.append("updateAt", new Date().toString());
    await imagesStore.upload(formData)
      .then((response) => {
        if (response.error) {
          enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
          setIsLoading(false);
          return;
        }
        setSelectedFile(null);
        setIsLoading(false);
        enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
      }).catch((error) => {
        enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
        console.error(error);
        setIsLoading(false);
      });
  };


  const handleSearch = (pageNumber?: string, limitNumber?: string, _dateType?: string, _worker?: any, _projectValue?: string, _houseValue?: string, dateF?: Dayjs, dateT?: Dayjs, _sortType?: string, isRefresh = false) => {
    setRows([]);
    setIsLoading(true);
    const _page = parseInt(pageNumber || '0');
    const _limit = parseInt(limitNumber || '0');
    // Set date to local storage
    localStorage.setItem('dateFrom', JSON.stringify(dateF ? dateF.format('YYYY-MM-DD') : periodDateFrom?.format('YYYY-MM-DD')));
    localStorage.setItem('dateTo', JSON.stringify(dateT ? dateT.format('YYYY-MM-DD') : periodDateTo?.format('YYYY-MM-DD')));
    wagesStore.getByCriteria({
      periodDateFrom: dateF ? dateF.format('YYYY-MM-DD') : periodDateFrom?.format('YYYY-MM-DD'),
      periodDateTo: dateT ? dateT.format('YYYY-MM-DD') : periodDateTo?.format('YYYY-MM-DD'),
      project: _projectValue ? _projectValue : (projectValue || null),
      house: _houseValue ? _houseValue : (houseValue || null),
      dateType: _dateType ? _dateType : dateType,
      worker: _worker ? _worker : (worker || null),
      page: _page,
      limit: _limit,
      sortType: _sortType ? _sortType : sortType,
    }).then((result: any) => {
      const wageDetails = result?.data;
      if ((result?.total && result?.total <= 10) || isRefresh === true) {
        setPage(0);
        setLimit(10);
      };
      setRows(wageDetails);
      setTotal(result?.total);
      const _sortType = localStorage.getItem('sortType');
      const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
      setSortType(_sortTypeConvert || sortType);
      setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
      if (!_sortType) {
        localStorage.setItem('sortType', JSON.stringify(sortType));
      }
      setIsLoading(false);
    }).catch((error: any) => {
      console.error(error);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      projectsStore.getAll(),
      housesStore.getAll()]).then((res) => {
        setProjectsList(res[0]);
        setHousesList(res[1]);
        setIsLoading(false);
      }).catch((error) => {
        console.error(error);
        setIsLoading(false);
        enqueueSnackbar('เกิดข้อผิดพลาด', { variant: 'error' });
      });
    const _dateFrom = localStorage.getItem('dateFrom');
    const _dateTo = localStorage.getItem('dateTo');
    const _project: any = localStorage.getItem('project');
    const _projectConvert: any = JSON.parse(_project);
    const _house: any = localStorage.getItem('house');
    const _houseConvert: any = JSON.parse(_house);
    const _dateType: any = localStorage.getItem('dateType');
    const _worker: any = localStorage.getItem('worker');
    const _week: any = localStorage.getItem('week');
    const dateF = dayjs(_dateFrom ? _dateFrom : periodDateFrom);
    const dateT = dayjs(_dateTo ? _dateTo : periodDateTo);
    const _limit = localStorage.getItem('limit');
    const _page = localStorage.getItem('page');
    setPeriodDateFrom(dateF);
    setPeriodDateTo(dateT);
    setProjectValue(_projectConvert?.id);
    setHouseValue(_houseConvert?.id);
    setProject(_projectConvert);
    setHouse(_houseConvert);
    setDateType(_dateType ? _dateType : 'createDate');
    setWorker(_worker ? _worker : undefined);
    setWeek(_week ? _week : 1);
    setLimit(_limit ? parseInt(_limit) : limit);
    setPage(_page ? parseInt(_page) : page);
    const _sortType: any = localStorage.getItem('sortType');
    const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
    setSortType(_sortTypeConvert || sortType);
    setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
    if (!_sortType) {
      localStorage.setItem('sortType', JSON.stringify(sortType));
    }
    if (!_limit) {
      localStorage.setItem('limit', '10');
    }
    if (!_page) {
      localStorage.setItem('page', '0');
    }
    handleSearch(_page || undefined, _limit || undefined, _dateType || undefined, _worker || undefined, _projectConvert?.id as string || undefined, _houseConvert?.id as string || undefined, dateF || undefined, dateT || undefined, _sortTypeConvert);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangeDateType = (event: ChangeEvent<HTMLInputElement>) => {
    setDateType((event.target as HTMLInputElement).value);
    localStorage.setItem('dateType', (event.target as HTMLInputElement).value);
  };

  const handleProjectChange = (value: any) => {
    const selectedProject = value;
    setProject(selectedProject);
    setProjectValue(selectedProject?.id || '');
    // Set project to local storage
    localStorage.setItem('project', JSON.stringify(selectedProject));
    if (selectedProject?.id) {
      housesStore.getByProjectId(selectedProject.id)
        .then((res) => {
          setHousesList(res);
        });
    } else {
      housesStore.getAll()
        .then((res) => {
          setHousesList(res);
        });
    }
  };

  const handleHouseChange = (value: any) => {
    const selectedHouse = value;
    setHouse(selectedHouse);
    setHouseValue(selectedHouse?.id || '');
    // Set house to local storage
    localStorage.setItem('house', JSON.stringify(selectedHouse));
  };

  const handleChangeWeek = (e: ChangeEvent<HTMLInputElement>) => {
    setWeek(e?.target?.value ? Number(e.target.value) : Number(e));
    // Set week to local storage
    localStorage.setItem('week', JSON.stringify(e?.target?.value ? Number(e.target.value) : Number(e)));
    if (Number(e?.target?.value) === 1 || Number(e) === 1) {
      const _dateFrom = dayjs().startOf('month').set('month', pickMonth).set('year', pickYear);
      setPeriodDateFrom(_dateFrom);
      const _dateTo = dayjs().startOf('month').add(14, 'day').set('month', pickMonth).set('year', pickYear);
      setPeriodDateTo(_dateTo);
      // Set date to local storage
      localStorage.setItem('dateFrom', JSON.stringify(_dateFrom?.format('YYYY-MM-DD') || ''));
      localStorage.setItem('dateTo', JSON.stringify(_dateTo.format('YYYY-MM-DD') || ''));
    }
    else {
      const _dateFrom = dayjs().startOf('month').add(15, 'day').set('month', pickMonth).set('year', pickYear);
      const _dateTo = dayjs().endOf('month').set('month', pickMonth).set('year', pickYear).set('hour', 0).set('minute', 0).set('second', 0);
      setPeriodDateFrom(_dateFrom);
      setPeriodDateTo(_dateTo);
      // Set date to local storage
      localStorage.setItem('dateFrom', JSON.stringify(_dateFrom.format('YYYY-MM-DD') || ''));
      localStorage.setItem('dateTo', JSON.stringify(_dateTo.format('YYYY-MM-DD') || ''));
    }
  };

  const handleChangeMonthYear = (value?: any) => {
    const _month = value.$M;
    const _year = value.$y;
    // Set pickMonth and pickYear states
    setPickMonth(_month);
    setPickYear(_year);
    if (week === 1) {
      setPeriodDateFrom(dayjs().startOf('month').set('month', _month).set('year', _year));
      setPeriodDateTo(dayjs().startOf('month').add(14, 'day').set('month', _month).set('year', _year));
    } else {
      setPeriodDateFrom(dayjs().startOf('month').add(15, 'day').set('month', _month).set('year', _year));
      setPeriodDateTo(dayjs().endOf('month').set('month', _month).set('year', _year).set('hour', 0).set('minute', 0).set('second', 0));
    }
  };

  const handleWorkerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWorker(e.target.value);
    if (e.target.value) {
      localStorage.setItem('worker', e.target.value);
    }
  };

  const handleChangeSort = (e: any) => {
    const findSortType: any = sortOption.find((item: any) => item.variable === e.target.value);
    localStorage.setItem('sortType', JSON.stringify(findSortType));
    setSortType(findSortType);
    setSortValue(e.target.value);
  };

  // Remove local storage
  window.addEventListener("beforeunload", function (event) {
    localStorage.removeItem('project');
    localStorage.removeItem('house');
    localStorage.removeItem('dateFrom');
    localStorage.removeItem('dateTo');
    localStorage.removeItem('dateType');
    localStorage.removeItem('worker');
    localStorage.removeItem('week');
    localStorage.removeItem('sortType');
    localStorage.removeItem('page');
    localStorage.removeItem('limit');
  });

  return (
    <Box component={'div'}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>บันทึกค่าใช้จ่าย</Typography>
        <Typography variant="h6" color="text.primary">ค่าแรง</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems={'end'}>
                <Grid item xs={12} >
                  <Grid item xs={12}>
                    <Typography>ประจำงวดวันที่</Typography>
                  </Grid>
                  <FormControl>
                    <RadioGroup row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={dateType}
                      onChange={handleChangeDateType}>
                      <FormControlLabel value="createDate" control={<Radio />} label="วันที่บันทึก" />
                      <FormControlLabel value="periodDate" control={<Radio />} label="ประจำงวดวันที่" />
                      <FormControlLabel value="periodDateAdjust" control={<Radio />} label="ประจำงวดวันที่ปรับแต่ง" />
                    </RadioGroup>
                  </FormControl>
                  <Grid item xs={12} display={'flex'}>
                    {(dateType === 'periodDateAdjust' || dateType === 'createDate') &&
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                          <DatePicker
                            label="ตั้งแต่วันที่"
                            value={periodDateFrom}
                            onChange={(newValue) => {
                              setPeriodDateFrom(newValue);
                              // Set date to local storage
                              localStorage.setItem('dateFrom', JSON.stringify(newValue?.format('YYYY-MM-DD') || ''));
                            }} />
                          <DatePicker
                            label="ถึงวันที่"
                            value={periodDateTo}
                            onChange={(newValue) => {
                              setPeriodDateTo(newValue);
                              // Set date to local storage
                              localStorage.setItem('dateTo', JSON.stringify(newValue?.format('YYYY-MM-DD') || ''));
                            }} />
                        </DemoContainer>  </LocalizationProvider>
                    }
                    {dateType === 'periodDate' &&
                      <Grid display={'flex'} item xs={5} >
                        <Grid item xs={6} display={'flex'} alignItems={'end'}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">งวดที่</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={week}
                              label="งวด"
                              onChange={(e: any) => handleChangeWeek(e)}
                            >
                              <MenuItem value={1}>Week 1</MenuItem>
                              <MenuItem value={2}>Week 2</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6.9} ml={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'} >
                            <DemoContainer components={['DatePicker', 'DatePicker']} >
                              <DatePicker sx={{ width: '100%' }}
                                views={['month', 'year']}
                                label="เดือนและปี"
                                value={pickMonthYear}
                                onChange={(newValue: any) =>
                                  handleChangeMonthYear(newValue)
                                }
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    }
                    <Grid item xs={4.8} ml={2} mt={1}>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5.1}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    value={project}
                    options={projectsList}
                    sx={{ width: '100%' }}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option || '';
                      }
                      if (option.inputValue) {
                        return option.inputValue || '';
                      }
                      return option.name || '';
                    }}
                    renderInput={(params) => <TextField {...params} label="โครงการ" />}
                    onChange={(e, value) => { handleProjectChange(value); }}
                  />
                </Grid>
                <Grid item xs={4.9}>
                  <Autocomplete
                    fullWidth
                    value={house}
                    disablePortal
                    options={housesList}
                    sx={{ width: '100%' }}
                    renderOption={(props, option) =>

                      <li {...props}>{option.name}</li>
                    }
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option || '';
                      }
                      if (option.inputValue) {
                        return option.inputValue || '';
                      }
                      return option.name || '';
                    }}
                    renderInput={(params) => <TextField {...params} label="บ้าน" />}
                    onChange={(e, value) => { handleHouseChange(value); }}
                  />
                </Grid>
                <Grid item xs={5.1}>
                  <TextField id="outlined-basic" label="ชื่อคนงาน" variant="outlined" fullWidth value={worker} onChange={(e: any) => handleWorkerChange(e)} />
                </Grid>
                <Grid item xs={4.9}>
                  <FormControl fullWidth >
                    <InputLabel id="demo-simple-select-label">เรียงตาม
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sortValue}
                      label="sortType"
                      onChange={handleChangeSort}
                    >
                      {sortOption.map((option: any, index: number) => (
                        <MenuItem key={`sort-option-${index}`} value={option.variable}>{option.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} textAlign={'end'} pb={0.5}>
                  <Button variant="contained" onClick={() => {
                    handleSearch(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true);
                  }} startIcon={<SearchIcon />}>ค้นหา</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container >
                    <Grid item xs={9}>
                      <Typography>ทั้งหมด {total.toLocaleString()} รายการ</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <DownloadTableExcel
                        filename={`wages-${dayjs().format('DD-MM-YYYY')}`}
                        sheet={`wages-${dayjs().format('DD-MM-YYYY')}`}
                        currentTableRef={exportRef.current}
                      >
                        <IconButton aria-label="download"  >
                          <DownloadIcon />
                        </IconButton>
                      </DownloadTableExcel>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="print" onClick={() => reactToPrintFn()}>
                        <PrintIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      {((permission && permission.create) || authStore.user?.isSuperAdmin) && <Button variant="contained" startIcon={<NoteAddIcon />} onClick={() => navigate('/wage-create')}>สร้าง</Button>}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell width={100} align="center">ช่วงวันที่</TableCell>
                          <TableCell width={400} align="center">คนงาน</TableCell>
                          <TableCell width={100} align="center">ยอดรวม</TableCell>
                          <TableCell width={100} align="center">ยอดเบิก</TableCell>
                          <TableCell width={100} align="center">ยอดคงเหลือ</TableCell>
                          <TableCell width={100} align="center">บันทึก</TableCell>
                          <TableCell width={100} align="center">แก้ไข</TableCell>
                          <TableCell width={80}></TableCell>
                          <TableCell width={80}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows && rows?.length > 0 && rows?.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell width={100} align="center" component="th" scope="row">
                              {`${dayjs(row.periodDateFrom).format('DD/MM/YYYY')} - ${dayjs(row.periodDateTo).format('DD/MM/YYYY')}`}
                            </TableCell>
                            <TableCell width={400} align="left">
                              {row?.wageDetails?.sort((a: any, b: any) => dayjs(a.createAt).diff(b.createAt)).map((wageDetail: any, index: number) => {
                                if (index < 4) {
                                  return <span key={wageDetail.id}>{`${wageDetail.worker?.firstName || ''}${index < 4 ? ', ' : ''} `}</span>;
                                } else if (index === 4) {
                                  return <span key={wageDetail.id}>{`${wageDetail.worker?.firstName || ''} ${wageDetail.worker?.lastName || ''}, ... `}</span>;
                                } else {
                                  return '';
                                }
                              })}
                            </TableCell>
                            <TableCell width={100} align="center">{parseFloat(row.total).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                            <TableCell width={100} align="center">{parseFloat(row.withdraw).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                            <TableCell width={100} align="center">{parseFloat(row.netTotal).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                            <TableCell width={100} align="center">{<b>{row.createBy?.name || row.createBy?.firstName || ''}</b>} {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                            <TableCell width={100} align="center">{<b>{row.updateBy?.name || row.updateBy?.firstName || ''}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''}</TableCell>
                            <TableCell>
                              {
                                row?.images && row?.images?.length > 0 &&
                                <IconButton color="warning" onClick={() => {
                                  setOpenDisplayImageDialog({ open: true, images: row.images });
                                }}>
                                  <ImageIcon />
                                </IconButton>
                              }
                              {
                                row?.images?.length < 1 && rowSelectImage !== row.id &&
                                <IconButton component="label" > <UploadFileIcon />
                                  <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png" onChange={(e) => {
                                    handleFileChange(e, row.id);
                                  }} />
                                </IconButton>
                              }
                              {
                                (selectedFile && row?.images?.length < 1 && rowSelectImage === row.id) &&
                                <IconButton component="label" color="primary" onClick={() => handleFileUpload(row.id)
                                }>
                                  <SaveIcon />
                                </IconButton>
                              }
                            </TableCell>
                            <TableCell width={100} align="center">
                              <IconButton aria-label="edit" onClick={() => navigate(`/wage-edit/${row.id}`)}>
                                <KeyboardArrowRightIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                        {rows && rows?.length === 0 && (
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell colSpan={10} align="center">ไม่พบข้อมูล</TableCell>
                          </TableRow>)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                      count={total}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        handleSearch(newPage.toString());
                        localStorage.setItem('page', newPage.toString());
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setRows([]);
                        setLimit(e.target.value);
                        setPage(0);
                        handleSearch('0', e.target.value.toString());
                        localStorage.setItem('limit', e.target.value.toString());
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogDisplayImage
        open={openDisplayImageDialog.open}
        onClose={() => setOpenDisplayImageDialog({ open: false, images: [] })}
        images={openDisplayImageDialog.images}

      />
      <Box sx={{ display: 'none' }}>
        <PrintWages ref={printRef} title={'รายงานค่าแรง'} data={rows} />
      </Box>
      <Box sx={{ display: 'none' }}>
        <ExportFileWages title={'รายงานค่าแรง'} data={rows} ref={exportRef} />
      </Box>
    </Box >
  );
};

export default Wages;
