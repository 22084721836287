import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  MenuProps,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  ChangeEvent,
  RefObject,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/th";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import DialogDisplayImage from "../../components/DialogDisplayImage";
import { enqueueSnackbar } from "notistack";
import authStore from "../../stores/AuthStore";
import DialogForCheckQc from "../../components/DialogForCheckQc";
import { useReactToPrint } from "react-to-print";
import { DownloadTableExcel } from "react-export-table-to-excel";
import purchasesStore from "../../stores/PurchasesStore";
import { styled, alpha } from "@mui/material/styles";
import inventoryStore from "../../stores/InventoryStore";
import PrintInventory from "../../components/Report/Pdf/PrintInventory";
import PrintInventories from "../../components/Report/Pdf/PrintInventories";
import ExportFileInventories from "../../components/Report/Excel/ExportFileInventories";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const Inventories: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(
    dayjs().startOf("month")
  );
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs());
  const [dateTypes, setDateType] = useState("createDate");
  const [docNo, setDocNo] = useState("");
  const [workerName, setWorkerName] = useState("");
  const [openDisplayImageDialog, setOpenDisplayImageDialog] = useState({
    open: false,
    images: [],
    docNo: "",
  });
  const [openDialogForCheckQc, setOpenDialogForCheckQc] = useState({
    open: false,
    id: "",
  });
  const [rows, setRows] = useState<any[]>([]);
  const permission: any = authStore.user?.roles.find(
    (role: any) => role.menu.path === "/warehouses"
  );
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const printRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const printOneRef = useRef<RefObject<HTMLTableRowElement>[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isClick, setIsClick] = useState(false);
  const reactToPrintOneFn = useReactToPrint({
    contentRef: selectedIndex !== null ? printOneRef?.current[selectedIndex] : undefined
  });

  const exportRef = useRef(null);
  const [sortType, setSortType] = useState({
    name: "วันที่บันทึก: ล่าสุด",
    variable: "createAtDESC",
    sortBy: "createAt",
    sortType: "DESC",
  });
  const sortOption = [
    {
      name: "วันที่บันทึก: ล่าสุด",
      variable: "createAtDESC",
      sortBy: "createAt",
      sortType: "DESC",
    },
    {
      name: "วันที่บันทึก: เก่าสุด",
      variable: "createAtASC",
      sortBy: "createAt",
      sortType: "ASC",
    },
    {
      name: "วันที่เอกสาร: ล่าสุด",
      variable: "docDateDESC",
      sortBy: "docDate",
      sortType: "DESC",
    },
    {
      name: "วันที่เอกสาร: เก่าสุด",
      variable: "docDateASC",
      sortBy: "docDate",
      sortType: "ASC",
    },
    {
      name: "วันที่แก้ไข: ล่าสุด",
      variable: "updateAtDESC",
      sortBy: "updateAt",
      sortType: "DESC",
    },
    {
      name: "วันที่แก้ไข: เก่าสุด",
      variable: "updateAtASC",
      sortBy: "updateAt",
      sortType: "ASC",
    },
  ];
  const [sortValue, setSortValue] = useState("createAtDESC");
  const [inventoryValue, setInventoryValue] = useState("all");
  const [statusValue, setStatusValue] = useState("all");
  const [inventoryType, setInventoryType] = useState({
    name: "ทั้งหมด",
    value: "all",
  });
  const inventoryOptions = [
    { name: "ทั้งหมด", value: "all" },
    { name: "เพิ่มวัสดุ", value: "add" },
    { name: "เบิกวัสดุ", value: "requisition" },
    { name: "คืนวัสดุ", value: "return" },
  ];

  const statusOptions = [
    { name: "ทั้งหมด", value: "all" },
    { name: "ใช้งาน", value: "active" },
    { name: "ยกเลิก", value: "cancel" },
  ];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleChangeDateType = (event: ChangeEvent<HTMLInputElement>) => {
    setDateType((event.target as HTMLInputElement).value);
  };

  const addRef = (el: any) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };

  const handleKeyDown = (event: any, index: number) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (index + 1 < inputRefs.current.length) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  window.addEventListener("beforeunload", function (event) {
    localStorage.removeItem("dateFrom");
    localStorage.removeItem("dateTo");
    localStorage.removeItem("sortType");
    localStorage.removeItem("limit");
    localStorage.removeItem("page");
    localStorage.removeItem("workerName");
    localStorage.removeItem("inventoryType");
    localStorage.removeItem("status");
  });

  const handleNavigate = (value: string) => {
    switch (value) {
      case "add":
        navigate("/inventory-add-create");
        break;
      case "return":
        navigate("/inventory-return-create");
        break;
      case "requisition":
        navigate("/inventory-requisition-create");
        break;
    }
  };
  useEffect(() => {
    const _dateFrom = localStorage.getItem("dateFrom");
    const _dateTo = localStorage.getItem("dateTo");
    const dateF = dayjs(_dateFrom ? _dateFrom : dateFrom);
    const dateT = dayjs(_dateTo ? _dateTo : dateTo);
    const _limit = localStorage.getItem("limit");
    const _page = localStorage.getItem("page");
    const _workerName = localStorage.getItem("workerName");
    const _docNo = localStorage.getItem("docNo");
    const _sortType: any = localStorage.getItem("sortType");
    const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
    const _inventoryType = localStorage.getItem("inventoryType");
    const _inventoryTypeConvert: any = _inventoryType
      ? JSON.parse(_inventoryType)
      : inventoryType;
    const _status = localStorage.getItem("status");
    const _statusConvert: any = _status ? JSON.parse(_status) : statusValue;
    setDocNo(_docNo ? _docNo : docNo);
    setWorkerName(_workerName ? _workerName : workerName);
    setDateFrom(dateF);
    setDateTo(dateT);
    setLimit(_limit ? parseInt(_limit) : limit);
    setPage(_page ? parseInt(_page) : page);
    setSortType(_sortTypeConvert || sortType);
    setSortValue(_sortTypeConvert?.variable || "createAtDESC");
    setInventoryType(_inventoryTypeConvert || inventoryType);
    setInventoryValue(_inventoryTypeConvert?.value || "all");
    setStatusValue(_statusConvert);
    if (!_sortType) {
      localStorage.setItem("sortType", JSON.stringify(sortType));
    }
    if (!_limit) {
      localStorage.setItem("limit", "10");
    }
    if (!_page) {
      localStorage.setItem("page", "0");
    }
    if (!_inventoryType) {
      localStorage.setItem("inventoryType", JSON.stringify(inventoryType));
    }
    if (!_status) {
      localStorage.setItem("status", JSON.stringify(statusValue).toString());
    }
    handleSearch(
      _page || undefined,
      _limit || undefined,
      dateF,
      dateT,
      _sortTypeConvert,
      false,
      _docNo || undefined,
      _workerName || undefined,
      _inventoryTypeConvert,
      _statusConvert
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSort = (e: any) => {
    const findSortType: any = sortOption.find(
      (item: any) => item.variable === e.target.value
    );
    localStorage.setItem("sortType", JSON.stringify(findSortType));
    setSortType(findSortType);
    setSortValue(e.target.value);
  };

  const handleChangeInventoryType = (e: any) => {
    const findInventoryType: any = inventoryOptions.find(
      (item: any) => item.value === e.target.value
    );
    localStorage.setItem("inventoryType", JSON.stringify(findInventoryType));
    setInventoryValue(e.target.value);
    setInventoryType(findInventoryType);
  };
  const handleSearch = (
    pageNumber?: string,
    limitNumber?: string,
    dateF?: Dayjs,
    dateT?: Dayjs,
    _sortType?: string,
    isRefresh = false,
    _docNo?: string,
    _workerName?: string,
    _inventoryType?: string,
    _status?: string
  ) => {
    setIsLoading(true);
    setRows([]);
    const _page = parseInt(pageNumber || "0");
    const _limit = parseInt(limitNumber || "0");
    inventoryStore
      .getByCriteria({
        dateFrom: dateF
          ? dateF.format("YYYY-MM-DD")
          : dateFrom?.format("YYYY-MM-DD"),
        dateTo: dateT
          ? dateT.format("YYYY-MM-DD")
          : dateTo?.format("YYYY-MM-DD"),
        dateType: dateTypes,
        docNo: _docNo ? _docNo : docNo,
        workerName: _workerName ? _workerName : workerName,
        page: _page,
        limit: _limit,
        sortType: _sortType ? _sortType : sortType,
        inventoryType: _inventoryType ? _inventoryType : inventoryType,
        status: _status ? _status : statusValue,
      })
      .then((response) => {
        const data = response.data;
        setTotal(response.total);
        if ((response?.total && response.total <= 10) || isRefresh === true) {
          setPage(0);
          setLimit(10);
        }
        setRows(data);
        const _sortType = localStorage.getItem("sortType");
        const _sortTypeConvert: any = _sortType
          ? JSON.parse(_sortType)
          : sortType;
        const _inventoryType = localStorage.getItem("inventoryType");
        const _inventoryTypeConvert: any = _inventoryType
          ? JSON.parse(_inventoryType)
          : inventoryType;

        setSortType(_sortTypeConvert || sortType);
        setSortValue(_sortTypeConvert?.variable || "createAtDESC");
        setInventoryType(_inventoryTypeConvert || inventoryType);
        setInventoryValue(_inventoryTypeConvert?.value || "all");
        setStatusValue(_status || statusValue);
        if (!_sortType) {
          localStorage.setItem("sortType", JSON.stringify(sortType));
        }
        if (!_inventoryType) {
          localStorage.setItem("inventoryType", JSON.stringify(inventoryType));
        }
        if (!_status) {
          localStorage.setItem(
            "status",
            JSON.stringify(statusValue).toString()
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleQC = (item: any) => {
    purchasesStore
      .update(item.expenseId, {
        isChecked: item.checked,
        checkedBy: authStore?.user?.id,
        checkedAt: new Date(),
      })
      .then((res) => {
        if (res.error) {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        setOpenDialogForCheckQc({ open: false, id: "" });
        rows.map((row: any, index: number) => {
          const _row = [...rows];
          if (row.id === item.expenseId) {
            _row[index].isChecked = item.checked;
            _row[index].checkedAt = new Date();
            _row[index].checkedBy = authStore?.user;
            setRows(_row);
          }
          return row;
        });
      })
      .catch((error) => {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        console.error(error);
      });
  };

  const handleChangeStatus = (e: any) => {
    setStatusValue(e.target.value);
    localStorage.setItem("status", JSON.stringify(e.target.value).toString());
  };

  useEffect(() => {
    if (selectedIndex !== null && isClick) {
      reactToPrintOneFn();
      setIsClick(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, reactToPrintOneFn]);

  return (
    <Box component={"div"}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>คลังวัสดุ</Typography>
        <Typography variant="h6" color="text.primary">
          เบิก/คืนวัสดุ
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems={"end"}>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={dateTypes}
                      onChange={handleChangeDateType}
                    >
                      <FormControlLabel
                        value="createDate"
                        control={<Radio />}
                        label="วันที่บันทึก"
                      />
                      <FormControlLabel
                        value="updateDate"
                        control={<Radio />}
                        label="วันที่แก้ไข"
                      />
                      <FormControlLabel
                        value="docDate"
                        control={<Radio />}
                        label="วันที่เอกสาร"
                      />
                    </RadioGroup>
                  </FormControl>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"th"}
                  >
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        label="ตั้งแต่วันที่"
                        value={dateFrom}
                        onChange={(newValue) => {
                          setDateFrom(newValue);
                          localStorage.setItem(
                            "dateFrom",
                            newValue?.format("YYYY-MM-DD") || ""
                          );
                        }}
                      />
                      <DatePicker
                        label="ถึงวันที่"
                        value={dateTo}
                        onChange={(newValue) => {
                          setDateTo(newValue);
                          localStorage.setItem(
                            "dateTo",
                            newValue?.format("YYYY-MM-DD") || ""
                          );
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4.5}>
                  <TextField
                    id="outlined-basic"
                    label="เลขเอกสาร"
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                      setDocNo(event.target.value);
                      localStorage.setItem("docNo", event.target.value);
                    }}
                    inputRef={addRef}
                    onKeyDown={(e) => handleKeyDown(e, 0)}
                  />
                </Grid>
                <Grid item xs={5.5}>
                  <TextField
                    id="outlined-basic"
                    label="ชื่อผู้ทำรายการ"
                    variant="outlined"
                    fullWidth
                    onChange={(event) => {
                      setWorkerName(event.target.value);
                      localStorage.setItem("workerName", event.target.value);
                    }}
                    inputRef={addRef}
                    onKeyDown={(e) => handleKeyDown(e, 1)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      ประเภท
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={inventoryValue}
                      label="sortType"
                      onChange={handleChangeInventoryType}
                    >
                      {inventoryOptions.map((option: any, index: number) => (
                        <MenuItem
                          key={`sort-option-${index}`}
                          value={option.value}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">สถานะ</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={statusValue}
                      label="sortType"
                      onChange={handleChangeStatus}
                    >
                      {statusOptions.map((option: any, index: number) => (
                        <MenuItem
                          key={`sort-option-${index}`}
                          value={option.value}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      เรียงตาม
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sortValue}
                      label="sortType"
                      onChange={handleChangeSort}
                    >
                      {sortOption.map((option: any, index: number) => (
                        <MenuItem
                          key={`sort-option-${index}`}
                          value={option.variable}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} textAlign={"end"} pb={0.5}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSearch(
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        true
                      );
                    }}
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={9}>
                      <Typography>
                        ทั้งหมด {total?.toLocaleString()} รายการ
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <DownloadTableExcel
                        filename={`inventories-${dayjs(dateFrom).format(
                          "DD-MM-YYYY"
                        )} - ${dayjs(dateTo).format("DD-MM-YYYY")}`}
                        sheet={`inventories-${dayjs(dateFrom).format(
                          "DD-MM-YYYY"
                        )} - ${dayjs(dateTo).format("DD-MM-YYYY")}`}
                        currentTableRef={exportRef.current}
                      >
                        <IconButton aria-label="download">
                          <DownloadIcon />
                        </IconButton>
                      </DownloadTableExcel>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="print" onClick={() => reactToPrintFn()}>
                        <PrintIcon />
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      display={"flex"}
                      flexDirection={"column"}
                      mb={0.5}
                    >
                      {((permission && permission.create) ||
                        authStore.user?.isSuperAdmin) && (
                          <>
                            <Button
                              id="demo-customized-button"
                              aria-controls={
                                open ? "demo-customized-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              sx={{ textDecoration: "none", color: "gray" }}
                              variant="contained"
                              color="primary"
                              disableElevation
                              onClick={handleClick}
                              style={{ color: "white" }}
                              startIcon={
                                <NoteAddIcon style={{ color: "white" }} />
                              }
                            >
                              สร้าง
                            </Button>
                            <StyledMenu
                              id="demo-customized-menu"
                              MenuListProps={{
                                "aria-labelledby": "demo-customized-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleClose();
                                  handleNavigate("add");
                                }}
                                disableRipple
                              >
                                ใบเพิ่มวัสดุ
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleClose();
                                  handleNavigate("requisition");
                                }}
                                disableRipple
                              >
                                ใบเบิกวัสดุ
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleClose();
                                  handleNavigate("return");
                                }}
                                disableRipple
                              >
                                ใบคืนวัสดุ
                              </MenuItem>
                            </StyledMenu>
                          </>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>วันที่เอกสาร</TableCell>
                          <TableCell>เลขเอกสาร</TableCell>
                          <TableCell>ชื่อผู้ทำรายการ</TableCell>
                          <TableCell align="center">ประเภท</TableCell>
                          <TableCell>เอกสารอ้างอิง</TableCell>
                          <TableCell align="center">สถานะ</TableCell>
                          <TableCell width={100} align="center">
                            บันทึก
                          </TableCell>
                          <TableCell width={100} align="center">
                            แก้ไข
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows &&
                          rows?.length > 0 &&
                          rows.map((row: any, index: number) => {
                            if (!printOneRef.current[index]) {
                              printOneRef.current[index] = createRef();
                            }
                            return (
                              <TableRow
                                key={`bill-row-${index}`}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {dayjs(row.docDate).format("DD/MM/YYYY")}
                                </TableCell>
                                <TableCell>{row.docNo}</TableCell>
                                <TableCell>
                                  {row.worker?.firstName} {row.worker?.lastName}
                                </TableCell>
                                <TableCell align="center">
                                  {(() => {
                                    switch (row.type) {
                                      case "return":
                                        return (
                                          <Chip
                                            label="คืนวัสดุ"
                                            color="primary"
                                          />
                                        );
                                      case "requisition":
                                        return (
                                          <Chip
                                            label="เบิกวัสดุ"
                                            color="error"
                                          />
                                        );
                                      case "add":
                                        return (
                                          <Chip
                                            label="เพิ่มวัสดุ"
                                            color="success"
                                          />
                                        );
                                      default:
                                        return null;
                                    }
                                  })()}
                                </TableCell>
                                <TableCell>{row.docRef}</TableCell>
                                <TableCell>
                                  {row.isCancelled ? (
                                    <Chip label="ยกเลิก" color="error" />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {<b>{row.createBy?.name}</b>}{" "}
                                  {dayjs(row.createAt).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {<b>{row.updateBy?.name}</b>}{" "}
                                  {row.updateAt
                                    ? dayjs(row.updateAt).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                    : ""}{" "}
                                </TableCell>
                                <TableCell>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                  >
                                    <Box component={"span"}>
                                      <IconButton aria-label="print" onClick={() => {
                                        setIsClick(true);
                                        setSelectedIndex(index);
                                      }}>
                                        <PrintIcon />
                                      </IconButton>
                                      <Box style={{ display: "none" }}>
                                        <PrintInventory
                                          ref={printOneRef.current[index]}
                                          title={
                                            row.type === "add"
                                              ? "ใบเพิ่มวัสดุ"
                                              : row.type === "return"
                                                ? "ใบคืนวัสดุ"
                                                : "ใบเบิกวัสดุ"
                                          }
                                          data={{
                                            items: row.inventoryDetail,
                                            docNo: row.docNo,
                                            docDate: row.docDate,
                                            refNo: row.refNo,
                                            workerValue:
                                              row.worker?.firstName +
                                              " " +
                                              row.worker?.lastName,
                                            remark: row.remark,
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() =>
                                      navigate(
                                        `/inventory-${row?.type}-edit/${row?.id}`
                                      )
                                    }
                                  >
                                    <KeyboardArrowRightIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {rows && rows?.length === 0 && (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell colSpan={10} align="center">
                              ไม่พบข้อมูล
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={total}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        handleSearch(newPage.toString());
                        localStorage.setItem("page", newPage.toString());
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setRows([]);
                        setLimit(e.target.value);
                        setPage(0);
                        handleSearch("0", e.target.value.toString());
                        localStorage.setItem(
                          "limit",
                          e.target.value.toString()
                        );
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogDisplayImage
        open={openDisplayImageDialog.open}
        onClose={() =>
          setOpenDisplayImageDialog({ open: false, images: [], docNo: "" })
        }
        images={openDisplayImageDialog.images}
        docNo={openDisplayImageDialog.docNo}
      />
      <DialogForCheckQc
        open={openDialogForCheckQc.open}
        id={openDialogForCheckQc.id}
        onSubmit={(item: any) => {
          handleQC(item);
        }}
        onClose={() => setOpenDialogForCheckQc({ open: false, id: "" })}
      />
      <Box style={{ display: "none" }}>
        {
          <PrintInventories
            ref={printRef}
            title="ใบเบิก/คืนวัสดุ"
            date={`${dayjs(dateFrom).format("DD/MM/YYYY")} - ${dayjs(
              dateTo
            ).format("DD/MM/YYYY")}`}
            data={rows ?? []}
          />
        }
      </Box>
      <Box style={{ display: "none" }}>
        {
          <ExportFileInventories
            ref={exportRef}
            title="ใบเบิก/คืนวัสดุ"
            date={`${dayjs(dateFrom).format("DD/MM/YYYY")} - ${dayjs(
              dateTo
            ).format("DD/MM/YYYY")}`}
            data={rows ?? []}
          />
        }
      </Box>
    </Box>
  );
};

export default Inventories;
