import { Autocomplete, Box, Breadcrumbs, Button, Card, CardContent, FormControl, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import { useReactToPrint } from "react-to-print";
import { DownloadTableExcel } from "react-export-table-to-excel";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import landStore from "../../stores/LandStore";
import authStore from "../../stores/AuthStore";
import PrintLand from "../../components/Report/Pdf/PrintLand";
import ExportFileLand from "../../components/Report/Excel/ExportFileLands";
import projectsStore from "../../stores/ProjectsStore";
import housesStore from "../../stores/HousesStore";
const Lands: React.FC = () => {
  const [projectValue, setProjectValue] = useState('');
  const [deedNo, setDeedNo] = useState('');
  const [houseValue, setHouseValue] = useState('');
  const printRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const exportRef = useRef(null);
  const [rows, setRows] = useState<any[]>([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState('');
  const [project, setProject] = useState<any[]>([]);
  const [house, setHouse] = useState<any[]>([]);
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [housesList, setHousesList] = useState<any[]>([]);
  const [deedType, setDeedType] = useState('all');

  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/lands');
  useEffect(() => {
    setIsLoading(true);
    handleSearch(undefined, undefined);
    projectsStore.getAll().then((res: any) => {
      setProjectsList(res);
      housesStore.getAll().then((res) => {
        setHousesList(res);
        setIsLoading(false);
      }).catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
    }).catch((err) => {
      console.error(err);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSearch = (pageNumber?: string, limitNumber?: string,) => {
    setIsLoading(true);
    setRows([]);
    const _page = parseInt(pageNumber || '0');
    const _limit = parseInt(limitNumber || '0');
    landStore.getByCriteria({
      project: projectValue,
      house: houseValue,
      deedNo: deedNo,
      page: _page,
      limit: _limit,
      deedType: deedType
    }).then((result: any) => {
      setRows(result.data.sort((a: any, b: any) => dayjs(b.createAt).diff(a.createAt)));
      setTotal(result.total);
      setTotalPrice(result.data.reduce((a: any, b: any) => parseFloat(a) + parseFloat(b.landPrice || 0), 0));
      setIsLoading(false);
    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
    });
  };

  const handleChangeDeedType = (event: ChangeEvent<HTMLInputElement>) => {
    setDeedType(event.target.value);
  };

  const calculateLandTotal = (data: any) => {
    const sum = (parseFloat(data?.landPrice ?? 0) + parseFloat(data?.registrationFee ?? 0) + parseFloat(data?.mortgageFee ?? 0)).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return sum;
  };
  return (
    <Box component={'div'}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>โครงการ</Typography>
        <Typography variant="h6" color="text.primary">ที่ดิน</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems={'end'}>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={deedType}
                      onChange={handleChangeDeedType}
                    >
                      <FormControlLabel value="all" control={<Radio />} label="ทั้งหมด" />
                      <FormControlLabel value="project" control={<Radio />} label="โครงการ" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {deedType === "project" && <Grid item xs={8} >
                  <Autocomplete
                    disablePortal
                    value={project}
                    options={projectsList}
                    sx={{ width: '100%' }}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option || '';
                      }
                      if (option.inputValue) {
                        return option.inputValue || '';
                      }
                      return option.name || '';
                    }}
                    renderInput={(params) => <TextField {...params} label="โครงการ"
                    />}
                    onChange={(e, value: any) => {
                      if (value?.id) {
                        housesStore.getByProjectId(value?.id).then((res) => {
                          setHousesList(res);
                        });
                      } else {
                        housesStore.getAll().then((res) => {
                          setHousesList(res);
                        });
                      }
                      setProject(value);
                      setProjectValue(value?.id);
                    }}
                  />
                </Grid>}
                {
                  deedType === "all" &&
                  <>
                    <Grid item xs={4} >
                      <Autocomplete
                        disablePortal
                        value={project}
                        options={projectsList}
                        sx={{ width: '100%' }}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        getOptionLabel={(option) => {
                          if (typeof option === 'string') {
                            return option || '';
                          }
                          if (option.inputValue) {
                            return option.inputValue || '';
                          }
                          return option.name || '';
                        }}
                        renderInput={(params) => <TextField {...params} label="โครงการ"
                        />}
                        onChange={(e, value: any) => {
                          if (value?.id) {
                            housesStore.getByProjectId(value?.id).then((res) => {
                              setHousesList(res);
                            });
                          } else {
                            housesStore.getAll().then((res) => {
                              setHousesList(res);
                            });
                          }
                          setProject(value);
                          setProjectValue(value?.id);
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} >
                      <Autocomplete
                        value={house}
                        disablePortal
                        options={housesList}
                        sx={{ width: '100%' }}
                        renderOption={(props, option) =>

                          <li {...props}>{option.name}</li>
                        }
                        getOptionLabel={(option) => {
                          if (typeof option === 'string') {
                            return option || '';
                          }
                          if (option.inputValue) {
                            return option.inputValue || '';
                          }
                          return option.name || '';
                        }}
                        renderInput={(params) => <TextField {...params} label="บ้าน"
                        />}
                        onChange={(e, value: any) => {
                          setHouse(value);
                          setHouseValue(value?.id);
                        }}
                      />
                    </Grid>
                  </>
                }
                <Grid item xs={2} >
                  <TextField id="outlined-basic" label="เลขที่โฉนด" variant="outlined" fullWidth
                    onChange={(event) => setDeedNo(event.target.value)} value={deedNo} />
                </Grid>
                <Grid item xs={2} textAlign={'end'} pb={0.5} >
                  <Button variant="contained" onClick={() => handleSearch()} startIcon={<SearchIcon />}>ค้นหา</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justifyContent={'end'}>
                    <Grid item xs={9}>
                      <Typography>ทั้งหมด {total.toLocaleString()} รายการ</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <DownloadTableExcel
                        filename={`project-land-${dayjs().format('DD-MM-YYYY')}`}
                        sheet={`project-land-${dayjs().format('DD-MM-YYYY')}`}
                        currentTableRef={exportRef.current}
                      >
                        <IconButton aria-label="download"  >
                          <DownloadIcon />
                        </IconButton>
                      </DownloadTableExcel>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="print" onClick={() => reactToPrintFn()}>
                        <PrintIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      {((permission && permission.create) || authStore.user?.isSuperAdmin) &&
                        <Button variant="contained" startIcon={<NoteAddIcon />} onClick={() => navigate('/land-create')}>สร้าง</Button>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>เลขที่โฉนด</TableCell>
                          <TableCell>ชื่อโครงการ</TableCell>
                          <TableCell>บ้าน</TableCell>
                          <TableCell>เนื้อที่</TableCell>
                          <TableCell>มูลค่ารวม</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => (
                          <TableRow
                            key={row.id + index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell component="th" scope="row" style={{ textDecoration: row.isDelete ? 'line-through' : 'none' }} >
                              {row.isDelete ? row.deedNo : <Tooltip title="ข้อมูลนี้ถูกลบแล้ว">{row.deedNo}</Tooltip>}
                            </TableCell>
                            <TableCell >{row?.childLand.length > 0 ?
                              <Typography>{row?.project?.name || ''}</Typography> : (row?.project?.name || '')}</TableCell>
                            <TableCell>
                              <Typography>     {row?.childLand?.sort((a: any, b: any) => dayjs(a.createAt).diff(b.createAt)).map((childLand: any, index: number) => {
                                if (index <= 1) {
                                  return <span key={childLand.id}>{`${childLand.house?.name || ''}${(index < 1 && row?.childLand?.length > 1) ? ', ' : ''} `}</span>;
                                } else if (index > 1) {
                                  return <span key={childLand.id}>{`${childLand.house?.name || ''}, ... `}</span>;
                                } else {
                                  return '';
                                }
                              })}</Typography>
                              {
                                row?.house?.name || ''
                              }
                            </TableCell>
                            <TableCell>{`${row?.rai ? parseFloat(row?.rai || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-'} ไร่ ${row?.ngan ? parseFloat(row?.ngan).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-'} งาน ${row?.squareWah ? parseFloat(row?.squareWah).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-'} ตร.วา`}</TableCell>
                            <TableCell>{calculateLandTotal(row)}</TableCell>
                            <TableCell align="right">
                              <IconButton aria-label="detail" onClick={() => navigate(`/land-edit/${row.id}`)}>
                                <KeyboardArrowRightIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                      count={total}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        handleSearch(newPage.toString());
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setRows([]);
                        setLimit(e.target.value);
                        setPage(0);
                        handleSearch('0', e.target.value.toString());
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <Box sx={{ display: "none" }}>
        <PrintLand ref={printRef} title={'รายการที่ดิน'} data={rows} total={totalPrice} />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileLand ref={exportRef} title={'รายการที่ดิน'} data={rows} total={totalPrice} />
      </Box>
    </Box>
  );
};

export default Lands;
