import {
  Box, Breadcrumbs, Button, Card, CardContent, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography
} from "@mui/material";
import React, { ChangeEvent, RefObject, createRef, useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/th';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import DialogDisplayImage from "../../components/DialogDisplayImage";
import { enqueueSnackbar } from "notistack";
import authStore from "../../stores/AuthStore";
import DialogForCheckQc from "../../components/DialogForCheckQc";
import { useReactToPrint } from 'react-to-print';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import purchasesStore from "../../stores/PurchasesStore";
import PrintOnePO from "../../components/Report/Pdf/PrintOnePO";
import companyStore from "../../stores/CompanyStore";
import ExportFilePO from "../../components/Report/Excel/ExportFilePO";
import PrintPO from "../../components/Report/Pdf/PrintPO";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ImageIcon from '@mui/icons-material/Image';
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import imagesStore from "../../stores/ImagesStore";
import SaveIcon from '@mui/icons-material/Save';

const Purchases: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs().startOf('month'));
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs());
  const [dateTypes, setDateType] = useState('createDate');
  const [docNo, setDocNo] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [openDisplayImageDialog, setOpenDisplayImageDialog] = useState({
    open: false,
    images: [],
    docNo: ''
  });
  const [openDialogForCheckQc, setOpenDialogForCheckQc] = useState({
    open: false,
    id: ''
  });
  const [rows, setRows] = useState<any[]>([]);
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/purchases');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [companyInfo, setCompanyInfo] = useState<any>([]);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [rowSelectImage, setRowSelectImage] = useState<string>('');
  const printRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const printOneRef = useRef<RefObject<HTMLTableRowElement>[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isClick, setIsClick] = useState(false);
  const reactToPrintOneFn = useReactToPrint({
    contentRef: selectedIndex !== null ? printOneRef?.current[selectedIndex] : undefined
  });

  const exportRef = useRef(null);
  const [reload, setReload] = useState<boolean>(false);
  const [sortType, setSortType] = useState({ name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" });
  const sortOption = [
    { name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" },
    { name: "วันที่บันทึก: เก่าสุด", variable: "createAtASC", sortBy: "createAt", sortType: "ASC" },
    { name: "วันที่เอกสาร: ล่าสุด", variable: "docDateDESC", sortBy: "docDate", sortType: "DESC" },
    { name: "วันที่เอกสาร: เก่าสุด", variable: "docDateASC", sortBy: "docDate", sortType: "ASC" },
    { name: "วันที่แก้ไข: ล่าสุด", variable: "updateAtDESC", sortBy: "updateAt", sortType: "DESC" },
    { name: "วันที่แก้ไข: เก่าสุด", variable: "updateAtASC", sortBy: "updateAt", sortType: "ASC" },
  ];
  const [sortValue, setSortValue] = useState("createAtDESC");

  const handleChangeDateType = (event: ChangeEvent<HTMLInputElement>) => {
    setDateType((event.target as HTMLInputElement).value);
  };

  const addRef = (el: any) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };

  const handleKeyDown = (event: any, index: number) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (index + 1 < inputRefs.current.length) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  window.addEventListener("beforeunload", function (event) {
    localStorage.removeItem('dateFrom');
    localStorage.removeItem('dateTo');
    localStorage.removeItem('sortType');
    localStorage.removeItem('limit');
    localStorage.removeItem('page');
  });

  useEffect(() => {
    companyStore.getAll().then((res) => {
      setCompanyInfo(res[0]);
    });
    const _dateFrom = localStorage.getItem('dateFrom');
    const _dateTo = localStorage.getItem('dateTo');
    const dateF = dayjs(_dateFrom ? _dateFrom : dateFrom);
    const dateT = dayjs(_dateTo ? _dateTo : dateTo);
    const _limit = localStorage.getItem('limit');
    const _page = localStorage.getItem('page');
    setDateFrom(dateF);
    setDateTo(dateT);
    setLimit(_limit ? parseInt(_limit) : limit);
    setPage(_page ? parseInt(_page) : page);
    const _sortType: any = localStorage.getItem('sortType');
    const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
    setSortType(_sortTypeConvert || sortType);
    setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
    if (!_sortType) {
      localStorage.setItem('sortType', JSON.stringify(sortType));
    }
    if (!_limit) {
      localStorage.setItem('limit', '10');
    }
    if (!_page) {
      localStorage.setItem('page', '0');
    }
    handleSearch(_page || undefined, _limit || undefined, dateF, dateT, _sortTypeConvert);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const handleChangeSort = (e: any) => {
    const findSortType: any = sortOption.find((item: any) => item.variable === e.target.value);
    localStorage.setItem('sortType', JSON.stringify(findSortType));
    setSortType(findSortType);
    setSortValue(e.target.value);
  };

  const handleSearch = (pageNumber?: string, limitNumber?: string, dateF?: Dayjs, dateT?: Dayjs, _sortType?: string, isRefresh = false) => {
    setIsLoading(true);
    setRows([]);
    const _page = parseInt(pageNumber || '0');
    const _limit = parseInt(limitNumber || '0');
    purchasesStore.getByCriteria({
      dateFrom: dateF ? dateF.format('YYYY-MM-DD') : dateFrom?.format('YYYY-MM-DD'),
      dateTo: dateT ? dateT.format('YYYY-MM-DD') : dateTo?.format('YYYY-MM-DD'),
      dateType: dateTypes,
      docNo: docNo,
      vendorName: vendorName,
      page: _page,
      limit: _limit,
      sortType: _sortType ? _sortType : sortType,
    }).then((response) => {
      const data = response.data;
      setTotal(response.total);
      if ((response?.total && response.total <= 10) || isRefresh === true) {
        setPage(0);
        setLimit(10);
      };
      setRows(data);
      const _sortType = localStorage.getItem('sortType');
      const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
      setSortType(_sortTypeConvert || sortType);
      setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
      if (!_sortType) {
        localStorage.setItem('sortType', JSON.stringify(sortType));
      }
      setIsLoading(false);
    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
    });
  };

  const handleQC = (item: any) => {
    purchasesStore.update(item.expenseId, {
      isChecked: item.checked,
      checkedBy: authStore?.user?.id,
      checkedAt: new Date()
    }).then((res) => {
      if (res.error) {
        enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
      }
      enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
      setOpenDialogForCheckQc({ open: false, id: '' });
      rows.map((row: any, index: number) => {
        const _row = [...rows];
        if (row.id === item.expenseId) {
          _row[index].isChecked = item.checked;
          _row[index].checkedAt = new Date();
          _row[index].checkedBy = authStore?.user;
          setRows(_row);
        }
        return row;
      });
    }).catch((error) => {
      enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
      console.error(error);
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const files = event.target.files;
    if (files && files.length > 0 && id) {
      setRowSelectImage(id);
      setSelectedFile(files[0]);
    }
  };

  const handleFileUpload = async (id: string) => {
    setReload(false);
    const formData = new FormData();
    formData.append("refType", "purchaseOrder");
    formData.append("refId", id);
    formData.append('file', selectedFile);
    formData.append('updateBy', authStore.user?.id);
    formData.append('createBy', authStore.user?.id);
    formData.append("createAt", new Date().toString());
    formData.append("updateAt", new Date().toString());
    await imagesStore.upload(formData)
      .then((response: any) => {
        if (response.error) {
          enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
          return;
        }
        setSelectedFile(null);
        setReload(true);
        enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
      }).catch((error) => {
        enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (selectedIndex !== null && isClick) {
      reactToPrintOneFn();
      setIsClick(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, reactToPrintOneFn]);

  return (
    <Box component={'div'}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>ใบสั่งซื้อ</Typography>
        <Typography variant="h6" color="text.primary">รายการใบสั่งซื้อ</Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems={'end'}>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={dateTypes}
                      onChange={handleChangeDateType}
                    >
                      <FormControlLabel value="createDate" control={<Radio />} label="วันที่บันทึก" />
                      <FormControlLabel value="updateDate" control={<Radio />} label="วันที่แก้ไข" />
                      <FormControlLabel value="purchaseDate" control={<Radio />} label="วันที่ใบสั่งซื้อ" />
                    </RadioGroup>
                  </FormControl>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                    <DemoContainer components={['DatePicker', 'DatePicker']} >
                      <DatePicker
                        label="ตั้งแต่วันที่"
                        value={dateFrom}
                        onChange={(newValue) => {
                          setDateFrom(newValue);
                          localStorage.setItem('dateFrom', newValue?.format('YYYY-MM-DD') || '');
                        }}
                      />
                      <DatePicker
                        label="ถึงวันที่"
                        value={dateTo}
                        onChange={(newValue) => {
                          setDateTo(newValue);
                          localStorage.setItem('dateTo', newValue?.format('YYYY-MM-DD') || '');
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <TextField id="outlined-basic" label="เลขใบสั่งซื้อ" variant="outlined" fullWidth
                    onChange={(event) => {
                      setDocNo(event.target.value);
                    }}
                    inputRef={addRef}
                    onKeyDown={(e) => handleKeyDown(e, 0)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField id="outlined-basic" label="ชื่อผู้ขาย" variant="outlined" fullWidth
                    onChange={(event) => {
                      setVendorName(event.target.value);
                    }}
                    inputRef={addRef}
                    onKeyDown={(e) => handleKeyDown(e, 1)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">เรียงตาม
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sortValue}
                      label="sortType"
                      onChange={handleChangeSort}
                    >
                      {sortOption.map((option: any, index: number) => (
                        <MenuItem key={`sort-option-${index}`} value={option.variable}>{option.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} textAlign={'end'} pb={0.5}>
                  <Button variant="contained" onClick={() => {
                    handleSearch(undefined, undefined, undefined, undefined, undefined, true);
                  }} startIcon={<SearchIcon />}>ค้นหา</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container >
                    <Grid item xs={9}>
                      <Typography>ทั้งหมด {total?.toLocaleString()} รายการ</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <DownloadTableExcel
                        filename={`purchase-order-${dayjs(dateFrom).format('DD-MM-YYYY')} - ${dayjs(dateTo).format('DD-MM-YYYY')}`}
                        sheet={`purchase-order-${dayjs(dateFrom).format('DD-MM-YYYY')} - ${dayjs(dateTo).format('DD-MM-YYYY')}`}
                        currentTableRef={exportRef.current}
                      >
                        <IconButton aria-label="download"  >
                          <DownloadIcon />
                        </IconButton>
                      </DownloadTableExcel>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="print" onClick={() => reactToPrintFn()}>
                        <PrintIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      {((permission && permission.create) || authStore.user?.isSuperAdmin) && <Button variant="contained" startIcon={<NoteAddIcon />} onClick={() => navigate('/purchase-create')}>สร้าง</Button>}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow >
                          <TableCell  >วันที่เอกสาร</TableCell>
                          <TableCell  >เลขเอกสาร</TableCell>
                          <TableCell >ชื่อผู้ขาย</TableCell>
                          <TableCell >ยอดรวมสุทธิ</TableCell>
                          <TableCell width={100}>บันทึก</TableCell>
                          <TableCell width={100}>แก้ไข</TableCell>
                          <TableCell   ></TableCell>
                          <TableCell  ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows && rows.length > 0 && rows.map((row: any, index: number) => {
                          if (!printOneRef.current[index]) {
                            printOneRef.current[index] = createRef();
                          }
                          return (
                            <TableRow
                              key={`bill-row-${index}`}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {dayjs(row.docDate).format('DD/MM/YYYY')}
                              </TableCell>
                              <TableCell>{row.docNo}</TableCell>
                              <TableCell>{row.vendor?.name}</TableCell>
                              <TableCell align="right">{parseFloat(row.grandTotal)?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
                              <TableCell >{<b>{row.createBy?.name}</b>} {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                              <TableCell>{<b>{row.updateBy?.name}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''} </TableCell>
                              <TableCell>
                                <Box display={'flex'} justifyContent={'space-between'}>
                                  <Box component={'span'}>
                                    <IconButton aria-label="print" onClick={() => {
                                      setIsClick(true);
                                      setSelectedIndex(index);
                                    }}>
                                      <PrintIcon />
                                    </IconButton>
                                    <Box style={{ display: "none" }}>
                                      <PrintOnePO ref={printOneRef.current[index]} title="ใบสั่งซื้อ" date={`${dayjs(dateFrom).format('DD/MM/YYYY')} - ${dayjs(dateTo).format('DD/MM/YYYY')}`}
                                        data={row}
                                        companyInfo={companyInfo} details={row.purchaseOrderDetail ?? []}
                                      />
                                    </Box>
                                  </Box>
                                  <Box component={'span'} >
                                    {
                                      row.images.length > 0 &&
                                      <IconButton color="warning" onClick={() => {
                                        setOpenDisplayImageDialog({ open: true, images: row.images, docNo: row.docNo });
                                      }}>
                                        <ImageIcon />
                                      </IconButton>
                                    }
                                    {
                                      row.images.length < 1 && rowSelectImage !== row.id &&
                                      <IconButton component="label">
                                        <UploadFileIcon />
                                        <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png" onChange={(e) => {
                                          handleFileChange(e, row.id);
                                        }}
                                        />
                                      </IconButton>
                                    }
                                    {
                                      (selectedFile && row.images.length < 1 && rowSelectImage === row.id) &&
                                      <IconButton component="label" color="primary" onClick={() => handleFileUpload(row.id)
                                      }>
                                        <SaveIcon />
                                      </IconButton>
                                    }
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell>
                                <IconButton aria-label="edit" onClick={() => navigate(`/purchase-edit/${row?.id}`)}>
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {rows && rows.length === 0 && (
                          <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell colSpan={10} align="center">ไม่พบข้อมูล</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                      count={total}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        handleSearch(newPage.toString());
                        localStorage.setItem('page', newPage.toString());
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setRows([]);
                        setLimit(e.target.value);
                        setPage(0);
                        handleSearch('0', e.target.value.toString());
                        localStorage.setItem('limit', e.target.value.toString());
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogDisplayImage
        open={openDisplayImageDialog.open}
        onClose={() => setOpenDisplayImageDialog({ open: false, images: [], docNo: '' })}
        images={openDisplayImageDialog.images}
        docNo={openDisplayImageDialog.docNo}
      />
      < DialogForCheckQc
        open={openDialogForCheckQc.open}
        id={openDialogForCheckQc.id}
        onSubmit={(item: any) => {
          handleQC(item);
        }}
        onClose={() => setOpenDialogForCheckQc({ open: false, id: '' })} />
      <Box style={{ display: "none" }}>
        {<PrintPO ref={printRef} title="ใบสั่งซื้อ" date={`${dayjs(dateFrom).format('DD/MM/YYYY')} - ${dayjs(dateTo).format('DD/MM/YYYY')}`} data={rows ?? []}
        />}
      </Box>
      <Box style={{ display: "none" }}>
        {<ExportFilePO ref={exportRef} title="ใบสั่งซื้อ" date={`${dayjs(dateFrom).format('DD/MM/YYYY')} - ${dayjs(dateTo).format('DD/MM/YYYY')}`} data={rows ?? []} />}
      </Box>
    </Box >
  );
};

export default Purchases;
