import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Breadcrumbs, Grid, IconButton, Typography, Link, Button, ButtonProps, Card, CardContent, TextField, Autocomplete, createFilterOptions, Divider, Stack, MenuItem, FormControl, InputLabel, Select, RadioGroup, Radio, FormControlLabel, SelectChangeEvent } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import SimpleBackdrop from '../../components/SimpleBackdrop';
import PrintOneContract from '../../components/Report/Pdf/Document/PrintOneContract';
import companyStore from '../../stores/CompanyStore';
import contractStore from '../../stores/ContractStore';
import authStore from '../../stores/AuthStore';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/th';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import workersStore from '../../stores/WorkersStore';
import DialogCreateWorker from '../../components/DialogCreateWorker';
import projectsStore from '../../stores/ProjectsStore';
import housesStore from '../../stores/HousesStore';
import masterDataStore from '../../stores/MasterDataStore';
import landStore from '../../stores/LandStore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { v4 as uuid4 } from 'uuid';
import DialogConfirm from '../../components/DialogConfirm';
import { enqueueSnackbar } from 'notistack';
import DraftContract from '../../components/DraftContract';

interface WorkerOptionType {
  inputValue?: string;
  name: string;
}
const filterWorker = createFilterOptions<WorkerOptionType>();

const ContractCreate: React.FC = observer(() => {
  const navigate = useNavigate();
  const { id } = useParams();
  const printRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const [isLoading, setIsLoading] = useState(false);
  const [companyInfo, setCompanyInfo] = useState<any>([]);
  const [contractType, setContractType] = useState('');
  const [contractTypeId, setContractTypeId] = useState('');
  const [contractTypeData, setContractTypeData] = useState<any[]>([]);
  const [title, setTitle] = useState('');
  const [contractDate, setContractDate] = useState<Dayjs | null>(dayjs());
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/contracts');
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: '',
    message: '',
    color: 'primary' as ButtonProps['color'],
    type: 'create' as 'create' | 'edit' | 'delete' | 'confirm-duplicate'
  });
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [workersList, setWorkersList] = useState<any[]>([]);
  const [workerValue, setWorkerValue] = useState<any>([]);
  const [openAddWorkerDialog, setOpenAddWorkerDialog] = useState(false);
  const [dialogCreateWorkerValue, setDialogCreateWorkerValue] = useState('');
  const [workerAddress, setWorkerAddress] = useState('');
  const [workerAlley, setWorkerAlley] = useState('');
  const [workerStreet, setWorkerStreet] = useState('');
  const [workerMoo, setWorkerMoo] = useState('');
  const [workerProvince, setWorkerProvince] = useState('');
  const [workerDistrict, setWorkerDistrict] = useState('');
  const [workerSubDistrict, setWorkerSubDistrict] = useState('');
  const [workerProvinceId, setWorkerProvinceId] = useState('');
  const [workerDistrictId, setWorkerDistrictId] = useState('');
  const [workerSubDistrictId, setWorkerSubDistrictId] = useState('');
  const [workerZipcode, setWorkerZipcode] = useState('');
  const [workerTel, setWorkerTel] = useState('');
  const [provinceData, setProvinceData] = useState<any>([]);
  const [districtData, setDistrictData] = useState<any>([]);
  const [subDistrictData, setSubDistrictData] = useState<any[]>([]);
  const [districtOptions, setDistrictOptions] = useState<any>([]);
  const [subDistrictOptions, setSubDistrictOptions] = useState<any>([]);
  const [project, setProject] = useState('all');
  const [projectData, setProjectData] = useState([]);
  const [house, setHouse] = useState('all');
  const [houseData, setHouseData] = useState([]);
  const [houseDataFilter, setHouseDataFilter] = useState([]);
  const [landData, setLandData] = useState<any>([]);
  const [amountOfHouse, setAmountOfHouse] = useState('');
  const [grandTotal, setGrandTotal] = useState('');
  const [pricePerHouse, setPricePerHouse] = useState('');
  const [amountOfArea, setAmountOfArea] = useState('');
  const [pricePerArea, setPricePerArea] = useState('');
  const [amountOfEave, setAmountOfEave] = useState('');
  const [pricePerEave, setPricePerEave] = useState('');
  const [amountOfDate, setAmountOfDate] = useState('');
  const [amountOfMonth, setAmountOfMonth] = useState('');
  const [mulct, setMulct] = useState('');
  const [notifyDate, setNotifyDate] = useState('');
  const [remark, setRemark] = useState('');
  const [period, setPeriod] = useState<any[]>([{ id: uuid4(), order: '', description: '', total: '', remark: '', isNew: true }]);
  const [periodOld, setPeriodOld] = useState<any[]>([]);
  const [content, setContent] = useState('');
  const [employerTypes, setEmployerTypes] = useState('company');
  const [employerName, setEmployerName] = useState('');
  const [employerTaxId, setEmployerTaxId] = useState('');
  const [employerAddress, setEmployerAddress] = useState('');
  const [employerAlley, setEmployerAlley] = useState('');
  const [employerStreet, setEmployerStreet] = useState('');
  const [employerMoo, setEmployerMoo] = useState('');
  const [employerProvince, setEmployerProvince] = useState('');
  const [employerProvinceId, setEmployerProvinceId] = useState('');
  const [employerSubDistrict, setEmployerSubDistrict] = useState('');
  const [employerDistrictId, setEmployerDistrictId] = useState('');
  const [employerDistrict, setEmployerDistrict] = useState('');
  const [employerSubDistrictId, setEmployerSubDistrictId] = useState('');
  const [employerZipcode, setEmployerZipcode] = useState('');
  const [employerTel, setEmployerTel] = useState('');
  const [districtEmployerOptions, setDistrictEmployerOptions] = useState<any>([]);
  const [subDistrictEmployerOptions, setSubDistrictEmployerOptions] = useState<any>([]);
  const [isSaveTemplate, setIsSaveTemplate] = useState(false);
  const [contentContract, setContentContract] = useState('');
  const [titleContract, setTitleContract] = useState('');
  const [openConfirmTemplate, setOpenConfirmTemplate] = useState({
    open: false,
    title: '',
    message: '',
    color: 'primary' as ButtonProps['color'],
    type: 'create' as 'create' | 'edit' | 'delete' | 'confirm-duplicate'
  });

  const addRef = (el: any) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };
  const handleKeyDown = (event: any, index: number, i = 0) => {
    const currentInput = event.target;
    if (event.key === "Enter") {
      const currentIndex = Array.from(inputRefs.current).indexOf(currentInput);
      if (i === -1) {
        inputRefs.current[10].focus();
      }
      else if (index === 8 && i === 0) {

        if (inputRefs.current[11]) {
          inputRefs.current[11].focus();
        }
        else {
          const nextIndex = currentIndex + 1;
          inputRefs.current[nextIndex].focus();
        }

      } else {
        const nextIndex = currentIndex + 1;
        if (nextIndex < inputRefs.current.length) {
          inputRefs.current[nextIndex].focus();
          event.preventDefault();
        }
      }
    }
  };

  const handleChangeWorker = (value: any) => {
    setWorkerValue(value);
    setWorkerAddress(value?.address);
    setWorkerAlley(value?.alley);
    setWorkerStreet(value?.street);
    setWorkerMoo(value?.moo);
    setWorkerDistrict(value?.district_nameTh ? value?.district_nameTh : (value?.district?.nameTh ?? ''));
    setWorkerProvinceId(value?.province_id ? value?.province_id : '');
    setWorkerProvince(value?.province_nameTh ? value?.province_nameTh : (value?.province?.nameTh ?? ''));
    setWorkerDistrictId(value?.district_id ? value?.district_id : '');
    setWorkerSubDistrict(value?.subDistrict_nameTh ? value?.subDistrict_nameTh : (value?.subDistrict?.nameTh ?? ''));
    setWorkerSubDistrictId(value?.subDistrict_id ? value?.subDistrict_id : '');
    setWorkerZipcode(value?.subDistrict_zipCode ? value?.subDistrict_zipCode : (value?.zipCode ?? ''));
    setWorkerTel(value?.worker_tel ? value?.worker_tel : (value?.tel ?? ''));
  };

  const handleChangeProject = (event: any) => {
    setProject(event.target.value);
    const _houseData = houseData.filter((house: any) => house?.project?.id === event.target.value);
    setHouseDataFilter(_houseData);
  };

  const handleChangeHouse = (event: any) => {
    setHouse(event.target.value as string);
    landStore.getByHouseId(event.target.value).then((data: any) => {
      setLandData(data);
    });
  };

  const handleRemoveItem = (id: string) => {
    setPeriod(period.filter((item) => item.id !== id));
  };

  const handleAddItem = () => {
    setPeriod([...period, { id: uuid4(), order: '', description: '', total: '', remark: '', isNew: true }]);
  };

  const handleSave = async () => {
    try {
      if (!workerValue?.id) {
        enqueueSnackbar('กรุณาเลือกผู้รับจ้าง', { variant: 'warning' });
        setIsLoading(false);
        return;
      }
      if (!titleContract) {
        enqueueSnackbar('กรุณากรอกชื่อสัญญา', { variant: 'warning' });
        setIsLoading(false);
        return;
      }

      if (isSaveTemplate === true) {
        handleSaveTemplate();
      }
      await workersStore.update(workerValue?.id, {
        address: workerAddress,
        alley: workerAlley,
        street: workerStreet,
        moo: workerMoo,
        district: workerDistrictId !== '' ? workerDistrictId : null,
        province: workerProvinceId !== '' ? workerProvinceId : null,
        subDistrict: workerSubDistrictId !== '' ? workerSubDistrictId : null,
        zipCode: workerZipcode,
        updateBy: authStore.user?.id,
        updateAt: new Date(),
      }).then((res) => {
        if (res.error) {
          enqueueSnackbar(res.error.message, { variant: 'error' });
          setIsLoading(false);
          return;
        } else {
          setIsLoading(false);
        }
      }).catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
      await contractStore.create({
        worker: workerValue?.id,
        project: project !== 'all' ? project : null,
        house: house !== 'all' ? house : null,
        amountOfHouse: amountOfHouse !== '' ? parseFloat(amountOfHouse) : null,
        pricePerHouse: pricePerHouse !== '' ? parseFloat(pricePerHouse) : null,
        contractDate: contractDate?.format('YYYY-MM-DD'),
        amountOfDate: amountOfDate !== '' ? parseFloat(amountOfDate) : null,
        amountOfMonth: amountOfMonth !== '' ? parseFloat(amountOfMonth) : null,
        total: grandTotal !== '' ? parseFloat(grandTotal) : null,
        amountOfArea: amountOfArea !== '' ? parseFloat(amountOfArea) : null,
        pricePerArea: pricePerArea !== '' ? parseFloat(pricePerArea) : null,
        amountOfEave: amountOfEave !== '' ? parseFloat(amountOfEave) : null,
        pricePerEave: pricePerEave !== '' ? parseFloat(pricePerEave) : null,
        mulct: mulct !== '' ? parseFloat(mulct) : null,
        notifyDate: notifyDate !== '' ? parseFloat(notifyDate) : null,
        contractType: contractTypeId !== '' ? contractTypeId : null,
        remark: remark,
        employerName: employerName,
        employerTaxId: employerTaxId,
        employerAddress: employerAddress,
        employerAlley: employerAlley,
        employerStreet: employerStreet,
        employerMoo: employerMoo,
        employerDistrict: employerDistrictId !== '' ? employerDistrictId : null,
        employerProvince: employerProvinceId !== '' ? employerProvinceId : null,
        employerSubDistrict: employerSubDistrictId !== '' ? employerSubDistrictId : null,
        employerZipcode: employerZipcode,
        employerTel: employerTel,
        content: contentContract,
        title: titleContract,
        createBy: authStore.user?.id,
        createAt: new Date(),

        period: period.map((item: any) => {
          return {
            order: item.order !== '' ? parseFloat(item.order) : null,
            description: item.description,
            total: item.total !== '' ? parseFloat(item.total) : null,
            remark: item.remark,
            createBy: authStore.user?.id,
            createAt: new Date(),
          };
        })
      }).then((res) => {
        if (res.error) {
          enqueueSnackbar(res.error.message, { variant: 'error' });
          setIsLoading(false);
          return;
        } else {
          setIsLoading(false);
          enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
          navigate(`/contract-edit/${res.id}`);
        }
      }).catch((err) => {
        console.error(err);
        setIsLoading(false);
        enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleEdit = async () => {
    try {
      if (!workerValue?.id) {
        enqueueSnackbar('กรุณาเลือกผู้รับจ้าง', { variant: 'warning' });
        setIsLoading(false);
        return;
      }
      if (!titleContract) {
        enqueueSnackbar('กรุณากรอกชื่อสัญญา', { variant: 'warning' });
        setIsLoading(false);
        return;
      }
      if (isSaveTemplate === true) {
        handleSaveTemplate();
      }
      if (id) {
        workersStore.update(workerValue?.id, {
          address: workerAddress,
          alley: workerAlley,
          street: workerStreet,
          moo: workerMoo,
          district: workerDistrictId !== '' ? workerDistrictId : null,
          province: workerProvinceId !== '' ? workerProvinceId : null,
          subDistrict: workerSubDistrictId !== '' ? workerSubDistrictId : null,
          zipCode: workerZipcode,
          tel: workerTel,
          updateBy: authStore.user?.id,
          updateAt: new Date(),
        }).then((res) => {
          if (res.error) {
            enqueueSnackbar(res.error.message, { variant: 'error' });
            setIsLoading(false);
            return;
          } else {
            setIsLoading(false);
          }
        }).catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
        const _period = await period.map((item: any) => {
          return {
            documentContract: id,
            order: item.order !== '' ? parseFloat(item.order) : null,
            description: item.description,
            total: item.total !== '' ? parseFloat(item.total) : null,
            remark: item.remark,
            createBy: authStore.user?.id,
            createAt: new Date(),
          };
        });
        periodOld.forEach((item) => {
          contractStore.deletePeriod(item.id);
        });
        contractStore.createPeriod(_period);

        contractStore.update(id, {
          worker: workerValue?.id,
          project: project !== 'all' ? project : null,
          house: house !== 'all' ? house : null,
          amountOfHouse: amountOfHouse !== '' ? parseFloat(amountOfHouse) : null,
          pricePerHouse: pricePerHouse !== '' ? parseFloat(pricePerHouse) : null,
          contractDate: contractDate?.format('YYYY-MM-DD'),
          amountOfDate: amountOfDate !== '' ? parseFloat(amountOfDate) : null,
          amountOfMonth: amountOfMonth !== '' ? parseFloat(amountOfMonth) : null,
          total: grandTotal !== '' ? parseFloat(grandTotal) : null,
          amountOfArea: amountOfArea !== '' ? parseFloat(amountOfArea) : null,
          pricePerArea: pricePerArea !== '' ? parseFloat(pricePerArea) : null,
          amountOfEave: amountOfEave !== '' ? parseFloat(amountOfEave) : null,
          pricePerEave: pricePerEave !== '' ? parseFloat(pricePerEave) : null,
          mulct: mulct !== '' ? parseFloat(mulct) : null,
          notifyDate: notifyDate !== '' ? parseFloat(notifyDate) : null,
          contractType: contractTypeId !== '' ? contractTypeId : null,
          remark: remark,
          employerName: employerName,
          employerTaxId: employerTaxId,
          employerAddress: employerAddress,
          employerAlley: employerAlley,
          employerStreet: employerStreet,
          employerMoo: employerMoo,
          employerDistrict: employerDistrictId !== '' ? employerDistrictId : null,
          employerProvince: employerProvinceId !== '' ? employerProvinceId : null,
          employerSubDistrict: employerSubDistrictId !== '' ? employerSubDistrictId : null,
          employerZipcode: employerZipcode,
          employerTel: employerTel,
          title: titleContract,
          content: contentContract,
          updateBy: authStore.user?.id,
          updateAt: new Date(),
        }).then((res) => {
          if (res.error) {
            enqueueSnackbar(res.error.message, { variant: 'error' });
            setIsLoading(false);
            return;
          } else {
            setIsLoading(false);
            enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
          }
        }).catch((err) => {
          console.error(err);
          setIsLoading(false);
          enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = () => {
    try {
      setIsLoading(true);
      if (id) {
        contractStore.update(id, {
          isDeleted: true,
          updateBy: authStore.user?.id,
          updateAt: new Date()
        });
        enqueueSnackbar('ลบข้อมูลสำเร็จ', { variant: 'success' });
        setIsLoading(false);
        navigate('/contracts');
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const handleSaveTemplate = () => {
    try {
      contractStore.createTemplateContracts({
        name: titleContract,
        content: contentContract,
      }).then((res: any) => {
        contractTypeData.push(res);
      });

    }
    catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    try {
      setIsLoading(true);
      Promise.all([
        companyStore.getAll(),
        workersStore.getAll(),
        projectsStore.getAll(),
        housesStore.getAll(),
        masterDataStore.getContractTypeAll(),
        masterDataStore.getAllProvince(),
        masterDataStore.getAllAmphur(),
        masterDataStore.getAllTambon(),
        contractStore.getAllTemplateContracts(),
      ]).then((res: any) => {
        setCompanyInfo(res[0][0]);
        setWorkersList(res[1]);
        setProjectData(res[2]);
        setHouseData(res[3]);
        setProvinceData(res[5]);
        setDistrictData(res[6]);
        setSubDistrictData(res[7]);
        setContractTypeData(res[8]);
        setIsLoading(false);
      }).catch((err) => {
        console.error(err);
        enqueueSnackbar('เกิดข้อผิดพลาด', { variant: 'error' });
        setIsLoading(false);
      });
      if (id) {
        contractStore.get(id).then((res) => {
          setContractType(res.contractType?.nameEn ?? '');
          setContractTypeId(res.contractType?.id ?? '');
          setTitle(res.contractType?.name ?? '');
          const worker = res.worker;
          worker.name = worker?.firstName + ' ' + worker?.lastName;
          handleChangeWorker(worker);
          setProject(res.project?.id || 'all');
          setHouse(res.house?.id || 'all');
          setGrandTotal(res.total);
          setRemark(res.remark);
          setPeriod(res.periods);
          setPeriodOld(res.periods);
          setPricePerHouse(res.pricePerHouse ?? '');
          setAmountOfHouse(res.amountOfHouse ?? '');
          setPricePerArea(res.pricePerArea ?? '');
          setAmountOfArea(res.amountOfArea ?? '');
          setPricePerEave(res.pricePerEave ?? '');
          setAmountOfEave(res.amountOfEave ?? '');
          setMulct(res.mulct ?? '');
          setNotifyDate(res.notifyDate ?? '');
          setAmountOfDate(res.amountOfDate ?? '');
          setAmountOfMonth(res.amountOfMonth ?? '');
          setContractDate(res.contractDate ? dayjs(res.contractDate) : dayjs());
          setLandData(res?.house?.land ?? []);
          setTitleContract(res.title);
          setContent(res.content);
          if (res.employerName) setEmployerTypes('person');
          setEmployerName(res.employerName);
          setEmployerTaxId(res.employerTaxId);
          setEmployerAddress(res.employerAddress);
          setEmployerAlley(res.employerAlley);
          setEmployerMoo(res.employerMoo);
          setEmployerStreet(res.employerStreet);
          setEmployerProvince(res.employerProvince?.nameTh);
          setEmployerProvinceId(res.employerProvince?.id);
          setEmployerDistrict(res.employerDistrict);
          setEmployerDistrictId(res.employerDistrict);
          setEmployerSubDistrict(res.employerSubDistrict);
          setEmployerSubDistrictId(res.employerSubDistrict);
          setEmployerZipcode(res.employerPostcode);
          setEmployerTel(res.employerTel);
          setIsLoading(false);
        });
      }
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeEmployerType = (event: SelectChangeEvent) => {
    setEmployerTypes(event.target.value as string);
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | any, name: string) => {
    const value: any = e?.target?.value ? e?.target?.value : e;

    switch (name) {
      case 'employerName': setEmployerName(value); break;
      case 'employerTaxId': setEmployerTaxId(value); break;
      case 'employerAddress': setEmployerAddress(value); break;
      case 'employerAlley': setEmployerAlley(value); break;
      case 'employerMoo': setEmployerMoo(value); break;
      case 'employerStreet': setEmployerStreet(value); break;
      case 'employerProvince':
        setEmployerProvince(value?.nameTh);
        setEmployerProvinceId(value?.id);
        setDistrictEmployerOptions(districtData.filter((district: any) => district?.province?.id === value?.id));
        break;
      case 'employerDistrict':
        setEmployerDistrictId(value?.id);
        setEmployerDistrict(value?.nameTh);
        setSubDistrictEmployerOptions(subDistrictData.filter((subDistrict: any) => subDistrict?.amphur?.id === value?.id));
        break;
      case 'employerSubDistrict':
        setEmployerSubDistrictId(value?.id);
        setEmployerSubDistrict(value?.nameTh);
        setEmployerZipcode(value?.zipCode);
        break;
      case 'employerZipcode': setEmployerZipcode(value); break;
      case 'employerTel': setEmployerTel(value); break;
    }
  };
  return (
    <Box component={'div'}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton aria-label='delete' onClick={() => navigate('/contracts')}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={'flex'} alignItems={'center'}>
          <Breadcrumbs separator='›' aria-label='breadcrumb'>
            <Typography>เอกสาร</Typography>
            <Link component='button' onClick={() => navigate('/contracts')}>สัญญา</Link>
            <Typography variant='h6' color='text.primary'>{id ? 'แก้ไข' : 'สร้าง'}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={1.5} display={'flex'} justifyContent={'end'}>
          <Button variant='outlined' color='success' startIcon={<PrintIcon />} onClick={() => reactToPrintFn()}>
            พิมพ์สัญญา
          </Button>

        </Grid>
        <Grid item xs={1.5} display={'flex'} justifyContent={'end'}>
          {((permission && permission.delete) || authStore.user?.isSuperAdmin) && id &&
            <Button variant='contained' color='error' startIcon={<DeleteIcon />} onClick={() => setOpenConfirm({
              open: true,
              title: 'ลบเอกสาร',
              message: 'คุณต้องการลบเอกสารนี้ใช่หรือไม่',
              color: 'error',
              type: 'delete'
            })}>ลบเอกสาร</Button>
          }
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4} >
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'} >
                    <DatePicker
                      label='วันที่เอกสาร'
                      value={contractDate}
                      onChange={(newValue) => setContractDate(newValue)}
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <Typography>ข้อมูลผู้ว่าจ้าง</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={employerTypes}
                      onChange={handleChangeEmployerType}
                    >
                      <FormControlLabel value="company" control={<Radio />} label="บริษัท" />
                      <FormControlLabel value="person" control={<Radio />} label="บุคคลธรรมดา" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {employerTypes === 'person' &&
                  <>
                    <Grid item xs={3}>
                      <TextField value={employerName} onChange={(e: any) => handleChange(e, 'employerName')} fullWidth label="ชื่อผู้ว่าจ้าง" inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 33);
                        }} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField value={employerTaxId} onChange={(e: any) => handleChange(e, 'employerTaxId')} fullWidth label="เลขประจําตัวผู้เสียภาษี"
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 34);
                        }} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField value={employerAddress} onChange={(e: any) => handleChange(e, 'employerAddress')} fullWidth label="ที่อยู่"
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 35);
                        }} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField value={employerMoo} onChange={(e: any) => handleChange(e, 'employerMoo')} fullWidth label="หมู่"
                        inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 36);
                        }} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField value={employerAlley} onChange={(e: any) => handleChange(e, 'employerAlley')} fullWidth label="ตรอก/ซอย" inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 37);
                        }} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField value={employerStreet} onChange={(e: any) => handleChange(e, 'employerStreet')} fullWidth label="ถนน" inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 38);
                        }} />
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        value={employerProvince}
                        id='employerProvince'
                        onChange={(e: any, value: any) => handleChange(value, 'employerProvince')}
                        disablePortal
                        options={provinceData}
                        sx={{ width: '100%' }}
                        renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                        getOptionLabel={(option: any) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.nameTh;
                        }}
                        renderInput={(params) => <TextField {...params} label='จังหวัด' inputRef={addRef}
                          onKeyDown={(e) => {
                            if (e.key === '-') {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 7);
                          }} />}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        value={employerDistrict}
                        id='employerDistrict'
                        onChange={(e: any, value: any) => handleChange(value, 'employerDistrict')}
                        disablePortal
                        options={districtEmployerOptions}
                        sx={{ width: '100%' }}
                        renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                        getOptionLabel={(option: any) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.nameTh;
                        }}
                        renderInput={(params) => <TextField {...params} label='อำเภอ' inputRef={addRef}
                          onKeyDown={(e) => {
                            if (e.key === '-') {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 8);
                          }} />}
                        disabled={(districtEmployerOptions.length > 0 || employerDistrict !== '') ? false : true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Autocomplete
                        value={employerSubDistrict ?? ''}
                        id='employerSubDistrict'
                        onChange={(e: any, value: any) => handleChange(value, 'employerSubDistrict')}
                        disablePortal
                        options={subDistrictEmployerOptions}
                        sx={{ width: '100%' }}
                        renderOption={(props: any, option: any) => <li {...props}>{option.nameTh}</li>}
                        getOptionLabel={(option: any) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.nameTh;
                        }}
                        renderInput={(params) => <TextField {...params} label='ตำบล' inputRef={addRef}
                          onKeyDown={(e) => {
                            if (e.key === '-') {
                              e.preventDefault();
                            }
                            handleKeyDown(e, 9);
                          }} />}
                        disabled={(subDistrictEmployerOptions.length > 0 || employerSubDistrict !== '') ? false : true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField label='รหัสไปรษณีย์' variant='outlined' fullWidth value={employerZipcode} onChange={(e: any) =>
                        handleChange(e, 'employerZipcode')} inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 10);
                        }} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField label='เบอร์โทร' variant='outlined' fullWidth value={employerTel} onChange={(e: any) => handleChange(e, 'employerTel')} inputRef={addRef}
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                          handleKeyDown(e, 11);
                        }} />
                    </Grid>
                  </>
                }
                {employerTypes === 'company' &&
                  <>
                    <Grid item xs={4}>
                      <Typography>บริษัท {companyInfo?.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>ที่อยู่ {companyInfo?.address || '-'} หมู่ที่ {companyInfo?.moo || '-'} ตรอก/ซอย{companyInfo?.alley || '-'} ถนน{companyInfo?.street || '-'} ต.{companyInfo?.subDistrict?.nameTh || '-'} อ.{companyInfo?.district?.nameTh || '-'} จ.{companyInfo?.province?.nameTh || '-'} </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>โทร {companyInfo?.tel || '-'}</Typography>
                    </Grid>
                  </>
                }
                <Grid item xs={12}>
                  <Typography>ข้อมูลผู้รับจ้าง</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={workerValue}
                    disablePortal
                    sx={{ width: '100%' }}
                    onChange={async (event, newValue) => {

                      if (typeof newValue === 'string') {
                        setTimeout(() => {
                          setOpenAddWorkerDialog(true);
                          setDialogCreateWorkerValue(newValue);
                        });
                      } else if (newValue && newValue.inputValue) {
                        setOpenAddWorkerDialog(true);
                        setDialogCreateWorkerValue(newValue.inputValue);
                      } else {
                        handleChangeWorker(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filterWorker(options, params);
                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          name: `สร้าง '${params.inputValue}'`,
                        });
                      }
                      return filtered;
                    }}
                    id={`item-worker-autocomplete`}
                    options={workersList}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      if (option.name) { return option.name; }
                      if (!option.name) {
                        return '';
                      }
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label='ชื่อผู้รับจ้าง' variant='outlined'
                      inputRef={addRef}
                    />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField label='ที่อยู่' variant='outlined' fullWidth value={workerAddress} onChange={(e) => setWorkerAddress(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 0);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label='หมู่' variant='outlined' fullWidth multiline value={workerMoo} onChange={(e) => setWorkerMoo(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 1);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label='ตรอก/ซอย' variant='outlined' fullWidth value={workerAlley} onChange={(e) => setWorkerAlley(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 2);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField label='ถนน' variant='outlined' fullWidth value={workerStreet} onChange={(e) => setWorkerStreet(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 6);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={workerProvince}
                    id='province'
                    onChange={(e: any, value: any) => {
                      if (value?.id) {
                        setWorkerProvinceId(value?.id);
                        setWorkerProvince(value?.nameTh);
                        const _districtOptions = districtData.filter((district: any) => district?.province?.id === value?.id
                        );
                        setDistrictOptions(_districtOptions);
                      }
                    }}
                    disablePortal
                    options={provinceData}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label='จังหวัด' inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 7);
                      }} />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={workerDistrict}
                    id='district'
                    onChange={(e: any, value: any) => {
                      setWorkerDistrictId(value);
                      setWorkerDistrict(value?.nameTh);
                      if (value?.id) {
                        const _subDistrictOptions = subDistrictData.filter((subDistrict: any) => subDistrict?.amphur?.id === value?.id);
                        setSubDistrictOptions(_subDistrictOptions);
                      }
                    }}
                    disablePortal
                    options={districtOptions}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label='อำเภอ' inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 8);
                      }} />}
                    disabled={(districtOptions.length > 0 || workerDistrict !== '') ? false : true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={workerSubDistrict ?? ''}
                    id='district'
                    onChange={(e: any, value: any) => {
                      if (value?.id) {
                        setWorkerSubDistrictId(value?.id);
                        setWorkerSubDistrict(value?.nameTh);
                      }
                      if (value?.zipCode) { setWorkerZipcode(value?.zipCode); }
                    }}
                    disablePortal
                    options={subDistrictOptions}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label='ตำบล' inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 9);
                      }} />}
                    disabled={(subDistrictOptions.length > 0 || workerSubDistrict !== '') ? false : true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField label='รหัสไปรษณีย์' variant='outlined' fullWidth value={workerZipcode} onChange={(e) => setWorkerZipcode(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 10);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label='เบอร์โทร' variant='outlined' fullWidth value={workerTel} onChange={(e) => setWorkerTel(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 11);
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography>ข้อมูลบ้าน</Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>โครงการ</InputLabel>
                    <Select
                      value={project}
                      label='โครงการ'
                      onChange={handleChangeProject}
                      inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 16);
                      }}
                    >
                      <MenuItem value={'all'}>กรุณาเลือกโครงการ</MenuItem>
                      {projectData && projectData.length > 0 && projectData?.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>บ้าน</InputLabel>
                    <Select
                      disabled={project === 'all' ? true : false}
                      value={house}
                      label='บ้าน'
                      onChange={handleChangeHouse}
                      inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 17);
                      }}
                    >
                      {houseDataFilter?.length > 0 && houseDataFilter?.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                      {houseDataFilter?.length === 0 && houseData?.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}

                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography>ข้อมูลที่ดิน</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography><b>เลขที่โฉนด: </b>{landData?.deedNo ?? '-'}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography><b>เนื้อที่: </b>{landData?.rai ? parseFloat(landData?.rai ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-'} ไร่ {landData?.ngan ? parseFloat(landData?.ngan).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-'} งาน {landData?.squareWah ? parseFloat(landData?.squareWah ?? 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-'} ตร.วา</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography><b>ระวาง: </b>{landData?.mapsheet ?? '-'} </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography><b>เลขที่ดิน: </b>{landData?.parcelNo ?? '-'} </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography><b>หน้าสำรวจ: </b>{landData?.dealingFileNo ?? '-'} </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography><b>ที่อยู่: </b>ต.{landData?.landSubDistrict?.nameTh ?? '-'}  อ.{landData?.landDistrict?.nameTh ?? '-'} จ.{landData?.landProvince?.nameTh ?? '-'} {landData?.landSubDistrict?.zipCode ?? ''}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography><b>หน้าสำรวจ: </b>{landData?.mapsheet ?? '-'} </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>ข้อมูลสัญญา</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField label='จำนวนเงิน' variant='outlined' type='number' fullWidth value={grandTotal ? parseFloat(grandTotal) : ''} onChange={(e: any) => setGrandTotal(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 14);
                    }} />
                </Grid>
                <Grid item xs={12} display={'flex'} alignItems={'center'}>
                  <Typography>ข้อมูลงวด</Typography>
                  <IconButton onClick={handleAddItem}>
                    <AddCircleIcon color='primary' />
                  </IconButton>
                </Grid>
                {period.length > 0 && period.map((item: any, index: number) => (
                  <>
                    <Grid item xs={0.5} mb={1.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                      <IconButton aria-label={`delete-${item.id}`} size='small' color='error' onClick={() => handleRemoveItem(period[index].id)}>
                        <RemoveCircleIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <TextField label='งวดที่' variant='outlined' type='number' fullWidth value={item?.order}
                        onChange={(e) => {
                          const newItems = [...period];
                          newItems[index].order = e.target.value;
                          setPeriod(newItems);
                        }} />
                    </Grid>
                    <Grid item xs={4.5} >
                      <TextField label='รายละเอียด' variant='outlined' fullWidth value={item?.description} onChange={(e) => {
                        const newItems = [...period];
                        newItems[index].description = e.target.value;
                        setPeriod(newItems);
                      }} />
                    </Grid>
                    <Grid item xs={3} >
                      <TextField label='จำนวนเงิน (บาท)' type='number' variant='outlined' fullWidth value={item?.total ? parseFloat(item?.total) : ''} onChange={(e) => {
                        const newItems = [...period];
                        newItems[index].total = e.target.value;
                        setPeriod(newItems);
                      }} />
                    </Grid>
                    <Grid item xs={3} >
                      <TextField label='หมายเหตุ' variant='outlined' fullWidth value={item?.remark} onChange={(e) => {
                        const newItems = [...period];
                        newItems[index].remark = e.target.value;
                        setPeriod(newItems);
                      }} />
                    </Grid>
                  </>
                ))}
                <Grid item xs={12}>
                  <TextField label='หมายเหตุ' variant='outlined' fullWidth multiline rows={4} value={remark} onChange={(e) => setRemark(e.target.value)} />
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12}>
                  <DraftContract title={titleContract}
                    contractTypeData={contractTypeData}
                    content={content}
                    onSelected={(title: any, content: any, isSaveTemplate: any) => {
                      setIsSaveTemplate(isSaveTemplate);
                      setContentContract(content);
                      setContent(content);
                      setTitleContract(title);
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} >
                      <Stack spacing={2} direction='row'>
                        <Button variant='outlined' fullWidth onClick={() => navigate('/contracts')}>ยกเลิก</Button>
                        {!id && <Button variant='contained' fullWidth onClick={() => {
                          setOpenConfirm({
                            open: true,
                            title: 'บันทึกเอกสาร',
                            message: 'คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่',
                            color: 'primary',
                            type: 'create'
                          });
                        }}>บันทึก</Button>}
                        {((permission && permission.edit) || authStore.user?.isSuperAdmin) && id && <Button variant='contained' fullWidth onClick={() => {
                          setOpenConfirm({
                            open: true,
                            title: 'แก้ไขเอกสาร',
                            message: 'คุณต้องการแก้ไขเอกสารนี้ใช่หรือไม่',
                            color: 'primary',
                            type: 'edit'
                          });
                        }}>แก้ไข</Button>}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogCreateWorker name={dialogCreateWorkerValue} open={openAddWorkerDialog}
        onClose={() => {
          setOpenAddWorkerDialog(false);
        }}
        onSave={(success) => {
          if (success) {
            workersStore.getAll().then((res) => {
              setWorkersList(res);
            });
          }
          setOpenAddWorkerDialog(false);
        }} />
      <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === 'delete') {
            handleDelete();
          } else if (openConfirm.type === 'create') {
            handleSave();
          } else if (openConfirm.type === 'edit') {
            handleEdit();
          }
        }} />
      <DialogConfirm open={openConfirmTemplate.open} type={openConfirmTemplate.color} title={openConfirmTemplate.title} message={openConfirmTemplate.message} onClose={() => setOpenConfirmTemplate({ ...openConfirmTemplate, open: false })}
        onSubmit={() => {
          setOpenConfirmTemplate({ ...openConfirmTemplate, open: false });
          if (openConfirmTemplate.type === 'create') {
            handleSaveTemplate();
          }
        }} />
      <Box style={{ display: 'none' }}>
        <PrintOneContract
          ref={printRef}
          title={title}
          data={content}
          companyInfo={companyInfo}
          type={contractType}
          employerTypes={employerTypes}
          employer={{ employerName, employerAddress, employerMoo, employerTel, employerAlley, employerStreet, employerProvince, employerDistrict, employerSubDistrict, employerZipcode }}
        />
      </Box>
    </Box>
  );
});

export default ContractCreate;
