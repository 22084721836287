import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { } from "react";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface PrintReceiptProps {
  title?: string;
  date?: any;
  data: any[];
  company?: any;
  customer?: any;
  paymentType?: string;
  grandTotal?: number;
}
const PrintReceipts = React.forwardRef((props: PrintReceiptProps, ref) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  return (
    <Box
      ref={ref}
      sx={{
        margin: 0,
        width: "100%",
        borderCollapse: "collapse",
        textAlign: "center",
        paddingBottom: "20px",
        '@page': {
          size: "A4 portrait", // Default page size is portrait
        },
        overflow: "hidden",
        fontSize: 8,
        display: "block",
        pageBreakBefore: "auto", // Allow page break before this element 
        pageBreakAfter: "auto", // Allow page break before this element 
        '@media print': {
          '@page': {
            size: "A4 portrait", // Subsequent pages are landscape
            margin: "60px",
          },
          footer: {
            display: 'none'
          }
          , header: {
            display: 'none'
          }
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer >
            <Table sx={{ minWidth: 650, overflow: 'hidden' }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5} sx={{ textAlign: 'center', borderBottom: 0 }}>
                    <Typography fontWeight={'bold'}>
                      {props?.company?.images?.name && <img src={`${apiUrl}/uploads/${props?.company?.images?.name}`} width={100} alt="logo" />}</Typography>
                    <Typography>{props.company?.name}</Typography>
                    <Typography>{props.company?.address} หมู่ที่ {props.company?.moo} ตำบล{props.company?.subDistrict?.nameTh} อำเภอ{props.company?.district?.nameTh} จังหวัด{props.company?.province?.nameTh}</Typography>
                    <Typography>เลขประจำตัวผู้เสียภาษี {props.company?.taxId}</Typography>
                    <Typography>Tell. {props.company?.tel}</Typography>
                    <Typography>ใบเสร็จรับเงิน</Typography>
                    <Typography textAlign={'end'}>วันที่ชำระเงิน {dayjs(props.date).format('DD/MM/YYYY')}</Typography>
                    <Typography textAlign={'start'}>{props.customer?.firstname} {props.customer?.lastname}</Typography>
                    <Typography textAlign={'start'}>{props.customer?.address} หมู่ที่ {props.customer?.moo} ตำบล{props.customer?.subDistrict?.nameTh} อำเภอ{props.customer?.district?.nameTh} จังหวัด{props.customer?.province?.nameTh}</Typography>
                    <Typography textAlign={'start'}>เลขประจำตัวผู้เสียภาษี {props.customer?.taxId}</Typography>
                    <Typography textAlign={'start'}>Tell. {props.customer?.tel}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ ' td,  th': { border: 1 } }}>
                  <TableCell sx={{ textAlign: 'center' }} >ลำดับ</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>รายการ</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>จำนวน</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>ราคาต่อหน่วย</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>จำนวนเงิน</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props?.data?.length > 0 && props?.data?.map((row: any, index: number) => (
                  <TableRow
                    key={row.id} sx={{ ' td,  th': { border: 1 } }}
                  >
                    <TableCell sx={{ textAlign: 'center' }}>{index + 1}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{(row?.name) || ''} </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{parseFloat(row?.quantity || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || ''}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{parseFloat(row?.unit || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || ''}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>{parseFloat(row?.total || 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || ''}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4} sx={{ borderBottom: 1, textAlign: 'center', borderLeft: 1, borderRight: 1, borderTop: 1, fontWeight: 'bold' }}>รวม </TableCell>
                  <TableCell sx={{ borderBottom: 1, textAlign: 'right', borderLeft: 1, borderRight: 1, borderTop: 1 }}>{parseFloat((props?.grandTotal || 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) || ''}</TableCell>
                </TableRow>
                <TableRow sx={{ ' td,  th': { border: 0 } }}>
                  <TableCell colSpan={5} ><Box display={'flex'} alignItems={'center'}>{props?.paymentType === 'transfer' ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />} &nbsp;โอน</Box>
                    <Box display={'flex'} alignItems={'center'}>{props?.paymentType === 'cash' ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />} &nbsp;เงินสด
                    </Box></TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={5} align="center">ผู้รับเงิน.........................................................................</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box >
  );
});

export default PrintReceipts;





