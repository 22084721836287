import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React from "react";

interface ExportFileExpensesProjectLandTaxProps {
  title?: string;
  data: any[];
  total?: string;
}

const ExportFileExpensesProjectLandTax = React.forwardRef(
  (props: ExportFileExpensesProjectLandTaxProps, ref) => {
    return (
      <Box
        ref={ref}
        sx={{ textAlign: "center", padding: "20px", "&@page": { size: "A4" } }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 650, overflow: "hidden" }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                      <Typography fontWeight={"bold"}>{props.title}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>วันที่จ่ายภาษี</TableCell>
                    <TableCell>บ้าน</TableCell>
                    <TableCell>มูลค่ารวม</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props?.data?.length > 0 &&
                    props?.data?.map((row: any, index: number) => (
                      <TableRow
                        key={`landTax-project-detail-${row.id}-project-detail-${index}-project-detail`}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>
                          {dayjs(row?.taxDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell
                          scope="row"
                          style={{
                            textDecoration: !row?.land?.house?.isActive
                              ? "line-through"
                              : "none",
                          }}
                        >
                          {row?.land?.house?.name ?? ""}
                        </TableCell>
                        <TableCell>
                          {parseFloat(row?.grandTotalTax ?? 0).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  {props.data.length === 0 && (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell colSpan={4} align="center">
                        ไม่พบข้อมูล
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={2} align="right">
                      <b>จำนวนเงินรวมทั้งสิ้น</b>
                    </TableCell>
                    <TableCell align="center">
                      {parseFloat(
                        (props?.total || 0).toString()
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default ExportFileExpensesProjectLandTax;
