import { Box, Breadcrumbs, Button, Card, CardContent, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import DialogDisplayImage from "../../components/DialogDisplayImage";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import VisuallyHiddenInput from "../../components/VisuallyHiddenInput";
import authStore from "../../stores/AuthStore";
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useNavigate } from "react-router-dom";
import ImageIcon from '@mui/icons-material/Image';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import cashflowStore from "../../stores/CashflowStore";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SaveIcon from '@mui/icons-material/Save';
import { enqueueSnackbar } from "notistack";
import imagesStore from "../../stores/ImagesStore";
import PrintCashflow from "../../components/Report/Pdf/PrintCashflow";
import ExportFileCashflow from "../../components/Report/Excel/ExportFileCashflow";


const Cashflow: React.FC = () => {
    const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/cashflow');
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [dateTypes, setDateType] = useState('createDate');
    const [description, setDescription] = useState('');
    const [docType, setDocType] = useState('all');
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [openDisplayImageDialog, setOpenDisplayImageDialog] = useState({
        open: false,
        images: [],
    });
    const [selectedFile, setSelectedFile] = useState<any>();
    const [rowSelectImage, setRowSelectImage] = useState<string>('');
    const [reload, setReload] = useState<boolean>(false);
    const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs().startOf('month'));
    const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs());
    const printRef = useRef(null);
    const reactToPrintFn = useReactToPrint({ contentRef: printRef });
    const exportRef = useRef(null);
    const navigate = useNavigate();
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [totalIncomes, setTotalIncomes] = useState(0);
    const [sortType, setSortType] = useState({ name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" });
    const [sortValue, setSortValue] = useState("createAtDESC");
    const sortOption = [
        { name: "วันที่บันทึก: ล่าสุด", variable: "createAtDESC", sortBy: "createAt", sortType: "DESC" },
        { name: "วันที่บันทึก: เก่าสุด", variable: "createAtASC", sortBy: "createAt", sortType: "ASC" },
        { name: "วันที่เอกสาร: ล่าสุด", variable: "docDateDESC", sortBy: "docDate", sortType: "DESC" },
        { name: "วันที่เอกสาร: เก่าสุด", variable: "docDateASC", sortBy: "docDate", sortType: "ASC" },
        { name: "วันที่แก้ไข: ล่าสุด", variable: "updateAtDESC", sortBy: "updateAt", sortType: "DESC" },
        { name: "วันที่แก้ไข: เก่าสุด", variable: "updateAtASC", sortBy: "updateAt", sortType: "ASC" },
    ];
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const files = event.target.files;
        if (files && files?.length > 0 && id) {
            setRowSelectImage(id);
            setSelectedFile(files[0]);
        }
    };

    const handleFileUpload = async (id: string) => {
        setReload(false);
        const formData = new FormData();
        formData.append("refType", "bill");
        formData.append("refId", id);
        formData.append('file', selectedFile);
        formData.append('updateBy', authStore.user?.id);
        formData.append('createBy', authStore.user?.id);
        formData.append("createAt", new Date().toString());
        formData.append("updateAt", new Date().toString());
        await imagesStore.upload(formData)
            .then((response) => {
                if (response.error) {
                    enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
                    return;
                }
                setSelectedFile(null);
                setReload(true);
                enqueueSnackbar('บันทึกข้อมูลสำเร็จ', { variant: 'success' });
            }).catch((error) => {
                enqueueSnackbar('บันทึกข้อมูลไม่สำเร็จ', { variant: 'error' });
                console.error(error);
                setIsLoading(false);
            });
    };

    const handleChangeDateType = (event: ChangeEvent<HTMLInputElement>) => {
        setDateType((event.target as HTMLInputElement).value);
    };
    const handleChange = (event: SelectChangeEvent) => {
        setDocType(event.target.value);
    };

    const handleSearch = (pageNumber?: string, limitNumber?: string, dateF?: Dayjs, dateT?: Dayjs, _sortType?: string, _docType?: string, isRefresh = false) => {
        setIsLoading(true);
        setRows([]);
        const _page = parseInt(pageNumber || '0');
        const _limit = parseInt(limitNumber || '0');
        // Set date to local storage
        localStorage.setItem('dateFrom', JSON.stringify(dateF ? dateF.format('YYYY-MM-DD') : dateFrom?.format('YYYY-MM-DD')));
        localStorage.setItem('dateTo', JSON.stringify(dateT ? dateT.format('YYYY-MM-DD') : dateTo?.format('YYYY-MM-DD')));
        cashflowStore.getByCriteria({
            dateFrom: dateF ? dateF.format('YYYY-MM-DD') : dateFrom?.format('YYYY-MM-DD'),
            dateTo: dateT ? dateT.format('YYYY-MM-DD') : dateTo?.format('YYYY-MM-DD'),
            dateType: dateTypes,
            description: description,
            page: _page,
            limit: _limit,
            docType: docType !== 'all' ? docType : '',
            sortType: _sortType ? _sortType : sortType,
        }).then((response) => {
            const data = response.data;
            setTotal(response.total);
            if ((response?.total && response.total <= 10) || isRefresh === true) {
                setPage(0);
                setLimit(10);
            };
            setRows(data);
            setTotalExpenses(response.totalExpenses);
            setTotalIncomes(response.totalIncomes);
            const _sortType: any = localStorage.getItem('sortType');
            const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
            setSortType(_sortTypeConvert || sortType);
            setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
            if (!_sortType) {
                localStorage.setItem('sortType', JSON.stringify(sortType));
            }
            setIsLoading(false);
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        });
    };

    const handleChangeSort = (e: any) => {
        const findSortType: any = sortOption.find((item: any) => item.variable === e.target.value);
        localStorage.setItem('sortType', JSON.stringify(findSortType));
        setSortType(findSortType);
        setSortValue(e.target.value);
    };

    window.addEventListener("beforeunload", function (event) {
        localStorage.removeItem('dateFrom');
        localStorage.removeItem('dateTo');
        localStorage.removeItem('sortType');
        localStorage.removeItem('docType');
        localStorage.removeItem('limit');
        localStorage.removeItem('page');
    });

    useEffect(() => {
        const _dateFrom = localStorage.getItem('dateFrom');
        const _dateTo = localStorage.getItem('dateTo');
        const dateF = dayjs(_dateFrom ? _dateFrom : dateFrom);
        const dateT = dayjs(_dateTo ? _dateTo : dateTo);
        const _limit = localStorage.getItem('limit');
        const _page = localStorage.getItem('page');
        setDateFrom(dateF);
        setDateTo(dateT);
        setLimit(_limit ? parseInt(_limit) : limit);
        setPage(_page ? parseInt(_page) : page);
        const _sortType: any = localStorage.getItem('sortType');
        const _sortTypeConvert: any = _sortType ? JSON.parse(_sortType) : sortType;
        const _docType: any = localStorage.getItem('docType');
        setDateType(_docType || 'all');
        setSortType(_sortTypeConvert || sortType);
        setSortValue(_sortTypeConvert?.variable || 'createAtDESC');
        if (!_sortType) {
            localStorage.setItem('sortType', JSON.stringify(sortType));
        }
        if (!_limit) {
            localStorage.setItem('limit', '10');
        }
        if (!_page) {
            localStorage.setItem('page', '0');
        }
        if (!_docType) {
            localStorage.setItem('docType', 'all');
        }
        handleSearch(_page || undefined, _limit || undefined, dateF, dateT, _sortTypeConvert, _docType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    return (
        <Box component={'div'}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Typography>บันทึกค่าใช้จ่าย</Typography>
                <Typography variant="h6" color="text.primary">กระแสเงินสด</Typography>
            </Breadcrumbs>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2} alignItems={'end'}>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={dateTypes}
                                            onChange={handleChangeDateType}
                                        >
                                            <FormControlLabel value="createDate" control={<Radio />} label="วันที่บันทึก" />
                                            <FormControlLabel value="updateDate" control={<Radio />} label="วันที่แก้ไข" />
                                            <FormControlLabel value="cashflowDate" control={<Radio />} label="วันที่รับ/จ่าย" />
                                        </RadioGroup>
                                    </FormControl>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']} >
                                            <DatePicker
                                                label="ตั้งแต่วันที่"
                                                value={dateFrom}
                                                onChange={(newValue) => {
                                                    setDateFrom(newValue);
                                                    localStorage.setItem('dateFrom', newValue?.format('YYYY-MM-DD') || '');
                                                }}
                                            />
                                            <DatePicker
                                                label="ถึงวันที่"
                                                value={dateTo}
                                                onChange={(newValue) => {
                                                    setDateTo(newValue);
                                                    localStorage.setItem('dateTo', newValue?.format('YYYY-MM-DD') || '');
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField id="outlined-basic" label="รายละเอียด" variant="outlined" fullWidth
                                        onChange={(event) => {
                                            setDescription(event.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2.5}>
                                    <FormControl fullWidth >
                                        <InputLabel id="demo-simple-select-helper-label" >ประเภท</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="xประเภท"
                                            value={docType}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={"all"} >
                                                ทั้งหมด
                                            </MenuItem>
                                            <MenuItem value={'received'}>รายรับ</MenuItem>
                                            <MenuItem value={'paid'}>รายจ่าย</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">เรียงตาม
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={sortValue}
                                            label="sortType"
                                            onChange={handleChangeSort}
                                        >
                                            {sortOption.map((option: any, index: number) => (
                                                <MenuItem key={`sort-option-${index}`} value={option.variable}>{option.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} textAlign={'end'} pb={0.5}>
                                    <Button variant="contained"
                                        onClick={() => {
                                            handleSearch(undefined, undefined, undefined, undefined, undefined, undefined, true);
                                        }}
                                        startIcon={<SearchIcon />}>ค้นหา</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container >
                                        <Grid item xs={9} display={'flex'}  >
                                            <Typography>ทั้งหมด {total?.toLocaleString()} รายการ</Typography>
                                            <Typography ml={2}>รายรับทั้งหมด <Box component={'span'} sx={{ color: 'success.main' }}>{totalIncomes?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Box> บาท</Typography>
                                            <Typography ml={2}>รายจ่ายทั้งหมด <Box component={'span'} sx={{ color: 'error.main' }}>{totalExpenses?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Box> บาท</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <DownloadTableExcel
                                                filename={`cashflow-${dayjs(dateFrom).format('DD-MM-YYYY')} - ${dayjs(dateTo).format('DD-MM-YYYY')}`}
                                                sheet={`cashflow-bill-${dayjs(dateFrom).format('DD-MM-YYYY')} - ${dayjs(dateTo).format('DD-MM-YYYY')}`}
                                                currentTableRef={exportRef.current}
                                            >
                                                <IconButton aria-label="download"  >
                                                    <DownloadIcon />
                                                </IconButton>
                                            </DownloadTableExcel>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton aria-label="print" onClick={() => reactToPrintFn()}>
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1}>
                                            {((permission && permission.create) || authStore.user?.isSuperAdmin) && <Button variant="contained" startIcon={<NoteAddIcon />}
                                                onClick={() => navigate('/cashflow-create')}
                                            >สร้าง</Button>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }}>
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell width={100}>วันที่เอกสาร</TableCell>
                                                    <TableCell width={300}>รายละเอียด</TableCell>
                                                    <TableCell width={100} align="right">รายรับ</TableCell>
                                                    <TableCell width={100} align="right">รายจ่าย</TableCell>
                                                    <TableCell width={100}>บันทึก</TableCell>
                                                    <TableCell width={100}>แก้ไข</TableCell>
                                                    <TableCell width={80}></TableCell>
                                                    <TableCell width={80}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows && rows?.length > 0 && rows.map((row: any, index: number) => (
                                                    <TableRow
                                                        key={`cashflow-row-${index}`}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {dayjs(row.docDate).format('DD/MM/YYYY')}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.cashflowDetail?.sort((a: any, b: any) => dayjs(a.createAt).diff(b.createAt)).map((cashflowDetail: any, index: number) => {
                                                                return <span key={cashflowDetail.id}>{`${cashflowDetail.description || ''}${index < row?.cashflowDetail?.length - 1 ? ', ' : ''} `}</span>;

                                                            })}</TableCell>
                                                        <TableCell align="right" sx={{ color: 'success.main' }}>{row.docType === 'received' ? parseFloat(row.total)?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ''}
                                                        </TableCell>
                                                        <TableCell align="right" sx={{ color: 'error.main' }}>{row.docType === 'paid' ? parseFloat(row.total)?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            {<b>{row.createBy?.name}</b>} {dayjs(row.createAt).format('DD/MM/YYYY HH:mm')}
                                                        </TableCell>
                                                        <TableCell>
                                                            {<b>{row.updateBy?.name}</b>} {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                row.images && row.images?.length > 0 &&
                                                                <IconButton color="warning" onClick={() => {
                                                                    setOpenDisplayImageDialog({ open: true, images: row.images });
                                                                }}>
                                                                    <ImageIcon />
                                                                </IconButton>
                                                            }
                                                            {
                                                                row.images && row.images?.length < 1 && rowSelectImage !== row.id &&
                                                                <IconButton component="label"><UploadFileIcon />
                                                                    <VisuallyHiddenInput type="file" accept=".jpg, .jpeg, .png" onChange={(e) => {
                                                                        handleFileChange(e, row.id);
                                                                    }}
                                                                    />
                                                                </IconButton>
                                                            }
                                                            {
                                                                (selectedFile && row.images?.length < 1 && rowSelectImage === row.id) &&
                                                                <IconButton component="label" color="primary" onClick={() => handleFileUpload(row.id)
                                                                }>
                                                                    <SaveIcon />
                                                                </IconButton>
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <IconButton aria-label="edit" onClick={() => navigate(`/cashflow-edit/${row.id}`)}>
                                                                <KeyboardArrowRightIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                {rows?.length === 0 && (
                                                    <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell colSpan={10} align="center">ไม่พบข้อมูล</TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box mt={3}>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                                            count={total}
                                            onPageChange={(e, newPage) => {
                                                setPage(newPage);
                                                handleSearch(newPage.toString());
                                                localStorage.setItem('page', newPage.toString());
                                            }}
                                            page={page}
                                            rowsPerPage={limit ? limit : 10}
                                            onRowsPerPageChange={(e: any) => {
                                                setRows([]);
                                                setLimit(e.target.value);
                                                setPage(0);
                                                handleSearch('0', e.target.value.toString());
                                                localStorage.setItem('limit', e.target.value.toString());
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <SimpleBackdrop open={isLoading} />
            <DialogDisplayImage
                open={openDisplayImageDialog.open}
                onClose={() => setOpenDisplayImageDialog({ open: false, images: [] })}
                images={openDisplayImageDialog.images}
            />
            <Box style={{ display: "none" }}>
                {<PrintCashflow ref={printRef} title="รายรับ/รายจ่าย" date={`${dayjs(dateFrom).format('DD/MM/YYYY')} - ${dayjs(dateTo).format('DD/MM/YYYY')}`} data={rows ?? []}
                />}
            </Box>
            <Box style={{ display: "none" }}>
                {<ExportFileCashflow ref={exportRef} title="รายรับ/รายจ่าย" date={`${dayjs(dateFrom).format('DD/MM/YYYY')} - ${dayjs(dateTo).format('DD/MM/YYYY')}`} data={rows ?? []} />}
            </Box>
        </Box >
    );
};

export default Cashflow;
