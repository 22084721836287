import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Breadcrumbs, Grid, IconButton, Typography, Link, Button, ButtonProps, Card, CardContent, TextField, Autocomplete, Divider, Stack, FormControl, RadioGroup, Radio, FormControlLabel, FormLabel } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import SimpleBackdrop from '../../components/SimpleBackdrop';
import companyStore from '../../stores/CompanyStore';
import authStore from '../../stores/AuthStore';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/th';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import masterDataStore from '../../stores/MasterDataStore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { v4 as uuid4 } from 'uuid';
import DialogConfirm from '../../components/DialogConfirm';
import customerStore from '../../stores/CustomerStore';
import receiptStore from '../../stores/ReceiptStore';
import PrintReceipts from '../../components/Report/Pdf/PrintReceipt';

const ReceiptCreate: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [docDate, setDocDate] = useState<Dayjs | null>(dayjs());
  const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/receipts');
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [customerValue, setCustomerValue] = useState<any>([]);
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [customerAddress, setCustomerAddress] = useState('');
  const [customerMoo, setCustomerMoo] = useState('');
  const [customerAlley, setCustomerAlley] = useState('');
  const [customerStreet, setCustomerStreet] = useState('');
  const [customerProvince, setCustomerProvince] = useState('');
  const [customerDistrict, setCustomerDistrict] = useState('');
  const [customerSubDistrict, setCustomerSubDistrict] = useState('');
  const [customerProvinceId, setCustomerProvinceId] = useState('');
  const [customerDistrictId, setCustomerDistrictId] = useState('');
  const [customerSubDistrictId, setCustomerSubDistrictId] = useState('');
  const [customerZipcode, setCustomerZipcode] = useState('');
  const [customerTel, setCustomerTel] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [provinceData, setProvinceData] = useState<any>([]);
  const [districtData, setDistrictData] = useState<any>([]);
  const [subDistrictData, setSubDistrictData] = useState<any[]>([]);
  const [districtOptions, setDistrictOptions] = useState<any>([]);
  const [subDistrictOptions, setSubDistrictOptions] = useState<any>([]);
  const [receiptDetailOld, setReceiptDetailOld] = useState<any[]>([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [paymentType, setPaymentType] = useState('');
  const [customerTaxId, setCustomerTaxId] = useState('');
  const [receiptDetail, setReceiptDetail] = useState<any[]>([{ id: uuid4(), name: '', quantity: 0, unit: 0, total: 0, isNew: true }]);
  const [company, setCompany] = useState<any>({});
  const printRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: '',
    message: '',
    color: 'primary' as ButtonProps['color'],
    type: 'create' as 'create' | 'edit' | 'delete' | 'confirm-duplicate'
  });

  const addRef = (el: any) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };
  const handleKeyDown = (event: any, index: number, i = 0) => {
    const currentInput = event.target;
    if (event.key === "Enter") {
      const currentIndex = Array.from(inputRefs.current).indexOf(currentInput);
      if (i === -1) {
        inputRefs.current[10].focus();
      }
      else if (index === 8 && i === 0) {

        if (inputRefs.current[11]) {
          inputRefs.current[11].focus();
        }
        else {
          const nextIndex = currentIndex + 1;
          inputRefs.current[nextIndex].focus();
        }

      } else {
        const nextIndex = currentIndex + 1;
        if (nextIndex < inputRefs.current.length) {
          inputRefs.current[nextIndex].focus();
          event.preventDefault();
        }
      }
    }
  };

  const handleRemoveItem = (id: string) => {
    setReceiptDetail(receiptDetail.filter((item) => item.id !== id));
  };

  const handleAddItem = () => {
    setReceiptDetail([...receiptDetail, { id: uuid4(), name: '', quantity: 0, unit: 0, total: 0, isNew: true }]);
  };
  const handleSave = () => {
    try {
      setIsLoading(true);
      customerStore.update(customerValue?.id, {
        address: customerAddress,
        alley: customerAlley,
        street: customerStreet,
        subDistrict: customerSubDistrictId !== "" ? customerSubDistrictId : null,
        province: customerProvinceId !== "" ? customerProvinceId : null,
        district: customerDistrictId !== "" ? customerDistrictId : null,
        zipCode: customerZipcode,
        tel: customerTel,
        taxId: customerTaxId
      });
      receiptStore.create({
        payment: paymentType,
        docDate: docDate,
        customer: customerValue?.id,
        createBy: authStore.user?.id,
        createAt: new Date(),
        total: grandTotal,
      }).then((res) => {
        if (res.error) {
          console.error("err", res.error);
        }
        if (receiptDetail && receiptDetail?.length > 0) {
          const _receiptDetail = receiptDetail.map((detail) => {
            return { ...detail, receipt: res.id };
          });
          receiptStore.createReceiptDetails(_receiptDetail);
        }
        navigate(`/receipt-edit/${res.id}`);
      });
      setIsLoading(false);

    } catch (err) {
      console.error(err);
    }
  };

  const handleEdit = () => {
    try {
      if (id) {
        setIsLoading(true);
        customerStore.update(customerValue?.id, {
          address: customerAddress,
          alley: customerAlley,
          street: customerStreet,
          subDistrict: customerSubDistrictId !== "" ? customerSubDistrictId : null,
          province: customerProvinceId !== "" ? customerProvinceId : null,
          district: customerDistrictId !== "" ? customerDistrictId : null,
          zipCode: customerZipcode,
          tel: customerTel,
          taxId: customerTaxId
        });
        receiptStore.update(id, {
          payment: paymentType,
          docDate: docDate,
          customer: customerValue?.id,
          createBy: authStore.user?.id,
          createAt: new Date(),
          total: grandTotal,
        });
        if (receiptDetailOld && receiptDetailOld?.length > 0) {
          receiptDetailOld.forEach((detail: any) => {
            handleDeleteDetail(detail.id);
          });
        }
        if (receiptDetail && receiptDetail?.length > 0) {
          const _receiptDetail = receiptDetail.map((detail: any) => {
            delete detail.id;
            return { ...detail, receipt: id };
          });
          receiptStore.createReceiptDetails(_receiptDetail);
        }
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = () => {
    try {
      if (id) {
        setIsLoading(true);
        receiptStore.update(id, {
          isDeleted: true,
          updateBy: authStore.user?.id,
          updateAt: new Date(),
        });
        navigate('/receipts');
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteDetail = (detailId: string) => {
    try {
      receiptStore.deletereceiptDetails(detailId);
    } catch (err) {
      console.error(err);
    }
  };
  const handleChangeCustomer = (newValue: any) => {
    setCustomerValue(newValue);
    setCustomerAddress(newValue?.address);
    setCustomerMoo(newValue?.moo);
    setCustomerAlley(newValue?.alley);
    setCustomerStreet(newValue?.street);
    setCustomerDistrict(newValue?.district);
    setCustomerSubDistrict(newValue?.subDistrict);
    setCustomerProvince(newValue?.province);
    setCustomerZipcode(newValue?.zipCode);
    setCustomerTel(newValue?.tel);
    setCustomerTaxId(newValue?.taxId);
  };

  useEffect(() => {
    try {
      Promise.all([
        customerStore.getAll(),
        masterDataStore.getAllProvince(),
        masterDataStore.getAllAmphur(),
        masterDataStore.getAllTambon(),
        companyStore.getAll(),
      ]).then((res: any) => {
        setCustomerList(res[0]);
        setProvinceData(res[1]);
        setDistrictData(res[2]);
        setSubDistrictData(res[3]);
        setCompany(res[4][0]);
      });
      if (id) {
        receiptStore.get(id).then((result: any) => {
          if (result.error) {
            console.error(result.error);
          }
          handleChangeCustomer(result.customer);
          setReceiptDetail(result.receiptDetail);
          setReceiptDetailOld(result.receiptDetail);
          setPaymentType(result.payment || '');
          setDocDate(dayjs(!result?.docDate ? new Date() : result?.docDate));
        }).catch((err: any) => {
          console.error(err);
        });
      }
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calculateGrandTotal = () => {
    const sum = receiptDetail.reduce((a: any, b: any) => a + (parseFloat(b.quantity || 0) * parseFloat(b.unit || 0)), 0);
    setGrandTotal(sum);
  };

  const calculateTotal = (index: any) => {
    const sum = parseFloat(receiptDetail[index]?.quantity || 0) * parseFloat(receiptDetail[index].unit || 0);
    receiptDetail[index].total = sum;
  };

  useMemo(() => {
    calculateGrandTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiptDetail]);
  return (
    <Box component={'div'}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton aria-label='delete' onClick={() => navigate('/receipts')}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={'flex'} alignItems={'center'}>
          <Breadcrumbs separator='›' aria-label='breadcrumb'>
            <Typography>เอกสาร</Typography>
            <Link component='button' onClick={() => navigate('/receipts')}>ใบเสร็จรับเงิน</Link>
            <Typography variant='h6' color='text.primary'>{id ? 'แก้ไข' : 'สร้าง'}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={1.5} display={'flex'} justifyContent={'end'}>
          <Button variant='outlined' color='success' startIcon={<PrintIcon />} onClick={() => reactToPrintFn()}>
            พิมพ์สัญญา
          </Button>
        </Grid>
        <Grid item xs={1.5} display={'flex'} justifyContent={'end'}>
          {((permission && permission.delete) || authStore.user?.isSuperAdmin) && id &&
            <Button variant='contained' color='error' startIcon={<DeleteIcon />} onClick={() => setOpenConfirm({
              open: true,
              title: 'ลบเอกสาร',
              message: 'คุณต้องการลบเอกสารนี้ใช่หรือไม่',
              color: 'error',
              type: 'delete'
            })}>ลบเอกสาร</Button>
          }
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4} >
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'} >
                    <DatePicker
                      label='วันที่เอกสาร'
                      value={docDate}
                      onChange={(newValue) => setDocDate(newValue)}
                      sx={{ width: '100%' }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <Typography>ข้อมูลลูกค้า</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={customerValue}
                    disablePortal
                    sx={{ width: '100%' }}
                    onChange={async (event, newValue) => {
                      handleChangeCustomer(newValue);
                    }}
                    id={`item-customer-autocomplete`}
                    options={customerList}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      const name = (option?.firstname || '') + ' ' + (option?.lastname || '');
                      return name;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(props, option) => <li {...props}>{option.firstname} {option.lastname}</li>}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label='ลูกค้า' variant='outlined'
                      inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 0);
                      }}
                    />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField label='ที่อยู่' variant='outlined' fullWidth value={customerAddress} onChange={(e) => setCustomerAddress(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 1);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label='หมู่' variant='outlined' fullWidth multiline value={customerMoo} onChange={(e) => setCustomerMoo(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 2);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label='ตรอก/ซอย' variant='outlined' fullWidth value={customerAlley} onChange={(e) => setCustomerAlley(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 2);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField label='ถนน' variant='outlined' fullWidth value={customerStreet} onChange={(e) => setCustomerStreet(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 6);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={customerProvince}
                    id='province'
                    onChange={(e: any, value: any) => {
                      if (value?.id) {
                        setCustomerProvinceId(value?.id);
                        setCustomerProvince(value?.nameTh);
                        const _districtOptions = districtData?.filter((district: any) => district?.province?.id === value?.id
                        );
                        setDistrictOptions(_districtOptions);
                      }
                    }}
                    disablePortal
                    options={provinceData}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label='จังหวัด' inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 7);
                      }} />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={customerDistrict}
                    id='district'
                    onChange={(e: any, value: any) => {
                      setCustomerDistrictId(value);
                      setCustomerDistrict(value?.nameTh);
                      if (value?.id) {
                        const _subDistrictOptions = subDistrictData.filter((subDistrict: any) => subDistrict?.amphur?.id === value?.id);
                        setSubDistrictOptions(_subDistrictOptions);
                      }
                    }}
                    disablePortal
                    options={districtOptions}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option?.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label='อำเภอ' inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 8);
                      }} />}
                    disabled={(districtOptions?.length > 0 || customerDistrict !== '') ? false : true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={customerSubDistrict ?? ''}
                    id='district'
                    onChange={(e: any, value: any) => {
                      if (value?.id) {
                        setCustomerSubDistrictId(value?.id);
                        setCustomerSubDistrict(value?.nameTh);
                      }
                      if (value?.zipCode) { setCustomerZipcode(value?.zipCode); }
                    }}
                    disablePortal
                    options={subDistrictOptions}
                    sx={{ width: '100%' }}
                    renderOption={(props: any, option: any) => <li {...props}>{option.nameTh}</li>}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.nameTh;
                    }}
                    renderInput={(params) => <TextField {...params} label='ตำบล' inputRef={addRef}
                      onKeyDown={(e) => {
                        if (e.key === '-') {
                          e.preventDefault();
                        }
                        handleKeyDown(e, 9);
                      }} />}
                    disabled={(subDistrictOptions?.length > 0 || customerSubDistrict !== '') ? false : true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField label='รหัสไปรษณีย์' variant='outlined' fullWidth value={customerZipcode} onChange={(e) => setCustomerZipcode(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 10);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label='เบอร์โทร' variant='outlined' fullWidth value={customerTel} onChange={(e) => setCustomerTel(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 11);
                    }} />
                </Grid>
                <Grid item xs={3}>
                  <TextField label='เลขประจำตัวผู้เสียภาษี' variant='outlined' fullWidth value={customerTaxId} onChange={(e) => setCustomerTaxId(e.target.value)} inputRef={addRef}
                    onKeyDown={(e) => {
                      if (e.key === '-') {
                        e.preventDefault();
                      }
                      handleKeyDown(e, 11);
                    }} />
                </Grid>
                <Grid item xs={12} display={'flex'} alignItems={'center'}>
                  <Typography>ข้อมูลรายการ</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                    </Grid>
                    <Grid item xs={0.5} textAlign={'center'}>
                      <Typography>ลำดับ</Typography>
                    </Grid>
                    <Grid item xs={4.5} textAlign={'center'}>
                      <Typography>รายการ</Typography>
                    </Grid>
                    <Grid item xs={2.25} textAlign={'center'}>
                      <Typography>จำนวน</Typography>
                    </Grid>
                    <Grid item xs={2} textAlign={'center'}>
                      <Typography>ราคาต่อหน่วย</Typography>
                    </Grid>
                    <Grid item xs={2.25} textAlign={'center'}>
                      <Typography>จำนวนเงิน</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {receiptDetail?.length > 0 && receiptDetail.map((item: any, index: number) => (
                  <Fragment key={index}>
                    <Grid item xs={0.5} justifyContent={'center'} alignItems={'center'} display={'flex'}>
                      <IconButton aria-label={`delete-${item.id}`} size='small' color='error' onClick={() => handleRemoveItem(receiptDetail[index].id)}>
                        <RemoveCircleIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={0.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                      <Typography>{index + 1}</Typography>
                    </Grid>
                    <Grid item xs={4.5} >
                      <TextField label='รายการ' variant='outlined' fullWidth value={item?.name} onChange={(e) => {
                        const newItems = [...receiptDetail];
                        newItems[index].name = e.target.value;
                        setReceiptDetail(newItems);
                      }} />
                    </Grid>
                    <Grid item xs={2.25} >
                      <TextField label='จำนวน' type='number' variant='outlined' fullWidth value={item?.quantity ? parseFloat(item?.quantity) : ''} onChange={(e) => {
                        const newItems = [...receiptDetail];
                        newItems[index].quantity = e.target.value;
                        setReceiptDetail(newItems);
                        calculateTotal(index);
                      }} />
                    </Grid>
                    <Grid item xs={2} >
                      <TextField label='ราคาต่อหน่วย' type='number' variant='outlined' fullWidth value={item?.unit ? parseFloat(item?.unit) : ''} onChange={(e) => {
                        const newItems = [...receiptDetail];
                        newItems[index].unit = e.target.value;
                        setReceiptDetail(newItems);
                        calculateTotal(index);
                      }} />
                    </Grid>
                    <Grid item xs={2.25}>
                      <TextField label='จำนวนเงิน' type='number' variant='outlined' fullWidth value={item?.total ? parseFloat(item?.total) : ''} onChange={(e) => {
                        const newItems = [...receiptDetail];
                        newItems[index].total = e.target.value;
                        setReceiptDetail(newItems);
                        calculateTotal(index);
                      }} />
                    </Grid>
                  </Fragment>
                ))}
                <Grid item xs={12}>
                  <Button variant="outlined" sx={{ width: '100%' }} onClick={handleAddItem} startIcon={<AddCircleIcon />} >เพิ่มรายการ</Button>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={8}>
                  <Grid container spacing={2} >
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">ประเภทการจ่าย</FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          value={paymentType}
                          onChange={(e) => setPaymentType(e.target.value)}
                        >
                          <FormControlLabel value="transfer" control={<Radio />} label="โอน" />
                          <FormControlLabel value="cash" control={<Radio />} label="เงินสด" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} >
                      <Typography fontWeight={'bold'}>จำนวนเงินรวมทั้งสิ้น</Typography>
                    </Grid>
                    <Grid item xs={6} >
                      <Typography>{parseFloat((grandTotal || 0).toString()).toLocaleString(undefined, { maximumFractionDigits: 10, minimumFractionDigits: 2 })}</Typography>
                    </Grid>
                    <Grid item xs={12} >
                      <Stack spacing={2} direction='row'>
                        <Button variant='outlined' fullWidth onClick={() => navigate('/contracts')}>ยกเลิก</Button>
                        {!id && <Button variant='contained' fullWidth onClick={() => {
                          setOpenConfirm({
                            open: true,
                            title: 'บันทึกเอกสาร',
                            message: 'คุณต้องการบันทึกเอกสารนี้ใช่หรือไม่',
                            color: 'primary',
                            type: 'create'
                          });
                        }}>บันทึก</Button>}
                        {((permission && permission.edit) || authStore.user?.isSuperAdmin) && id && <Button variant='contained' fullWidth onClick={() => {
                          setOpenConfirm({
                            open: true,
                            title: 'แก้ไขเอกสาร',
                            message: 'คุณต้องการแก้ไขเอกสารนี้ใช่หรือไม่',
                            color: 'primary',
                            type: 'edit'
                          });
                        }}>แก้ไข</Button>}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === 'delete') {
            handleDelete();
          } else if (openConfirm.type === 'create') {
            handleSave();
          } else if (openConfirm.type === 'edit') {
            handleEdit();
          }
        }} />
      <Box style={{ display: 'none' }}>
        <PrintReceipts ref={printRef} data={receiptDetail} company={company} date={docDate} customer={customerValue} paymentType={paymentType} grandTotal={grandTotal} />
      </Box>
    </Box>
  );
};

export default ReceiptCreate;