import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/th";
import PrintProductDetail from "./Report/Pdf/PrintProductDetail";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ExportFileProductDetail from "./Report/Excel/ExportFileProductDetail";
import DialogDisplayImage from "./DialogDisplayImage";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ImageIcon from "@mui/icons-material/Image";

interface DialogProductDetailProps {
  open: boolean;
  title: string;
  total: string;
  data?: any;
  onClose: () => void;
  house: string;
}

const DialogProductDetail = (props: DialogProductDetailProps) => {
  const printRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const exportRef = useRef(null);
  const [openDisplayImage, setOpenDisplayImage] = useState({
    open: false,
    images: [],
    docNo: "",
  });

  return (
    <Dialog open={props.open} fullWidth maxWidth={"md"}>
      <DialogTitle>
        <Grid container>
          <Grid item xs={10}>
            <Box component={"span"} ml={1}>
              {props.title} มูลค่ารวม: {props.total?.toLocaleString()} บาท
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent={"end"}>
                  <Grid item xs={10}>
                    <Typography>
                      <b>รายละเอียด</b> ทั้งหมด{" "}
                      {props.data?.houses?.length.toLocaleString()} รายการ
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <DownloadTableExcel
                      filename={`product-detail-house-${dayjs().format(
                        "DD-MM-YYYY"
                      )}`}
                      sheet={`product-detail-house-${dayjs().format(
                        "DD-MM-YYYY"
                      )}`}
                      currentTableRef={exportRef.current}
                    >
                      <IconButton aria-label="download">
                        <DownloadIcon />xx
                      </IconButton>
                    </DownloadTableExcel>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton aria-label="print" onClick={() => reactToPrintFn()}>
                      <PrintIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>วันที่เอกสาร </TableCell>
                        <TableCell>เลขเอกสาร</TableCell>
                        <TableCell>จำนวน</TableCell>
                        <TableCell>ราคาต่อหน่วย</TableCell>
                        <TableCell>จำนวนเงิน</TableCell>
                        <TableCell width={100} align="center">
                          บันทึก
                        </TableCell>
                        <TableCell width={100} align="center">
                          แก้ไข
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.data?.houses &&
                        props.data?.houses?.length > 0 &&
                        props.data?.houses?.map((item: any) => (
                          <TableRow
                            key={`dialog-product-detail-${item?.id}-table-row`}
                          >
                            <TableCell>
                              {item?.expenseDetail?.expense?.docDate
                                ? dayjs(
                                  item?.expenseDetail?.expense?.docDate
                                ).format("DD/MM/YYYY")
                                : ""}
                            </TableCell>
                            <TableCell>
                              {item?.expenseDetail?.expense?.docNo || ""}
                            </TableCell>
                            <TableCell>
                              {parseFloat(item?.quantity).toLocaleString(
                                undefined,
                                {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell>
                              {parseFloat(
                                item?.expenseDetail?.price
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>
                            <TableCell>
                              {(
                                parseFloat(item?.quantity || 0) *
                                parseFloat(item?.expenseDetail?.price || 0)
                              ).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>
                            <TableCell align="center">
                              {<b>{item?.createBy?.name}</b>}{" "}
                              {item?.createdAt
                                ? dayjs(item?.createdAt).format("DD/MM/YYYY")
                                : ""}
                            </TableCell>
                            <TableCell align="center">
                              {<b>{item?.updateBy?.name}</b>}{" "}
                              {item?.updateAt
                                ? dayjs(item?.updateAt).format("DD/MM/YYYY")
                                : ""}
                            </TableCell>
                            <TableCell>
                              {item?.expenseDetail?.expense?.images?.length >
                                0 && (
                                  <IconButton
                                    color="warning"
                                    onClick={() => {
                                      setOpenDisplayImage({
                                        open: true,
                                        images:
                                          item?.expenseDetail?.expense?.images ||
                                          [],
                                        docNo:
                                          item?.expenseDetail?.expense?.docNo ||
                                          "",
                                      });
                                    }}
                                  >
                                    <ImageIcon />
                                  </IconButton>
                                )}
                              {item?.expenseDetail?.expense?.images?.length ===
                                0 && (
                                  <IconButton component="label" disabled>
                                    <UploadFileIcon />
                                  </IconButton>
                                )}
                            </TableCell>
                          </TableRow>
                        ))}
                      {props.data?.wages && props.data?.wages?.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={7} align="center">
                            ไม่พบข้อมูล
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => props.onClose()}>
          ตกลง
        </Button>
      </DialogActions>
      <Box style={{ display: "none" }}>
        {
          <PrintProductDetail
            ref={printRef}
            title={`รายละเอียดการสั่งซื้อ ${props.title}`}
            house={props.house}
            data={props.data}
            total={props.total}
            onClose={() => props.onClose()}
          />
        }
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileProductDetail
          ref={exportRef}
          title={`รายละเอียดการสั่งซื้อ ${props.title}`}
          house={props.house}
          data={props.data}
          total={props.total}
        />
      </Box>
      <DialogDisplayImage
        open={openDisplayImage.open}
        onClose={() =>
          setOpenDisplayImage({ open: false, images: [], docNo: "" })
        }
        images={openDisplayImage.images}
        docNo={openDisplayImage.docNo}
      />
    </Dialog>
  );
};

export default DialogProductDetail;
