import { Box, Breadcrumbs, Button, Card, CardContent, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";
import projectsStore from "../../stores/ProjectsStore";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import { useReactToPrint } from "react-to-print";
import PrintSettingProjects from "../../components/Report/Pdf/PrintSettingProjects";
import { DownloadTableExcel } from "react-export-table-to-excel";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExportFileSettingProjects from "../../components/Report/Excel/ExportFileSettingProjects";
import authStore from "../../stores/AuthStore";

const SettingProjects: React.FC = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [projectName, setProjectName] = useState('');

    const permission: any = authStore.user?.roles.find((role: any) => role.menu.path === '/setting-projects');
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const printRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({ contentRef: printRef });
    const exportRef = useRef(null);
    const [rows, setRows] = useState<any[]>([]);

    const handleSearch = (pageNumber?: string, limitNumber?: string) => {
        setIsLoading(true);
        setRows([]);
        const _page = parseInt(pageNumber || '0');
        const _limit = parseInt(limitNumber || '0');
        projectsStore.getByCriteria({
            name: projectName,
            page: _page,
            limit: _limit
        }).then((result) => {
            setRows(result.data);
            setTotal(result.total);
            setIsLoading(false);
        }).catch((error) => {
            console.error(error);
            setIsLoading(false);
        });

    };

    useEffect(() => {
        handleSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box component={'div'}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Typography>การจัดการ</Typography>
                <Typography variant="h6" color="text.primary">โครงการ</Typography>
            </Breadcrumbs>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2} alignItems={'end'}>
                                <Grid item xs={10} >
                                    <TextField id="outlined-basic" label="ชื่อโครงการ" variant="outlined" fullWidth
                                        onChange={(event) => setProjectName(event.target.value)} value={projectName}
                                    />
                                </Grid>
                                <Grid item xs={2} textAlign={'end'} pb={0.5} >
                                    <Button variant="contained" onClick={() => handleSearch()} startIcon={<SearchIcon />}>ค้นหา</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent={'end'}>
                                        <Grid item xs={9}>
                                            <Typography>ทั้งหมด {rows.length.toLocaleString()} รายการ</Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <DownloadTableExcel
                                                filename={`projects-${dayjs().format('DD-MM-YYYY')}`}
                                                sheet={`projects-${dayjs().format('DD-MM-YYYY')}`}
                                                currentTableRef={exportRef.current}
                                            >
                                                <IconButton aria-label="download"  >
                                                    <DownloadIcon />
                                                </IconButton>
                                            </DownloadTableExcel>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton aria-label="print" onClick={() => reactToPrintFn()}>
                                                <PrintIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1}>
                                            {((permission && permission.create) || authStore.user?.isSuperAdmin) && <Button variant="contained" startIcon={<NoteAddIcon />} onClick={() => navigate('/setting-project-create')}>สร้าง</Button>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ชื่อโครงการ</TableCell>
                                                    <TableCell>รายละเอียด</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{row.name}</TableCell>
                                                        <TableCell>{row.description}</TableCell>
                                                        <TableCell align="right">
                                                            <IconButton aria-label="edit" onClick={() => navigate(`/setting-project-edit/${row.id}`)}>
                                                                <KeyboardArrowRightIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box mt={3}>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                                            count={total}
                                            onPageChange={(e, newPage) => {
                                                setPage(newPage);
                                                handleSearch(newPage.toString());
                                            }}
                                            page={page}
                                            rowsPerPage={limit}
                                            onRowsPerPageChange={(e: any) => {
                                                setRows([]);
                                                setLimit(e.target.value);
                                                setPage(0);
                                                handleSearch('0', e.target.value.toString());
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <SimpleBackdrop open={isLoading} />
            <Box sx={{ display: 'none' }}>
                <PrintSettingProjects title={'รายการโครงการ'} data={rows} ref={printRef} />
            </Box>
            <Box sx={{ display: 'none' }}>
                <ExportFileSettingProjects title={'รายการโครงการ'} data={rows} ref={exportRef} />
            </Box>
        </Box>
    );
};

export default SettingProjects;
