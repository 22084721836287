import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import projectsStore from "../../stores/ProjectsStore";
import { useNavigate } from "react-router-dom";
import SimpleBackdrop from "../../components/SimpleBackdrop";
import { useReactToPrint } from "react-to-print";
import { DownloadTableExcel } from "react-export-table-to-excel";
import dayjs from "dayjs";
import "dayjs/locale/th";
import PrintProjects from "../../components/Report/Pdf/PrintProjects";
import ExportFileProjects from "../../components/Report/Excel/ExportFileProjects";

const Projects: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [rows, setRows] = React.useState<any[]>([]);
  const printRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef: printRef });
  const exportRef = useRef(null);

  const handleSearch = () => {
    setIsLoading(true);
    if (projectName === "") {
      projectsStore.getWithExpenses().then((result) => {
        setRows(result.sort((a: any, b: any) => a.name.localeCompare(b.name)));
        setIsLoading(false);
      });
    } else {
      projectsStore.getWithExpensesByName(projectName).then((result) => {
        setRows(result.sort((a: any, b: any) => a.name.localeCompare(b.name)));
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    projectsStore
      .getWithExpenses()
      .then((result) => {
        setRows(result.sort((a: any, b: any) => a.name.localeCompare(b.name)));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  return (
    <Box component={"div"}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>โครงการ</Typography>
        <Typography variant="h6" color="text.primary">
          รายการ
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2} alignItems={"end"}>
                <Grid item xs={10}>
                  <TextField
                    id="outlined-basic"
                    label="ชื่อโครงการ"
                    variant="outlined"
                    fullWidth
                    onChange={(event) => setProjectName(event.target.value)}
                    value={projectName}
                  />
                </Grid>
                <Grid item xs={2} textAlign={"end"} pb={0.5}>
                  <Button
                    variant="contained"
                    onClick={() => handleSearch()}
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justifyContent={"end"}>
                    <Grid item xs={10}>
                      <Typography>
                        ทั้งหมด {rows.length.toLocaleString()} รายการ
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <DownloadTableExcel
                        filename={`project-cost-${dayjs().format(
                          "DD-MM-YYYY"
                        )}`}
                        sheet={`project-cost-${dayjs().format("DD-MM-YYYY")}`}
                        currentTableRef={exportRef.current}
                      >
                        <IconButton aria-label="download">
                          <DownloadIcon />
                        </IconButton>
                      </DownloadTableExcel>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton aria-label="print" onClick={() => reactToPrintFn()}>
                        <PrintIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>ชื่อโครงการ</TableCell>
                          <TableCell>มูลค่ารวม</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => (
                          <TableRow
                            key={row.id + index.toString()}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                textDecoration: !row.isActive
                                  ? "line-through"
                                  : "none",
                              }}
                            >
                              {row.isActive ? (
                                row.name
                              ) : (
                                <Tooltip title="ข้อมูลนี้ถูกลบแล้ว">
                                  <Box component="span">{row.name}</Box>
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell>
                              {parseFloat(row.total).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-label="detail"
                                onClick={() =>
                                  navigate(`/project-detail/${row.id}`)
                                }
                              >
                                <KeyboardArrowRightIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <SimpleBackdrop open={isLoading} />
      <Box sx={{ display: "none" }}>
        <PrintProjects ref={printRef} title={'รายการโครงการ'} data={rows} />
      </Box>
      <Box sx={{ display: "none" }}>
        <ExportFileProjects ref={exportRef} title={'รายการโครงการ'} data={rows} />
      </Box>
    </Box>
  );
};

export default Projects;
